import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Flex,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Input,
  InputNumber,
  Switch,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import useAutoTable from '../components/useAutoTable'
import ITUButton from '../components/ITUButton'
const BADGE = 'BADGE'
const POINT = 'POINT'
const typeOptions = [
  {
    label: 'Badge',
    value: BADGE
  },
  {
    label: 'Point',
    value: POINT
  }
]

export default function ManageAwardTriggers() {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [awardTypes, setAwardTypes] = useState([])
  const [badgeTypes, setBadgeTypes] = useState([])
  const [triggerNames, setTriggerNames] = useState([])
  const [formData, setFormData] = useState({
    id: null,
    badgeId: null,
    enabled: false,
    pointsCount: null,
    pointsTypeId: null,
    triggersName: '',
    awardType: '',
    gainCount: ''
  })
  const [Table, refreshTable] = useAutoTable({
    fetch: async (params) => {
      const result = {
        list: [],
        total: 0
      }
      try {
        const res = await api.getAwardTriggerList(params)
        if (res.data.code === 200) {
          result.list = res.data.data.records
          result.total = res.data.data.total
        }
      } catch (error) {
        // error
      }
      return result
    }
  })

  const handleCloseModal = () => {
    setFormData({
      id: null,
      badgeId: null,
      enabled: false,
      pointsCount: null,
      pointsTypeId: null,
      triggersName: ''
    })
    setIsOpen(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const { data } = await (formData.id ? api.updateAwardTrigger(formData) : api.creatAwardTrigger(formData)).catch((e) => {
        return e.response
      })
      const success = data.code === 200
      setIsLoading(false)
      toast({
        title: success ? 'Success' : 'Error',
        description: success
          ? 'Trigger saved successfully.'
          : 'Oops. There was an error saving the trigger. Please contact tech support. Error: ' + data.msg,
        status: success ? 'success' : 'error',
        duration: 5000,
        isClosable: true
      })
      if (success) {
        handleCloseModal()
        refreshTable()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPointsTypeData = () => {
    api
      .fetchPointsType({
        current: 1,
        size: -1
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setAwardTypes(res.data.data.map(({ id, name }) => ({ label: name, value: id })))
          }
        }
      })
  }

  const fetchBadgesData = () => {
    api
      .fetchBadges({
        current: 1,
        size: -1
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setBadgeTypes(res.data.data.records.map(({ id, name }) => ({ label: name, value: id })))
          }
        }
      })
  }

  const getTriggerName = () => {
    api
      .getAwardTriggerEvent({
        current: 1,
        size: -1
      })
      .then((res) => {
        if (res.data.code === 200) {
          setTriggerNames(res.data.data)
        }
      })
  }

  const [taskList, setTaskList] = useState([])
  const getTaskList = async () => {
    const res = await api.getChallengeTaskList({
      current: 1,
      size: 10000
    })
    setTaskList(res?.data?.data?.records)
  }

  useEffect(() => {
    getTriggerName()
    fetchPointsTypeData()
    fetchBadgesData()
    getTaskList()
  }, [])
  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Award Triggers</Heading>
      </Flex>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent padding={'24px'}>
          <div>
            <ModalBody>
              <FormControl hidden>
                <FormLabel>id</FormLabel>
                <Input value={formData.id} />
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Name</FormLabel>
                <Text>{formData.triggersName}</Text>
                {/* <Select
                  value={formData.triggersName}
                  placeholder="Select Trigger Name"
                  onChange={(event) => {
                    setFormData((_formData) => {
                      return { ..._formData, triggersName: event.target.value }
                    })
                  }}
                >
                  {triggerNames.map((key) => (
                    <option key={`option-${key}`} value={key}>
                      {key}
                    </option>
                  ))}
                </Select> */}
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Gain Type</FormLabel>
                <Select
                  value={formData.gainType}
                  placeholder="Select option"
                  onChange={(event) => {
                    setFormData((_formData) => {
                      return { ..._formData, gainType: event.target.value }
                    })
                  }}
                >
                  {['ONLY_COUNT', 'DAILY', 'MONTHLY', 'WEEKLY'].map((key) => (
                    <option key={`option-${key}`} value={key}>
                      {key}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Gain Count</FormLabel>
                <Input
                  value={formData.gainCount}
                  onChange={() =>
                    setFormData((_formData) => {
                      return { ..._formData, gainCount: event.target.value }
                    })
                  }
                />
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Enabled</FormLabel>
                <Switch
                  id="email-alerts"
                  isChecked={formData.enabled}
                  onChange={() =>
                    setFormData((_formData) => {
                      return { ..._formData, enabled: !_formData.enabled }
                    })
                  }
                />
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Award Type</FormLabel>
                <Select
                  value={formData.awardType}
                  placeholder="Select option"
                  onChange={(event) => {
                    setFormData((_formData) => {
                      return { ..._formData, awardType: event.target.value }
                    })
                  }}
                >
                  {typeOptions.map((el) => (
                    <option key={`option-${el.label}`} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {formData.awardType === BADGE && (
                <FormControl marginTop={'24px'}>
                  <FormLabel>Badge Type</FormLabel>
                  <Select
                    value={formData.badgeId}
                    placeholder="Select option"
                    onChange={(event) => {
                      setFormData((_formData) => {
                        return { ..._formData, badgeId: event.target.value }
                      })
                    }}
                  >
                    {badgeTypes.map((el) => (
                      <option key={`option-${el.label}`} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {formData.awardType === POINT && (
                <>
                  <FormControl marginTop={'24px'}>
                    <FormLabel>Point Count</FormLabel>
                    <Input
                      value={formData.pointsCount}
                      onChange={(event) =>
                        setFormData((_formData) => {
                          return { ..._formData, pointsCount: event.target.value }
                        })
                      }
                    />
                  </FormControl>
                  <FormControl marginTop={'24px'}>
                    <FormLabel>Points Type</FormLabel>
                    <Select
                      value={formData.pointsTypeId}
                      placeholder="Select option"
                      onChange={(event) => {
                        setFormData((_formData) => {
                          return { ..._formData, pointsTypeId: event.target.value }
                        })
                      }}
                    >
                      {awardTypes.map((el) => (
                        <option key={`option-${el.label}`} value={el.value}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {/* bad code but backend need, await backend reconsitution */}
              {formData?.triggersName === 'Completion of the Impact 90 Challenge' ? (
                <FormControl marginTop={'24px'}>
                  <FormLabel>Task</FormLabel>
                  <Select
                    value={formData.targetDetailId}
                    placeholder="Select option"
                    onChange={(event) => {
                      setFormData((_formData) => {
                        return { ..._formData, targetDetailId: +event.target.value }
                      })
                    }}
                  >
                    {taskList.map((el) => (
                      <option key={`option-${el.id}`} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              <ModalFooter>
                <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
                  Cancel
                </ITUButton>
                <ITUButton onClick={handleSave} disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" /> : 'Save'}
                </ITUButton>
              </ModalFooter>
            </ModalBody>
          </div>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={deleteId} onClose={() => setDeleteId('')}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteId('')}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteId('')
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Table
        columnDefs={[
          {
            field: 'triggersName',
            headerName: 'Triggers',
            flex: '3',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'enabled',
            headerName: 'Enabled',
            cellRenderer: (params) => {
              return <Switch isChecked={params.value} colorScheme="teal" />
            }
          },
          {
            field: 'awardType',
            headerName: 'Award Type',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'gainCount',
            headerName: 'Gain Count',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'PointsType',
            headerName: 'Points Type',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter',
            cellRenderer: ({ data }) => {
              return data?.pointsType?.name || '-'
            }
          },
          {
            field: 'pointsCount',
            headerName: 'Points Count',
            flex: '1'
          },
          {
            field: 'badges',
            headerName: 'Badge Type',
            flex: '1',
            cellRenderer: ({ data }) => {
              return data?.badges?.name || '-'
            }
          },
          {
            headerName: 'Actions',
            cellRenderer: (params) => {
              return (
                <Flex gap={4}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      const { triggersName, targetDetailId, gainCount, id, enabled, awardType, pointsCount, pointsTypeId, badgeId } =
                        params.data
                      setIsOpen(true)
                      setFormData({
                        id: id,
                        badgeId: badgeId,
                        enabled: enabled,
                        pointsCount: pointsCount,
                        pointsTypeId: pointsTypeId,
                        triggersName: triggersName,
                        gainCount: gainCount,
                        awardType: awardType,
                        targetDetailId
                      })
                    }}
                    icon={<EditIcon />}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setDeleteId(params.data.id)
                    }}
                    icon={<DeleteIcon />}
                  />
                </Flex>
              )
            }
          }
        ]}
      />
    </Layout>
  )
}
