import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Switch
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'
import RichText from '../components/RichTextEditor/index'

function ManageBanner() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])

  const [isOpen, setIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [name, setName] = useState('') // State for Name input
  const [bannerText, setBannerText] = useState('') // State for Rich Text Editor
  const [radioValue, setRadioValue] = useState('manual') // State for radio buttons

  const [startDate, setStartDate] = useState(Date.now()) // Current timestamp
  const [endDate, setEndDate] = useState(Date.now() + 1000 * 60 * 60 * 24) // 24 hours from now
  const [isEnabled, setIsEnabled] = useState(false)
  const [bgColor, setBgColor] = useState('#FFFFFF') // State for background color
  const [textColor, setTextColor] = useState('#000000')

  const toast = useToast()

  function convertTimestampToDatetimeLocal(timestamp) {
    const date = new Date(timestamp)
    const localDatetime = date.toISOString().substring(0, 16)
    return localDatetime
  }

  function convertDatetimeLocalToTimestamp(datetimeLocal) {
    const date = new Date(datetimeLocal)
    return date.getTime()
  }

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  const fetchBanners = () => {
    api
      .getBannerList({
        current: 1,
        size: 1000
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setRowData(res.data.data.records)
          }
        }
      })
  }
  useEffect(() => {
    if (loggedIn) {
      api
        .getBannerList({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setRowData(res.data.data.records)
            }
          }
        })
    }
  }, [loggedIn])
  const handleCloseModal = () => {
    setName('')
    setBannerText('')
    setBgColor('')
    setEndDate(Date.now())
    setStartDate(Date.now())
    setTextColor('')
    setIsEnabled(false)
    setRadioValue('')
    setIsOpen(false)
  }
  const handleSave = () => {
    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .updateBanner({
          backgroundColor: bgColor,
          bannerText: bannerText,
          enable: isEnabled,
          endDate: endDate,
          id: item.id,
          manualEnable: radioValue === 'manual',
          name: name,
          startDate: startDate,
          textColor: textColor
        })
        .then((response) => {
          // Handle the response
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Banner Updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          fetchBanners()
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to Updated Banner.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    }
    api
      .createBanner({
        backgroundColor: bgColor,
        bannerText: bannerText,
        enable: isEnabled,
        endDate: endDate,
        manualEnable: radioValue === 'manual',
        name: name,
        startDate: startDate,
        textColor: textColor
      })
      .then((response) => {
        // Handle the respons
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Banner saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        fetchBanners()
      })
      .catch((error) => {
        // Handle the error

        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Banner.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const columnDefs = [
    { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'enable',
      headerName: 'Enabled',
      flex: '1',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => {
        return <div>{params.data.enable ? 'True' : 'False'}</div>
      }
    },
    {
      field: 'startDate',
      headerName: 'Active Dates',
      flex: '1',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => {
        return dayjs(params.data.startDate).format('MMMM D, YYYY, h:mm A')
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setName(params.data.name)
                setBannerText(params.data.bannerText)
                setBgColor(params.data.backgroundColor)
                setStartDate(params.data.startDate)
                setEndDate(params.data.endDate)
                setTextColor(params.data.textColor)
                setIsEnabled(params.data.enable)
                setRadioValue(params.data.manualEnable ? 'manual' : 'schedule')
                setIsOpen(true)
                setItem(params.data)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Banners</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsOpen(true)
              setItem(null)
            }}
          >
            Add Banner
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Banner</ModalHeader>
          <ModalBody>
            <ModalBody>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Banner Text</FormLabel>
                <RichText onChange={(value) => setBannerText(value)} value={bannerText} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Enable Mode</FormLabel>
                <Flex align="center">
                  <input type="radio" value="manual" checked={radioValue === 'manual'} onChange={(e) => setRadioValue(e.target.value)} />
                  <FormLabel ml={2} htmlFor="manual">
                    Manually enable
                  </FormLabel>

                  <input
                    type="radio"
                    value="schedule"
                    checked={radioValue === 'schedule'}
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  <FormLabel ml={2} htmlFor="schedule">
                    Schedule
                  </FormLabel>
                </Flex>
              </FormControl>

              <Flex mt={4}>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="datetime-local"
                    value={convertTimestampToDatetimeLocal(startDate)}
                    onChange={(e) => setStartDate(convertDatetimeLocalToTimestamp(e.target.value))}
                  />
                </FormControl>

                <FormControl ml={4}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type="datetime-local"
                    value={convertTimestampToDatetimeLocal(endDate)}
                    onChange={(e) => setEndDate(convertDatetimeLocalToTimestamp(e.target.value))}
                  />
                </FormControl>
              </Flex>

              <FormControl mt={4}>
                <FormLabel>Enable</FormLabel>
                <Switch isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Background Color</FormLabel>
                <Input type="color" value={bgColor} onChange={(e) => setBgColor(e.target.value)} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Text Color</FormLabel>
                <Input type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} />
              </FormControl>
            </ModalBody>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteBanner(item.id)
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Banner saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                        fetchBanners()
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Failed to delete Banner.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete Banner.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageBanner
