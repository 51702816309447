import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Switch,
  RadioGroup,
  Radio,
  Stack,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import MultiSelect from 'react-select'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'

function CreateLesson() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [users, setUsers] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [membershipLevel, setMembershipLevel] = useState([])
  const [instructorId, setInstructorId] = useState()
  const [membershipLevels, setMembershipLevels] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [phaseId, setPhaseId] = useState(0)
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('')
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState('')
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [fileExtensions, setFileExtensions] = useState('')
  const [fileSizeLimit, setFileSizeLimit] = useState('')
  const [pointsToggle, setPointsToggle] = useState(false)
  const [availablePoints, setAvailablePoints] = useState('')
  const [gradingType, setGradingType] = useState('')
  const [sampleLessonToggle, setSampleLessonToggle] = useState(false)
  const [lessonReleaseSchedule, setLessonReleaseSchedule] = useState('')

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  const getLessions = () => {
    if (loggedIn) {
      api
        .fetchLessons({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setRowData(res.data.data.records)
          }
        })
    }
  }
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchUsers({
          current: 1,
          size: 1000,
          profileTypeId: 5
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setUsers(res.data.data.records)
            }
          }
        })
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
    }
    getLessions()
  }, [loggedIn])
  const handleCloseModal = () => {
    setIsOpen(false)
  }
  const handleSave = () => {
    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .updateLessons({
          content,
          membershipLevel,
          membershipLevelIds: membershipLevel.map((el) => el.value),
          title,
          instructorId: instructorId ? instructorId.value : '',
          videoPreviewUrl,
          videoThumbnailUrl,
          id: item.id,
          enableUploads: fileExtensions ? true : false,
          fileSizeLimit: fileSizeLimit,
          gradingType: gradingType,
          lessonReleaseSchedule: lessonReleaseSchedule,
          points: pointsToggle,
          sampleLesson: sampleLessonToggle
        })
        .then((response) => {
          // Handle the response

          getLessions()
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Lesson updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error updating the lesson. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    }
    api
      .createLesson({
        content,
        membershipLevel,
        membershipLevelIds: membershipLevel.map((el) => el.value),
        title,
        instructorId: instructorId ? instructorId.value : '',
        videoPreviewUrl
      })
      .then((response) => {
        // Handle the response

        getLessions()
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Lesson saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
      })
      .catch((error) => {
        // Handle the error

        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Oops. There was an error saving the lesson. Please contact tech support.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const columnDefs = [
    { field: 'id', headerName: 'ID', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'phaseId',
      headerName: 'Phase Id',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'coursesId',
      headerName: 'Course Id',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    { field: 'title', headerName: 'Title', sortable: true },
    { field: 'content', headerName: 'Content' },
    {
      field: 'membershipLevels',
      headerName: 'MembershipLevels',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => (params.value ? params.value.map((el) => el.levelName).toString() : '')
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      }
    },
    {
      field: 'videoPreviewUrl',
      headerName: 'VideoPreview'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (params.data.instructorId) {
                  const _instructor = users.find((el) => el.id === params.data.instructorId)
                  if (_instructor) {
                    setInstructorId({
                      label: _instructor.userNickname || _instructor.userLogin,
                      value: _instructor.id
                    })
                  }
                }
                if (params.data.membershipLevels && params.data.membershipLevels.length > 0) {
                  setMembershipLevel(
                    params.data.membershipLevels.map((el) => {
                      return {
                        value: el.id,
                        label: el.levelName
                      }
                    })
                  )
                }
                setContent(params.data.content)
                setTitle(params.data.title)
                setVideoPreviewUrl(params.data.videoPreviewUrl)
                setVideoThumbnailUrl(params.data.videoThumbnailUrl)
                setIsOpen(true)
                setItem(params.data)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Lessons</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsOpen(true)
              setItem(null)
            }}
          >
            Add Lesson
          </ITUButton>
        </Flex>
      </Flex>

      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          pagination={true}
          theme="ag-theme-alpine"
          alwaysShowHorizontalScroll={true}
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Lesson</ModalHeader>
          <ModalBody>
            <FormControl id="description">
              <FormLabel>Title</FormLabel>
              <Input placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl id="description" marginTop={'24px'}>
              <FormLabel>Content</FormLabel>
              <Textarea placeholder="Enter description" value={content} onChange={(e) => setContent(e.target.value)} height={'300px'} />
            </FormControl>
            <FormControl id="videoLink" marginTop={'24px'}>
              <FormLabel>Video Link</FormLabel>
              <Input value={videoPreviewUrl} onChange={(e) => setVideoPreviewUrl(e.target.value)} />
            </FormControl>
            <FormControl id="videoThumbnailUrl" mt="5">
              <FormLabel>Thumbnail Image</FormLabel>
              {videoThumbnailUrl && <img height="200" src={videoThumbnailUrl} />}

              <ITUButton variant="outline" size="xs" as="label" for="previewImageUrlInput">
                {videoThumbnailUrl ? 'Change' : 'Upload'}
              </ITUButton>

              <Input
                hidden
                id="previewImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setVideoThumbnailUrl(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl id="instructor" marginTop={'24px'}>
              <FormLabel>Instructor</FormLabel>
              <MultiSelect
                defaultValue={instructorId}
                name="instructor"
                onChange={(event) => {
                  setInstructorId(event)
                }}
                options={
                  users
                    ? users.map((el) => {
                        return {
                          value: el.id,
                          label: el.userNickname || el.userLogin
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="membershipLevel" marginTop={'24px'}>
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={(event) => {
                  setMembershipLevel(event)
                }}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <Heading size="md" mt={4}>
              Assignment Uploads
            </Heading>
            <FormControl>
              <FormLabel>File Extensions (pdf, xls, zip)</FormLabel>
              <Input placeholder="pdf,xls,zip" value={fileExtensions} onChange={(e) => setFileExtensions(e.target.value)} />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>File Size Limit</FormLabel>
              <Input placeholder="File Size Limit" value={fileSizeLimit} onChange={(e) => setFileSizeLimit(e.target.value)} />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={3}>
              <FormLabel mr={3}>Points</FormLabel>
              <Switch isChecked={pointsToggle} onChange={() => setPointsToggle(!pointsToggle)} />
            </FormControl>
            {pointsToggle && (
              <FormControl mt={3}>
                <FormLabel>Available Point(s)</FormLabel>
                <Input placeholder="Enter Points" value={availablePoints} onChange={(e) => setAvailablePoints(e.target.value)} />
              </FormControl>
            )}
            <FormControl mt={3}>
              <FormLabel>Grading Type</FormLabel>
              <RadioGroup onChange={setGradingType} value={gradingType}>
                <Stack spacing={3}>
                  <Radio value="auto-approve">Auto-Approve</Radio>
                  <Radio value="manually-grade">Manually Grade</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            {/* Lesson Access Settings */}
            <Heading size="md" mt={4}>
              Lesson Access Settings
            </Heading>
            <FormControl display="flex" alignItems="center" mt={3}>
              <FormLabel mr={3}>Sample Lesson</FormLabel>
              <Switch isChecked={sampleLessonToggle} onChange={() => setSampleLessonToggle(!sampleLessonToggle)} />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Lesson Release Schedule</FormLabel>
              <RadioGroup onChange={setLessonReleaseSchedule} value={lessonReleaseSchedule}>
                <Stack spacing={3}>
                  <Radio value="immediately">Immediately</Radio>
                  <Radio value="enrollment-based">Enrollment-based</Radio>
                  <Radio value="specific-date">Specific date</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteLessons({
                      lessonId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        getLessions()
                        toast({
                          title: 'Success',
                          description: 'Lesson saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Oops. There was an error deleting the lesson. Please contact tech support.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Oops. There was an error deleting the lesson. Please contact tech support. Error: ' + error,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default CreateLesson
