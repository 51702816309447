import { forwardRef } from 'react'
import { Box } from '@chakra-ui/react'

const Menu = forwardRef(({ className, ...props }, ref) => (
  <Box
    {...props}
    data-test-id="menu"
    position={'relative'}
    pt={4}
    pb={4}
    borderColor={'#eee'}
    borderBottom={'2px'}
    display={'flex'}
    flexWrap={'wrap'}
    gap={1}
  />
))

export default Menu
