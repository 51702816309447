import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Switch,
  Image,
  IconButton,
  Checkbox,
  Stack
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'
import Pagination from '../components/Pagination'
import Select from 'react-select'
import RichTextEditor from '../components/RichTextEditor/index'

function ManageMessageNotifications() {
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [info, setInfo] = useState(null)
  const [createData, setCreateData] = useState({
    id: '',
    configName: 'string',
    description: '',
    enabled: false,
    sendApp: true,
    sendEmail: true,
    sendWeb: true
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: -1
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const [configList, setConfigList] = useState([])
  const getConfigEnmu = async () => {
    const { data } = await api.notifications.getConfigEnum()
    if (data.code === 200) {
      setConfigList(
        data.data?.map((key) => ({
          label: key,
          value: key
        }))
      )
    }
  }

  useEffect(() => {
    getTableData()
    getConfigEnmu()
  }, [])

  const getTableData = (params = pageQuery) => {
    setIsLoading(true)
    api.notifications
      .getList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateQuickLinkBatch = async (quickLinks) => {
    setIsLoading(true)
    const res = await api.updateQuickLinkBatch({ quickLinks }).finally(() => {
      setIsLoading(false)
      getTableData()
    })
    toast({
      title: res.data.code === 200 ? 'Success' : 'Error',
      description: res.data.code === 200 ? 'Successful sorting' : 'Failed to sort',
      status: res.data.code === 200 ? 'success' : 'error'
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    const params = {
      ...info,
      mostMonth: +dayjs(info.mostMonth).format('YYYYMM')
    }
    ;(params.id ? api.notifications.update(params) : api.notifications.create(params))
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        setInfo(null)
        getTableData()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    const res = await api.notifications.delete(deleteId)
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: ' Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete Notification.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  return (
    <Layout>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Message Notifications</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setInfo({})}>
            Add
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'id', headerName: 'ID', flex: 1, sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'configName',
              headerName: 'configName',
              flex: 2,
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'enabled',
              headerName: 'Enabled',
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => {
                return <Switch isChecked={params.value} colorScheme="teal" />
              }
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: '1',
              headerTooltip: 'Search',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'sendApp',
              headerName: 'sendApp',
              flex: '1',
              cellRenderer: (params) => {
                return (
                  <>
                    <Checkbox isChecked={params.data.sendApp} isDisabled>
                      sendApp
                    </Checkbox>
                  </>
                )
              }
            },
            {
              field: 'sendEmail',
              headerName: 'sendEmail',
              flex: '1',
              cellRenderer: (params) => {
                return (
                  <>
                    <Checkbox isChecked={params.data.sendEmail} isDisabled>
                      sendEmail
                    </Checkbox>
                  </>
                )
              }
            },
            {
              field: 'sendWeb',
              headerName: 'sendWeb',
              flex: '1',
              cellRenderer: (params) => {
                return (
                  <>
                    <Checkbox isChecked={params.data.sendWeb} isDisabled>
                      sendWeb
                    </Checkbox>
                  </>
                )
              }
            },

            {
              field: 'id',
              headerName: 'Action',
              flex: '1',
              cellRenderer: (params) => (
                <Flex gap={4}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setInfo({
                        ...params.data
                      })
                    }}
                    icon={<EditIcon />}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setDeleteId(params.data.id)
                    }}
                    icon={<DeleteIcon />}
                  />
                </Flex>
              )
            }
          ]}
          onRowDragEnd={({ overIndex, overNode }) => {
            const list = [...tableData.list]
            const index = list.findIndex(({ id }) => id === overNode.data.id)
            const [item] = list.splice(index, 1)
            list.splice(overIndex, 0, item)
            updateQuickLinkBatch(
              list.map((item, index) => ({
                id: item.id,
                orderNum: index
              }))
            )
          }}
        ></AgGridReact>
      </div>

      <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getTableData({ current, size })
          }}
        />
      </div>
      {info ? (
        <Modal isOpen={true} onClose={() => setInfo(null)} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{info.id ? 'Edit' : 'Create'} Notification</ModalHeader>
            <ModalBody>
              <FormControl id="title">
                <FormLabel>Name</FormLabel>
                <Select
                  value={{
                    label: info.configName,
                    value: info.configName
                  }}
                  onChange={({ label, value }) => {
                    setInfo({
                      ...info,
                      configName: label
                    })
                  }}
                  cacheOptions
                  options={configList}
                />
              </FormControl>
              <FormControl marginTop={'24px'}>
                <FormLabel>Enabled</FormLabel>
                <Switch
                  id="email-alerts"
                  isChecked={info.enabled}
                  onChange={() =>
                    setInfo((_formData) => {
                      return { ..._formData, enabled: !_formData.enabled }
                    })
                  }
                />
              </FormControl>
              <FormControl id="description" mt="4">
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Add description..."
                  value={info.description}
                  onChange={(e) =>
                    setInfo((_formData) => {
                      return { ..._formData, description: e.target.value }
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Send</FormLabel>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    isChecked={info.sendApp}
                    onChange={(e) => {
                      setInfo({
                        ...info,
                        sendApp: e.target.checked
                      })
                    }}
                  >
                    sendApp
                  </Checkbox>
                  <Checkbox
                    isChecked={info.sendEmail}
                    onChange={(e) => {
                      setInfo({
                        ...info,
                        sendEmail: e.target.checked
                      })
                    }}
                  >
                    sendEmail
                  </Checkbox>
                  <Checkbox
                    isChecked={info.sendWeb}
                    onChange={(e) => {
                      setInfo({
                        ...info,
                        sendWeb: e.target.checked
                      })
                    }}
                  >
                    sendWeb
                  </Checkbox>
                </Stack>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ITUButton variant="outline" mr={3} onClick={() => setInfo(null)}>
                Cancel
              </ITUButton>
              <ITUButton onClick={handleSave} disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : 'Save'}
              </ITUButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </Layout>
  )
}

export default ManageMessageNotifications
