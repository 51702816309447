import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Switch,
  Image,
  Text,
  Select,
  Box
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs'
import ITUButton from '../components/ITUButton'
import RichTextEditor from '../components/RichTextEditor/index'

function ManageHeroSection() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [createData, setCreateData] = useState({
    id: '',
    link: '',
    coverImage: '',
    mobileCoverImage: '',
    active: false,
    buttonFirstLink: '',
    buttonFirstText: '',
    buttonSecondLink: '',
    buttonSecondText: '',
    category: '',
    displayOrder: 0,
    name: '',
    showVideo: false,
    video: ''
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: -1
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getJournalPromptList()
      getCategory()
    }
  }, [loggedIn])

  const getJournalPromptList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .getHeroList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const getCategory = (params = pageQuery) => {
    setIsLoading(true)
    api
      .getCategoryList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setCategoryList(res.data.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: '',
      link: '',
      coverImage: '',
      mobileCoverImage: '',
      active: false,
      buttonFirstLink: '',
      buttonFirstText: '',
      buttonSecondLink: '',
      buttonSecondText: '',
      category: '',
      displayOrder: 0,
      name: '',
      showVideo: false,
      video: ''
    })
  }

  const updateHeroSectionBatch = async (orderList) => {
    setIsLoading(true)
    const res = await api.updateHeroSectionBatch({ orderList }).finally(() => {
      setIsLoading(false)
      getJournalPromptList()
    })
    toast({
      title: res.data.code === 200 ? 'Success' : 'Error',
      description: res.data.code === 200 ? 'Successful sorting' : 'Failed to sort',
      status: res.data.code === 200 ? 'success' : 'error'
    })
  }

  const handleSave = () => {
    setIsLoading(true)
    ;(createData.id
      ? api.updateHeroSection(createData)
      : api.createHeroSection({
          ...createData,
          display_order: pageQuery.total + 1
        })
    )
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'HeroSection saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getJournalPromptList()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save HeroSection.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    const res = await api.deleteHeroSection(deleteId)
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'HeroSection Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getJournalPromptList()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete HeroSection.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  const uploadIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadIconLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadIconLoading(false)
      })
    if (data.data) {
      setCreateData({
        ...createData,
        coverImage: data.data
      })
    }
  }

  const uploadMobileIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    const { data } = await api.uploadFile(formData, {
      resourceType: 'images'
    })
    if (data.data) {
      setCreateData({
        ...createData,
        mobileCoverImage: data.data
      })
    }
  }

  const uploadMobileVideo = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    const { data } = await api.uploadFile(formData, {
      resourceType: 'images'
    })
    if (data.data) {
      setCreateData({
        ...createData,
        video: data.data
      })
    }
  }

  const changePinStatus = async ({ pin, ...data }) => {
    const res = await api.updateHeroSection({
      ...data,
      pin: pin === 1 ? 0 : 1
    })
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Change successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getJournalPromptList()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to Change Pin.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Layout>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Hero Section</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Add Hero Section
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list.sort((a, b) => a.displayOrder - b.displayOrder)}
          columnDefs={[
            {
              field: 'displayOrder',
              headerName: 'Order',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              rowDrag: (params) => {
                if ([0, 1, 2, 3, 4, 5].includes(params.data.displayOrder)) {
                  return false
                }
                return true
              }
            },
            {
              field: 'title',
              headerName: 'Panel Name',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => {
                return <div dangerouslySetInnerHTML={{ __html: params.value }} />
              }
            },
            { field: 'category', headerName: 'Category', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'active',
              headerName: 'Enabled',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => {
                return params.value ? 'true' : 'false'
              }
            },
            {
              field: 'createdAt',
              headerName: 'Created Date',
              valueFormatter: (params) => {
                return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
              },
              // flex: '2',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              // flex: '2',
              headerName: 'Pin',
              field: 'pin',
              cellRenderer: (params) => {
                const { id, pin, ...other } = params.data
                return pin === 1 ? 'True' : 'False'
              }
            },
            {
              field: 'id',
              headerName: 'Action',
              // flex: "3",
              cellRenderer: (params) => {
                const { id, pin, ...other } = params.data
                return (
                  <>
                    <Button
                      size={'sm'}
                      mr={2}
                      onClick={() => {
                        setCreateData({
                          id,
                          ...other
                        })
                        setIsOpen(true)
                      }}
                    >
                      <FiEdit3 />
                    </Button>
                    <Button size={'sm'} mr={2} onClick={() => setDeleteId(params.value)}>
                      <FiTrash2 />
                    </Button>
                    <Button size={'sm'} onClick={() => changePinStatus(params.data)}>
                      {pin === 1 ? <BsPinAngleFill /> : <BsPinAngle />}
                    </Button>
                  </>
                )
              }
            }
          ]}
          onRowDragEnd={({ overIndex, overNode }) => {
            if (![0, 1, 2, 3, 4, 5].includes(overIndex)) {
              const list = [...tableData.list]
              const index = list.findIndex(({ id }) => id === overNode.data.id)
              const [item] = list.splice(index, 1)
              list.splice(overIndex, 0, item)
              updateHeroSectionBatch(
                list.map((item, index) => ({
                  id: item.id,
                  orderNum: index
                }))
              )
            }
          }}
        ></AgGridReact>
      </div>

      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Close
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Panel</ModalHeader>
          <ModalBody>
            <FormControl id="title">
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter Label"
                value={createData.name}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    name: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="title">
              <FormLabel>Title</FormLabel>
              <RichTextEditor
                placeholder="Enter Title"
                value={createData.title}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    title: e
                  })
                }}
              ></RichTextEditor>
            </FormControl>
            <FormControl id="category">
              <FormLabel>Category</FormLabel>
              <Select
                w={32}
                value={createData.category}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    category: e.target.value
                  })
                }
              >
                {categoryList.map((category, indx) => (
                  <option key={indx} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="prompt" mt="4">
              <FormLabel>Description</FormLabel>
              <RichTextEditor
                placeholder="Enter Description"
                value={createData.description}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    description: e
                  })
                }}
              ></RichTextEditor>
            </FormControl>

            <FormControl id="button1">
              <FormLabel>FirstButton Text</FormLabel>
              <Input
                placeholder="Enter Label"
                value={createData.buttonFirstText}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    buttonFirstText: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="label">
              <FormLabel>FirstButton Link</FormLabel>
              <Input
                placeholder="Enter Label"
                value={createData.buttonFirstLink}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    buttonFirstLink: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="label">
              <FormLabel>SecondButton Text</FormLabel>
              <Input
                placeholder="Enter Label"
                value={createData.buttonSecondText}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    buttonSecondText: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="label">
              <FormLabel>SecondButton Link</FormLabel>
              <Input
                placeholder="Enter Label"
                value={createData.buttonSecondLink}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    buttonSecondLink: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="prompt" mt="4">
              <FormLabel>Background Image</FormLabel>
              <flex>
                {createData.coverImage ? (
                  <>
                    <Image maxW={200} maxH={200} objectFit="cover" src={createData.coverImage} alt="icon" />
                  </>
                ) : null}

                <ITUButton size="xs" variant="outline" as="label" htmlFor="icon">
                  {createData.coverImage ? 'Change' : 'Upload'}
                  {uploadIconLoading ? <Spinner /> : null}
                  <input hidden id="icon" type="file" accept="image/*" onChange={uploadIcon} />
                </ITUButton>
              </flex>
              <Text fontSize="xs">please upload limit to 1260 pixels by 560 pixels</Text>
            </FormControl>

            <FormControl mt="4">
              <FormLabel>Mobile Background Image</FormLabel>
              {createData.mobileCoverImage ? (
                <>
                  <Image maxW={200} maxH={200} objectFit="cover" src={createData.mobileCoverImage} alt="icon" />
                </>
              ) : null}
              <ITUButton size="xs" variant="outline" as="label">
                {createData.mobileCoverImage ? 'Change' : 'Upload'}
                <input hidden type="file" accept="image/*" onChange={uploadMobileIcon} />
              </ITUButton>
            </FormControl>

            <FormControl mt="4">
              <FormLabel>Video</FormLabel>
              {createData.video ? (
                <>
                  <Box maxW={300} maxH={300}>
                    <video controls style={{}} src={createData.video} alt="icon" />
                  </Box>
                </>
              ) : null}
              <ITUButton size="xs" variant="outline" as="label">
                {createData.video ? 'Change' : 'Upload'}
                <input hidden type="file" accept="video/*" onChange={uploadMobileVideo} />
              </ITUButton>
            </FormControl>

            <FormControl id="prompt" mt="4">
              <FormLabel>Show Video?</FormLabel>
              <Switch
                isChecked={createData.showVideo}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    showVideo: e.target.checked
                  })
                }}
              />
            </FormControl>

            <FormControl id="prompt" mt="4">
              <FormLabel>Is Active?</FormLabel>
              <Switch
                isChecked={createData.active}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    active: e.target.checked
                  })
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageHeroSection
