import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Flex,
  Box,
  Spinner,
  useToast,
  Card,
  IconButton,
  Stack,
  RadioGroup,
  Radio,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  Select,
  Input,
  InputNumber,
  Switch
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import useAutoTable from '../../components/useAutoTable'
import ITUButton from '../../components/ITUButton'
const BADGE = 'BADGE'
const POINT = 'POINT'
const typeOptions = [
  {
    label: 'Badge',
    value: BADGE
  },
  {
    label: 'Point',
    value: POINT
  }
]

export default function ManuallyAwardPoints() {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [isOpen, setIsOpen] = useState(true)
  const [awardTypes, setAwardTypes] = useState([])
  const [challenges, setChallenges] = useState([])
  const [users, setUsers] = useState([])
  const [formData, setFormData] = useState({
    id: null,
    allUser: false,
    pointCount: null,
    pointId: '',
    awardType: 'IMPACT_POINT',
    challengeId: '',
    userIds: []
  })

  const handleCloseModal = () => {
    setFormData({
      id: null,
      allUser: false,
      pointCount: '',
      pointId: '',
      awardType: 'IMPACT_POINT',
      challengeId: '',
      userIds: []
    })
  }
  const handleSave = async () => {
    if ((formData.awardType === 'IMPACT_POINT' && !formData.pointId) || (formData.awardType === 'CHALLENGE' && !formData.challengeId)) {
      toast({
        title: 'Error',
        description: 'Please select what type of points?',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }

    if (!formData.pointCount) {
      toast({
        title: 'Error',
        description: 'Please enter point count',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    if (!formData.allUser && (!formData.userIds || formData.userIds.length === 0)) {
      toast({
        title: 'Error',
        description: 'Please select user',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    setIsLoading(true)
    try {
      const { data } = await api.awardUser({
        ...formData,
        userIds: formData.userIds ? formData.userIds.map((el) => el.value) : []
      })
      const success = data.code === 200
      setIsLoading(false)
      toast({
        title: success ? 'Success' : 'Error',
        description: success ? 'Award Save successfully.' : data.msg,
        status: success ? 'success' : 'error',
        duration: 5000,
        isClosable: true
      })
      if (success) {
        handleCloseModal()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPointsTypeData = () => {
    api
      .fetchPointsType({
        current: 1,
        size: -1
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setAwardTypes(
              res.data && res.data.data
                ? res.data.data.map(({ id, name }) => ({ label: name, value: id })).filter((el) => el.value.toString() === '1')
                : []
            )
          }
        }
      })
  }

  const fetchChallenges = () => {
    api
      .getChallenges({
        current: 1,
        size: -1
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setChallenges(
              res.data && res.data.data && res.data.data.records
                ? res.data.data.records.map(({ id, name }) => ({ label: name, value: id }))
                : []
            )
          }
        }
      })
  }

  useEffect(() => {
    fetchChallenges()
    fetchPointsTypeData()
  }, [])

  const loadPageOptions = async (q, prevOptions, { page }) => {
    // const { options, hasMore } = await loadOptions(q, page);
    const res = await api.fetchUsersLike({
      current: page,
      size: 100,
      compositeLike: q,
      showMembership: false,
      showProfile: true,
      showProfileItems: 'first_name,last_name',
      showProfileName: false
    })
    if (res && res.data && res.data.code === 200) {
      return {
        options: res.data.data.records.map((el) => {
          return {
            value: el.id,
            label: `${
              el.profileMap && el.profileMap.first_name && el.profileMap.last_name
                ? el.profileMap.first_name + ' ' + el.profileMap.last_name
                : el.userNickname
            } (${el.userEmail})`
          }
        }),
        hasMore: res.data.data.pages > page,
        additional: {
          page: page + 1
        }
      }
    }
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0
      }
    }
  }

  return (
    <Layout>
      <Card padding={'48px'}>
        <Heading as="h2" size="xl">
          Manually Award Points
        </Heading>
        <div>
          <RadioGroup
            marginTop={'24px'}
            onChange={(event) => {
              setFormData((_formData) => {
                return { ..._formData, awardType: event, challengeId: null, pointId: null }
              })
            }}
            value={formData.awardType}
          >
            <Stack spacing={5} direction="row">
              <Radio value="IMPACT_POINT">Impact Point</Radio>
              <Radio value="CHALLENGE">Challenge</Radio>
            </Stack>
          </RadioGroup>

          {formData.awardType === 'CHALLENGE' && (
            <FormControl marginTop={'24px'} width={'320px'}>
              <FormLabel>What type of points?</FormLabel>
              <Select
                value={formData.challengeId}
                placeholder="Select option"
                onChange={(event) => {
                  setFormData((_formData) => {
                    return { ..._formData, challengeId: event.target.value }
                  })
                }}
              >
                {challenges.map((el) => (
                  <option key={`option-${el.label}`} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {formData.awardType === 'IMPACT_POINT' && (
            <FormControl marginTop={'24px'} width={'320px'}>
              <FormLabel>What type of points?</FormLabel>
              <Select
                value={formData.pointId}
                placeholder="Select option"
                onChange={(event) => {
                  setFormData((_formData) => {
                    return { ..._formData, pointId: event.target.value }
                  })
                }}
              >
                {awardTypes.map((el) => (
                  <option key={`option-${el.label}`} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl marginTop={'24px'} width={'160px'}>
            <FormLabel>How Many Points</FormLabel>
            <Input
              value={formData.pointCount}
              onChange={() =>
                setFormData((_formData) => {
                  return { ..._formData, pointCount: event.target.value }
                })
              }
            />
          </FormControl>

          <FormControl marginTop={'24px'}>
            <FormLabel>Award to all users?</FormLabel>
            <Switch
              id="email-alerts"
              isChecked={formData.allUser}
              onChange={() =>
                setFormData((_formData) => {
                  return { ..._formData, allUser: !_formData.allUser }
                })
              }
            />
          </FormControl>
          <FormControl marginTop={'24px'} id="instructor" width={'400px'}>
            <FormLabel>Users to receive points</FormLabel>
            <AsyncPaginate
              additional={{
                page: 1
              }}
              isMulti
              value={formData.userIds}
              loadOptions={loadPageOptions}
              onChange={(event) => {
                setFormData((_formData) => {
                  return { ..._formData, userIds: event }
                })
              }}
            />
            {/* <MultiSelect
              name="instructor"
              isMulti
              value={formData.userIds}
              onInputChange={search}
              onChange={(event) => {
                setFormData((_formData) => {
                  return { ..._formData, userIds: event }
                })
              }}
              options={
                users
                  ? users.map((el) => {
                      return {
                        value: el.id,
                        label: `${el.profileMap && el.profileMap.first_name && el.profileMap.last_name ? el.profileMap.first_name + ' ' + el.profileMap.last_name : el.userNickname} (${el.userEmail})`
                      }
                    })
                  : []
              }
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}
          </FormControl>
          <Box textAlign={'end'} mt={'48px'}>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Award Points'}
            </ITUButton>
          </Box>
        </div>
      </Card>
    </Layout>
  )
}
