export const getMonthWeek = (a, b, c) => {
  const date = new Date(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate()
  if (w == 0) {
    w = 7
  }
  var config = {
    getMonth: date.getMonth() + 1,
    getYear: date.getFullYear(),
    getWeek: Math.ceil((d + 6 - w) / 7)
  }
  return config
}
