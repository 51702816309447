import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Switch,
  Image,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import Pagination from '../../components/Pagination'

function ManageZoomCafe() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [createData, setCreateData] = useState({
    id: '',
    title: '',
    content: '',
    url: '',
    pageType: 'ZOOM_CAFE'
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    pageType: 'ZOOM_CAFE',
    current: 1,
    size: -1
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getJournalPromptList()
    }
  }, [loggedIn])

  const getJournalPromptList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .getZoomCafeList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: '',
      label: '',
      link: '',
      icon: '',
      active: ''
    })
  }

  const updateQuickLinkBatch = async (quickLinks) => {
    setIsLoading(true)
    const res = await api.updateQuickLinkBatch({ quickLinks }).finally(() => {
      setIsLoading(false)
      getJournalPromptList()
    })
    toast({
      title: res.data.code === 200 ? 'Success' : 'Error',
      description: res.data.code === 200 ? 'Successful sorting' : 'Failed to sort',
      status: res.data.code === 200 ? 'success' : 'error'
    })
  }

  const handleSave = () => {
    setIsLoading(true)
    ;(createData.id ? api.updateZoomCafe(createData) : api.createZoomCafe(createData))
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Zoom Cafe saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getJournalPromptList()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Zoom Cafe.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    const res = await api.deleteQuickLink({ linkId: deleteId })
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Zoom Cafe Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getJournalPromptList()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete Zoom Cafe.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Zoom Cafe</Heading>
        {/* <Flex justifyContent="flex-end">
          <Button colorScheme="teal" marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Create Zoom Cafe
          </Button>
        </Flex> */}
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'id', headerName: 'id', flex: 1, sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            { field: 'url', headerName: 'Url', flex: 1, sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            { field: 'title', headerName: 'Title', flex: 1, sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            { field: 'content', headerName: 'Content', flex: 1, sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'id',
              headerName: 'Action',
              flex: 1,
              // flex: "3",
              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const { id, ...other } = params.data
                      setCreateData({
                        id,
                        ...other
                      })
                      setIsOpen(true)
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragEnd={({ overIndex, overNode }) => {
            const list = [...tableData.list]
            const index = list.findIndex(({ id }) => id === overNode.data.id)
            const [item] = list.splice(index, 1)
            list.splice(overIndex, 0, item)
            updateQuickLinkBatch(
              list.map((item, index) => ({
                id: item.id,
                orderNum: index
              }))
            )
          }}
        ></AgGridReact>
      </div>

      {/* <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getJournalPromptList({ current, size })
          }}
        />
      </div> */}

      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Close
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Zoom Cafe</ModalHeader>
          <ModalBody>
            <FormControl id="url">
              <FormLabel>Url</FormLabel>
              <Textarea
                placeholder="Enter Url"
                value={createData.url}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    url: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="title" mt="4">
              <FormLabel>Title</FormLabel>
              <Textarea
                placeholder="Enter Title"
                value={createData.title}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    title: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="prompt" mt="4">
              <FormLabel>Content</FormLabel>
              <Textarea
                placeholder="Enter Content"
                value={createData.content}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    content: e.target.value
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageZoomCafe
