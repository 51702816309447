import React, { useState, useEffect, useMemo } from 'react'
import Layout from '../../components/Layout'
import { Heading, Text, Select, FormControl, FormLabel, Flex, Spinner, useToast } from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../../components/ITUButton'
import Pagination from '../../components/Pagination'

function OperationLog() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [tasks, setTasks] = useState([])
  const [challenges, setChallenges] = useState([])
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    current: 1,
    size: 50,
    challengeId: undefined,
    taskId: undefined
  })
  const [pageSize, setPageSize] = useState(50)
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (loggedIn) {
      getTableData(params)
    }
  }, [loggedIn, params.current, params.size])

  const [currentItem, setCurrentItem] = useState({
    id: '',
    pksProductId: '',
    membershipId: '',
    retentionEnabled: false
  })
  const onEdit = async (item) => {
    setMembershipLevel({
      label: item.memberLevelName,
      value: item.membershipId
    })
    setCurrentItem({
      id: item.id,
      retentionEnabled: item?.retentionEnabled,
      pksProductId: item.pksProductId,
      membershipId: item.membershipId
    })
    setIsModalOpen(true)
  }
  const columnDefs = [
    {
      field: 'userEmail',
      headerName: 'User Email',
      flex: '1',
      // sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: 'tableName',
      headerName: 'Table Name',
      flex: '1',
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: '1'
    },
    {
      field: 'operateTime',
      headerName: 'Operate Time',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '1'
    }
  ]

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  const getTableData = (params) => {
    setIsFetching(true)
    api.fetchRemoveAccountPage(params).then((res) => {
      if (res.data.code === 200 && res.data.data) {
        setIsFetching(false)
        setRowData(res.data.data.records)
        setTotal(res.data.data.total)
        setPages(res.data.data.pages)
      }
    })
  }

  return (
    <Layout>
      <Flex marginBottom="2rem" justifyContent="space-between" direction="column">
        <Heading>Operation Log</Heading>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        {isFetching ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
            <Spinner size="xl" marginBottom="1rem" />
            <Text>Loading data...</Text>
          </Flex>
        ) : (
          <>
            <AgGridReact
              style={{ width: '100%', height: '100%' }}
              rowData={rowData}
              columnDefs={columnDefs}
              // onSortChanged={(event) => console.log(event)}
              multiSortKey={'ctrl'}
              // pagination={true}
              // paginationPageSize={'50'}
            ></AgGridReact>
            <Pagination
              loading={isFetching}
              current={params.current}
              size={pageSize}
              total={total}
              onChange={(current, size) => {
                setPageSize(size)
                setParams((_params) => {
                  return {
                    ..._params,
                    size: size,
                    current: current
                  }
                })
              }}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default OperationLog
