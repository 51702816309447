import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { Card, Text, TableContainer, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import { api } from '../../services/api'

export const GamificationReports = () => {
  const [reportData, setReportData] = useState([])
  const fetchReports = () => {
    api.fetchGamificationReports().then((res) => {
      if (res.status === 200) {
        if (res.data.code === 200) {
          setReportData(res.data.data)
        }
      }
    })
  }

  useEffect(() => {
    fetchReports()
  }, [])

  return (
    <Layout>
      <Card padding={'48px'}>
        <Text fontSize={'2xl'}>Gamification Reports</Text>
        <TableContainer marginTop={'36px'}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th fontSize={'16px'}>Points Type</Th>
                <Th fontSize={'16px'}>Total Awarded (as of today)</Th>
                <Th fontSize={'16px'}>
                  <div>
                    <div>Total Awarded (Day 0 to )</div>
                    <div
                      style={{
                        fontWeight: '400',
                        marginTop: '12px'
                      }}
                    >
                      day 1 until (today - 30 days)
                    </div>
                  </div>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {reportData &&
                reportData.map((el, index) => (
                  <Tr key={`report-${el.pointTypeId}-${index}`}>
                    <Td>{el.pointTypeName}</Td>
                    <Td>{el.totalAward}</Td>
                    <Td>{el.total30Award}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
    </Layout>
  )
}
