import React, { useEffect } from 'react'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import {
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  Text,
  NumberIncrementStepper,
  NumberInput,
  Select,
  Flex,
  IconButton,
  Tooltip
} from '@chakra-ui/react'

const Pagination = ({ loading, current, size, total, onChange }) => {
  const totalPage = Math.ceil(total / size)
  const hasFirstPage = current > 1
  const hasPrevPage = current > 1
  const hasLastPage = current < totalPage
  const hasNextPage = current < totalPage
  useEffect(() => {
    if (current === 0) {
      onChange(current + 1, size)
    }
  }, [])

  return (
    <>
      <Flex justifyContent="end" m={1} alignItems="center">
        <Text flexShrink="0" mx={2} fontSize={14}>
          <Text mx={1} fontWeight="bold" as="span">
            {(current - 1) * size + 1}
          </Text>
          to
          <Text mx={1} fontWeight="bold" as="span">
            {(current - 1) * size + size > total ? total : (current - 1) * size + size}
          </Text>
          of
          <Text mx={1} fontWeight="bold" as="span">
            {total}
          </Text>
        </Text>
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              size={'sm'}
              onClick={() => {
                onChange(1, size)
              }}
              isDisabled={loading || !hasFirstPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              size={'sm'}
              onClick={() => {
                if (current === 1) {
                  return
                }
                onChange(current - 1, size)
              }}
              isDisabled={loading || !hasPrevPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>
        <Flex alignItems="center">
          <Text flexShrink="0" mx={2} fontSize={14}>
            Page
            <Text mx={1} fontWeight="bold" as="span">
              {current}
            </Text>
            of
            <Text mx={1} fontWeight="extrabold" as="span">
              {totalPage}
            </Text>
          </Text>
        </Flex>
        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              size={'sm'}
              onClick={() => {
                onChange(current + 1, size)
              }}
              isDisabled={loading || !hasNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              size={'sm'}
              onClick={() => onChange(totalPage, size)}
              isDisabled={loading || !hasLastPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
        <Select
          isDisabled={loading}
          mx={2}
          size={'sm'}
          w={32}
          value={size}
          onChange={(e) => {
            onChange(1, Number(e.target.value))
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
    </>
  )
}

export default Pagination
