import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Image,
  Text
} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3 } from 'react-icons/fi'
import ITUButton from '../components/ITUButton'
import Pagination from '../components/Pagination'
import RichTextEditor from '../components/RichTextEditor/index'
import { AsyncPaginate } from 'react-select-async-paginate'

function ManageQuicklinks() {
  const [info, setInfo] = useState(null)
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: -1
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    getTableData()
  }, [])

  const getTableData = (params = pageQuery) => {
    setIsLoading(true)
    api.studentMonth
      .getList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateQuickLinkBatch = async (quickLinks) => {
    setIsLoading(true)
    const res = await api.updateQuickLinkBatch({ quickLinks }).finally(() => {
      setIsLoading(false)
      getTableData()
    })
    toast({
      title: res.data.code === 200 ? 'Success' : 'Error',
      description: res.data.code === 200 ? 'Successful sorting' : 'Failed to sort',
      status: res.data.code === 200 ? 'success' : 'error'
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    const params = {
      ...info,
      mostMonth: +dayjs(info.mostMonth).format('YYYYMM')
    }
    if (!info.userId) {
      return toast({
        title: 'Info',
        description: 'Student is Required.',
        status: 'info'
      })
    }
    if (!info.biography) {
      return toast({
        title: 'Info',
        description: 'Student Biography is Required.',
        status: 'info'
      })
    }
    if (!info.studentPhoto) {
      return toast({
        title: 'Info',
        description: 'Mobile StudentPhoto is Required.',
        status: 'info'
      })
    }
    if (!info.studentPhotoForPC) {
      return toast({
        title: 'Info',
        description: 'StudentPhoto is Required.',
        status: 'info'
      })
    }

    ;(params.id ? api.studentMonth.update(params) : api.studentMonth.create(params))
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        setInfo(null)
        getTableData()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    const res = await api.deleteQuickLink({ linkId: deleteId })
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: ' Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete QuickLick.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  const uploadIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadIconLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadIconLoading(false)
      })
    if (data.data) {
      setInfo({
        ...info,
        studentPhoto: data.data
      })
    }
  }

  const [uploadStudentPhotoForPCLoading, setUploadStudentPhotoForPCLoading] = useState(false)
  const uploadStudentPhotoForPC = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadStudentPhotoForPCLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadStudentPhotoForPCLoading(false)
      })
    if (data.data) {
      setInfo({
        ...info,
        studentPhotoForPC: data.data
      })
    }
  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const res = await api.fetchUsersLike({
      current: page,
      size: 100,
      compositeLike: q,
      showMembership: false,
      showProfile: true,
      showProfileItems: 'first_name,last_name',
      showProfileName: false
    })
    if (res && res.data && res.data.code === 200) {
      let options = res?.data?.data?.records?.map((el) => {
        return {
          value: el.id,
          label: `${
            el.profileMap && el.profileMap.first_name && el.profileMap.last_name
              ? el.profileMap.first_name + ' ' + el.profileMap.last_name
              : el.userNickname
          } (${el.userEmail})`
        }
      })

      options = options.filter(({ value }) => value !== info.userId)

      return {
        options,
        hasMore: res.data.data.pages > page,
        additional: {
          page: page + 1
        }
      }
    }
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0
      }
    }
  }

  return (
    <Layout>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Student of the Month</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setInfo({})}>
            Add
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'id', headerName: 'ID', flex: 1 },
            {
              field: 'userNickName',
              headerName: 'Name',
              flex: 1,
              cellRenderer: ({ data: { firstName, lastName, userNickName } }) => {
                if (firstName || lastName) {
                  return `${firstName} ${lastName}`
                }
                return userNickName
              }
            },
            {
              field: 'MostMonth',
              headerName: 'Month/Year',
              flex: 1,
              cellRenderer: (params) => {
                const str = `${params.data.mostMonth}`.split('')
                str.splice(4, 0, '-')
                return str
              }
            },
            {
              field: 'id',
              headerName: 'Action',
              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const mostMonth = `${params.data.mostMonth}`.split('')
                      mostMonth.splice(4, 0, '-')
                      setInfo({
                        ...params.data,
                        mostMonth: mostMonth.join('')
                      })
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragEnd={({ overIndex, overNode }) => {
            const list = [...tableData.list]
            const index = list.findIndex(({ id }) => id === overNode.data.id)
            const [item] = list.splice(index, 1)
            list.splice(overIndex, 0, item)
            updateQuickLinkBatch(
              list.map((item, index) => ({
                id: item.id,
                orderNum: index
              }))
            )
          }}
        ></AgGridReact>
      </div>

      <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getTableData({ current, size })
          }}
        />
      </div>
      {info ? (
        <Modal isOpen={true} onClose={() => setInfo(null)} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{info.id ? 'Edit' : 'Create'} Student</ModalHeader>
            <ModalBody>
              <FormControl id="title">
                <FormLabel>Student</FormLabel>
                <div>
                  <AsyncPaginate
                    defaultOptions={[
                      {
                        value: info.userId,
                        label: info.userNickName
                      }
                    ]}
                    additional={{
                      page: 1
                    }}
                    value={{
                      value: info.userId,
                      label: info.userNickName
                    }}
                    defaultValue={{
                      value: info.userId,
                      label: info.userNickName
                    }}
                    loadOptions={loadPageOptions}
                    onChange={(value) => {
                      console.log(value)
                      setInfo({
                        ...info,
                        userNickName: value.label,
                        userId: value.value
                      })
                    }}
                  />
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Student Biography</FormLabel>
                <RichTextEditor
                  value={info.biography}
                  onChange={(biography) =>
                    setInfo({
                      ...info,
                      biography
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Mobile StudentPhoto</FormLabel>
                <Flex flexWrap={'wrap'}>
                  {info.studentPhoto ? (
                    <>
                      <Image h={'100px'} src={info.studentPhoto} alt="studentPhoto" />
                    </>
                  ) : null}

                  <ITUButton size="xs" variant="outline" as="label" htmlFor="studentPhoto">
                    {info.studentPhoto ? 'Change' : 'Upload'}
                    {uploadIconLoading ? <Spinner /> : null}
                    <input hidden id="studentPhoto" type="file" accept="image/*" onChange={uploadIcon} />
                  </ITUButton>
                </Flex>
                <Text fontSize="xs">please upload limit to 50 pixels by 50 pixels</Text>
              </FormControl>

              <FormControl>
                <FormLabel>StudentPhoto</FormLabel>
                <Flex flexWrap={'wrap'}>
                  {info.studentPhotoForPC ? (
                    <>
                      <Image h={'100px'} src={info.studentPhotoForPC} alt="studentPhotoForPC" />
                    </>
                  ) : null}

                  <ITUButton size="xs" variant="outline" as="label" htmlFor="studentPhotoForPC">
                    {info.studentPhotoForPC ? 'Change' : 'Upload'}
                    {uploadStudentPhotoForPCLoading ? <Spinner /> : null}
                    <input hidden id="studentPhotoForPC" type="file" accept="image/*" onChange={uploadStudentPhotoForPC} />
                  </ITUButton>
                </Flex>
                <Text fontSize="xs">please upload limit to 1260 pixels by 560 pixels</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Month</FormLabel>
                <input
                  type="month"
                  value={info.mostMonth}
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      mostMonth: e.target.value
                    })
                  }}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ITUButton variant="outline" mr={3} onClick={() => setInfo(null)}>
                Cancel
              </ITUButton>
              <ITUButton onClick={handleSave} disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : 'Save'}
              </ITUButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </Layout>
  )
}

export default ManageQuicklinks
