import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react'
import Button from './components/Button'
import Icon from './components/Icon'
import { Transforms } from 'slate'
import { Box, Image, Link } from '@chakra-ui/react'

export const ImageComponent = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <Box as="div" contentEditable={false} position={'relative'}>
        <Image alt="" src={element.url} display={'block'} maxW={'100%'} maxH={'20em'} boxShadow={selected && focused ? 'md' : 'none'} />
        <Box
          as={Button}
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          display={selected && focused ? 'inline' : 'none'}
          position={'absolute'}
          top={1}
          left={1}
          bg={'white'}
        >
          <Icon>delete</Icon>
        </Box>
      </Box>
    </div>
  )
}

export const InlineChromiumBugfix = () => (
  <Box as="span" contentEditable={false} fontSize={0}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </Box>
)

export const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected()
  return (
    <Link {...attributes} target="_blank" href={element.url} boxShadow={selected ? 'md' : 'none'}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </Link>
  )
}

export const EditableButtonComponent = ({ attributes, children }) => {
  return (
    /*
      Note that this is not a true button, but a span with button-like CSS.
      True buttons are display:inline-block, but Chrome and Safari
      have a bad bug with display:inline-block inside contenteditable:
      - https://bugs.webkit.org/show_bug.cgi?id=105898
      - https://bugs.chromium.org/p/chromium/issues/detail?id=1088403
      Worse, one cannot override the display property: https://github.com/w3c/csswg-drafts/issues/3226
      The only current workaround is to emulate the appearance of a display:inline button using CSS.
    */
    <Box
      as="span"
      {...attributes}
      margin={'0 0.1em'}
      bgColor={'#efefef'}
      p={'2px 6px'}
      border={'1px solid #767676'}
      rounded={'sm'}
      fontSize={'0.9em'}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </Box>
  )
}

export const BadgeComponent = ({ attributes, children, element }) => {
  const selected = useSelected()

  return (
    <span
      {...attributes}
      contentEditable={false}
      bgColor="green"
      color="white"
      p="2px 6px;"
      rounded="sm"
      fontSize="0.9em"
      boxShadow={selected ? 'md' : 'none'}
      data-playwright-selected={selected}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  )
}
