import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  HStack,
  Stack,
  Radio,
  Tag,
  useToast,
  Input,
  Text,
  Select,
  Switch,
  Image,
  Box,
  Card,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  CardBody
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import { getMonthWeek } from '../../utils'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
// import Pagination from '../../components/Pagination'
import RichTextEditor from '../../components/RichTextEditor'
import ITUButton from '../../components/ITUButton'

const weekIndex = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth']
const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const times = [
  '12:00 am',
  '12:30 am',
  '1:00 am',
  '1:30 am',
  '2:00 am',
  '2:30 am',
  '3:00 am',
  '3:30 am',
  '4:00 am',
  '4:30 am',
  '5:00 am',
  '5:30 am',
  '6:00 am',
  '6:30 am',
  '7:00 am',
  '7:30 am',
  '8:00 am',
  '8:30 am',
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm'
]
function ManageEvents() {
  const toast = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [updateType, setUpdateType] = useState('ThisEvent')
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [createData, setCreateData] = useState({
    promptId: undefined,
    promptText: '',
    promptTitle: '',
    active: true,
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    eventMultiDay: null
    // eventMultiDay: {
    //   ends: 'After',
    //   endsAfter: 0,
    //   endsOn: new Date().getTime(),
    //   happens: 'Once',
    //   loopInterval: 0,
    //   monthlyOn: 'DayInWeekOfMonth'
    // }
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    current: 0,
    size: 5000
  })
  const [isLoading, setIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const getTableData = (params = pageQuery) => {
    setIsLoading(true)
    api
      .fetchEventList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: undefined,
      promptId: undefined,
      promptText: '',
      promptTitle: '',
      active: true,
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      eventMultiDay: null
      // eventMultiDay: {
      //   ends: 'After',
      //   endsAfter: 0,
      //   endsOn: new Date().getTime(),
      //   happens: 'Once',
      //   loopInterval: 0,
      //   monthlyOn: 'DayInWeekOfMonth'
      // }
    })
  }
  const handleSave = () => {
    if (createData.eventMultiDay) {
      if (
        createData.eventMultiDay.happens === 'Daily' &&
        createData.eventMultiDay.ends === 'After' &&
        !createData.eventMultiDay.endsAfter
      ) {
        toast({
          title: 'Error',
          description: 'Please Enter endsAfter',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        return
      }
    }
    setIsLoading(true)
    ;(createData.id
      ? api.updateEvent({
          ...createData,
          modifyRange: updateType
        })
      : api.crentEvent(createData)
    )
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Events saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getTableData()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Events.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    // const await
    const res = await api.deleteEvent({ eventId: deleteId })
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Events Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete prompt.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [seriesList, setSeriesList] = useState([])
  const getSeries = async () => {
    const res = await api.fetchSeriesList()
    setSeriesList(
      res?.data?.data?.records?.map(({ id, name }) => ({
        key: id,
        value: name
      }))
    )
  }
  const [categoryList, setCategoryList] = useState([])
  const getCategory = async () => {
    const res = await api.fetchCategory()
    setCategoryList(res?.data?.data?.map(({ id, name }) => ({ key: id, value: name })))
  }

  useEffect(() => {
    getSeries()
    getCategory()
    getTableData()
  }, [])

  return (
    <Layout>
      {/* delete modal */}
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Close
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Events</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setCreateData({
                content: '[{"type":"paragraph","children":[{"text":"nihao"}]}]',
                startDate: new Date().getTime(),
                endDate: new Date().getTime(),
                eventMultiDay: null
              })
              setIsEditOpen(false)
              setIsOpen(true)
            }}
          >
            Add Event
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine relative" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        {isLoading ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
            <Spinner size="xl" marginBottom="1rem" />
            <Text>Loading events, one moment…</Text>
          </Flex>
        ) : (
          <AgGridReact
            pagination
            style={{ width: '100%', height: '100%' }}
            rowData={tableData.list}
            columnDefs={[
              { field: 'title', headerName: 'Title', flex: '2', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
              { field: 'id', headerName: 'ID', flex: '2', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
              {
                field: 'startDate',
                headerName: 'Event Date',
                valueFormatter: (params) => {
                  return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
                },
                flex: '2',
                sortable: true,
                headerTooltip: 'Search',
                filter: 'agTextColumnFilter'
              },
              {
                field: 'seriesList',
                valueFormatter: (params) => {
                  return params.value[0]?.name || ''
                },
                headerName: 'Series',
                sortable: true,
                headerTooltip: 'Search',
                filter: 'agTextColumnFilter'
              },
              {
                field: 'id',
                headerName: 'Action',
                cellRenderer: (params) => (
                  <>
                    <Button
                      size={'sm'}
                      mr={2}
                      onClick={() => {
                        const { id, ...other } = params.data
                        setCreateData(params.data)
                        setIsEditOpen(false)
                        setIsOpen(true)
                      }}
                    >
                      <FiEdit3 />
                    </Button>
                    <Button size={'sm'} onClick={() => setDeleteId(params.value)}>
                      <FiTrash2 />
                    </Button>
                  </>
                ),
                flex: '1'
              }
            ]}
          ></AgGridReact>
        )}
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Events</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Enter title"
                value={createData.title}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    title: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Content</FormLabel>
              <Box rounded={'md'} border={'1px'}>
                <RichTextEditor
                  key={isOpen}
                  value={createData.content}
                  onChange={(content) => {
                    setCreateData({
                      ...createData,
                      content
                    })
                  }}
                />
              </Box>
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter Description"
                value={createData.description}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    description: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Series</FormLabel>
              <Select
                value={createData.seriesId}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    seriesId: e.target.value
                  })
                }}
              >
                {seriesList.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Category</FormLabel>
              <Select
                value={createData.categoryId}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    categoryId: e.target.value
                  })
                }}
              >
                {categoryList.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt="4">
              <FormLabel>Date</FormLabel>
              <Flex>
                <Input
                  type="datetime-local"
                  value={dayjs(createData.startDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={(e) => {
                    setCreateData({
                      ...createData,
                      startDate: new Date(e.target.value).getTime()
                    })
                  }}
                />
                <Text>-</Text>
                <Input
                  type="datetime-local"
                  value={dayjs(createData.endDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={(e) => {
                    setCreateData({
                      ...createData,
                      endDate: new Date(e.target.value).getTime()
                    })
                  }}
                />
              </Flex>
            </FormControl>
            {createData.eventMultiDay && (
              <Card mt="4">
                <CardBody>
                  <FormControl mt="4">
                    <FormLabel>Happens</FormLabel>
                    <Select
                      value={createData.eventMultiDay.happens}
                      onChange={(e) => {
                        setCreateData((_createData) => {
                          return {
                            ..._createData,
                            eventMultiDay: {
                              ..._createData.eventMultiDay,
                              happens: e.target.value
                            }
                          }
                        })
                      }}
                    >
                      {['Once', 'Daily', 'Weekly', 'Monthly', 'Yearly'].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {['Daily', 'Weekly', 'Monthly', 'Yearly'].includes(createData.eventMultiDay.happens) && (
                    <FormControl mt="4">
                      <FormLabel>Every</FormLabel>
                      <Flex gap={'24px'} alignItems={'center'}>
                        <Select
                          width={'200px'}
                          value={createData.eventMultiDay.loopInterval}
                          onChange={(e) => {
                            setCreateData((_createData) => {
                              return {
                                ..._createData,
                                eventMultiDay: {
                                  ..._createData.eventMultiDay,
                                  loopInterval: e.target.value
                                }
                              }
                            })
                          }}
                        >
                          {(createData.eventMultiDay.happens === 'Monthly'
                            ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
                            : ['1', '2', '3', '4', '5', '6']
                          ).map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Select>{' '}
                        {createData.eventMultiDay.happens === 'Daily'
                          ? 'day'
                          : createData.eventMultiDay.happens === 'Weekly'
                          ? 'week'
                          : createData.eventMultiDay.happens === 'Monthly'
                          ? 'month'
                          : 'year'}
                      </Flex>
                    </FormControl>
                  )}
                  {createData.eventMultiDay.happens !== 'Once' && (
                    <FormControl mt="4">
                      <FormLabel>Ends</FormLabel>
                      <Flex gap={'24px'}>
                        <Select
                          width={'200px'}
                          value={createData.eventMultiDay.ends}
                          onChange={(e) => {
                            setCreateData((_createData) => {
                              return {
                                ..._createData,
                                eventMultiDay: {
                                  ..._createData.eventMultiDay,
                                  ends: e.target.value
                                }
                              }
                            })
                          }}
                        >
                          {['On', 'After', 'Never'].map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Select>{' '}
                        {createData.eventMultiDay.ends === 'On' ? (
                          <Input
                            type="date"
                            value={dayjs(createData.eventMultiDay.endsOn).format('YYYY-MM-DD')}
                            onChange={(e) => {
                              setCreateData((_createData) => {
                                return {
                                  ..._createData,
                                  eventMultiDay: {
                                    ..._createData.eventMultiDay,
                                    endsOn: new Date(e.target.value).getTime()
                                  }
                                }
                              })
                            }}
                          />
                        ) : (
                          <Flex gap={'24px'} alignItems={'center'}>
                            <NumberInput>
                              <NumberInputField
                                onChange={(e) => {
                                  setCreateData((_createData) => {
                                    return {
                                      ..._createData,
                                      eventMultiDay: {
                                        ..._createData.eventMultiDay,
                                        endsAfter: e.target.value
                                      }
                                    }
                                  })
                                }}
                                value={createData.eventMultiDay.endsAfter}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <span>events</span>
                          </Flex>
                        )}
                      </Flex>
                    </FormControl>
                  )}
                  {['Monthly', 'Yearly'].includes(createData.eventMultiDay.happens) && (
                    <FormControl mt="4">
                      <FormLabel>On the</FormLabel>
                      <Flex gap={'24px'} alignItems={'center'}>
                        <Select
                          width={'200px'}
                          value={createData.eventMultiDay.monthlyOn}
                          onChange={(e) => {
                            setCreateData((_createData) => {
                              return {
                                ..._createData,
                                eventMultiDay: {
                                  ..._createData.eventMultiDay,
                                  monthlyOn: e.target.value
                                }
                              }
                            })
                          }}
                        >
                          <option value={'DayInWeekOfMonth'}>
                            {`${
                              weekIndex[
                                getMonthWeek(
                                  dayjs(createData.startDate).year(),
                                  dayjs(createData.startDate).month() + 1,
                                  dayjs(createData.startDate).date()
                                ).getWeek - 1
                              ]
                            } ${weeks[dayjs(createData.startDate).day()]}`}
                          </option>
                          <option value={'DayOfMonth'}>day {dayjs(createData.startDate).date()}</option>
                        </Select>{' '}
                      </Flex>
                    </FormControl>
                  )}

                  {/* {createData.eventMultiDay.happens === 'Weekly' && (
                  <FormControl mt="4">
                    <FormLabel>On</FormLabel>
                    <HStack spacing={4}>
                      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((item) => (
                        <Tag width="40px" height="40px" justifyContent="center" key={item} variant="solid" colorScheme="teal">
                          {item}
                        </Tag>
                      ))}
                    </HStack>
                  </FormControl>
                )} */}
                  {createData.eventMultiDay.happens === 'Once' && (
                    <FormControl mt="4">
                      <FormLabel>On</FormLabel>
                      <Input
                        type="date"
                        value={dayjs(createData.eventMultiDay.endsOn).format('YYYY-MM-DD')}
                        onChange={(e) => {
                          setCreateData((_createData) => {
                            return {
                              ..._createData,
                              eventMultiDay: {
                                ..._createData.eventMultiDay,
                                endsOn: new Date(e.target.value).getTime()
                              }
                            }
                          })
                        }}
                      />
                    </FormControl>
                  )}

                  {/* {createData.eventMultiDay.happens === 'Once' && (
                  <FormControl mt="4">
                    <FormLabel>From</FormLabel>
                    <Flex>
                      <Select
                        value={createData.seriesId}
                        onChange={(e) => {
                          setCreateData({
                            ...createData,
                            seriesId: e.target.value
                          })
                        }}
                      >
                        {times.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                      <Text>-</Text>
                      <Select
                        value={createData.seriesId}
                        onChange={(e) => {
                          setCreateData({
                            ...createData,
                            seriesId: e.target.value
                          })
                        }}
                      >
                        {times.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  </FormControl>
                )} */}
                  <FormControl mt="4" display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      onClick={() => {
                        setCreateData((_createData) => {
                          return {
                            ..._createData,
                            eventMultiDay: null
                          }
                        })
                      }}
                    >
                      Cancel Repeat
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            )}

            {createData.eventMultiDay || createData.id ? null : (
              <FormControl mt="4">
                <Button
                  onClick={() => {
                    setCreateData((_createData) => {
                      return {
                        ..._createData,
                        eventMultiDay: {
                          ends: 'After',
                          endsAfter: 0,
                          endsOn: new Date().getTime(),
                          happens: 'Once',
                          loopInterval: 1,
                          monthlyOn: 'DayInWeekOfMonth'
                        }
                      }
                    })
                  }}
                >
                  + Repeat This Event
                </Button>
              </FormControl>
            )}
            <FormControl mt="4">
              <FormLabel>Time Zone</FormLabel>
              <Input
                placeholder="time zone"
                value={createData.timeZone}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    timeZone: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>zoom</FormLabel>
              <Input
                placeholder="zoomUrl"
                value={createData.zoomUrl}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    zoomUrl: e.target.value
                  })
                }}
              />
            </FormControl>

            <FormControl mt="4">
              <FormLabel>Banner Image</FormLabel>
              {createData.bannerImageUrl && <Image alt="bannerImageUrl" h="150px" src={createData.bannerImageUrl} />}

              <ITUButton size="xs" as="label" for="trackImageUrlInput">
                {createData.bannerImageUrl ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                id="trackImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setCreateData({
                            ...createData,
                            bannerImageUrl: res.data.data
                          })
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Mobile Image</FormLabel>
              {createData.mobileBannerImageUrl && <Image alt="mobileBannerImageUrl" h="150px" src={createData.mobileBannerImageUrl} />}

              <ITUButton size="xs" as="label" for="MobileImageImageUrlInput">
                {createData.mobileBannerImageUrl ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                id="MobileImageImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setCreateData({
                            ...createData,
                            mobileBannerImageUrl: res.data.data
                          })
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>

            <FormControl mt="4">
              <FormLabel>Address</FormLabel>
              <Textarea
                placeholder="Enter address"
                value={createData.address}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    address: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Show map?</FormLabel>
              <Switch
                isChecked={createData.showMap}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    showMap: e.target.checked
                  })
                }}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel>Add to calendar option?</FormLabel>
              <Switch
                isChecked={createData.calendarOption}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    calendarOption: e.target.checked
                  })
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton
              onClick={() => {
                if (createData.id) {
                  setIsEditOpen(true)
                } else {
                  handleSave()
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
          <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit recurring event</ModalHeader>
              <ModalBody>
                <Stack>
                  <Radio
                    onChange={() => {
                      setUpdateType('ThisEvent')
                    }}
                    value={updateType}
                    isChecked={updateType === 'ThisEvent'}
                    size="md"
                    name="ThisEvent"
                  >
                    This event
                  </Radio>
                  <Radio
                    onChange={() => {
                      setUpdateType('ThisAndFollowing')
                    }}
                    value={updateType}
                    isChecked={updateType === 'ThisAndFollowing'}
                    size="md"
                    name="ThisAndFollowing"
                  >
                    This and following events
                  </Radio>
                  <Radio
                    onChange={() => {
                      setUpdateType('All')
                    }}
                    value={updateType}
                    isChecked={updateType === 'All'}
                    size="md"
                    name="All"
                  >
                    All events
                  </Radio>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={() => setIsEditOpen(false)}>
                  Cancel
                </Button>
                <Button colorScheme="blue" onClick={() => handleSave()}>
                  OK
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageEvents
