import Button from './components/Button'
import Icon from './components/Icon'
import {
  TEXT_ALIGN_TYPES,
  insertButton,
  insertImage,
  insertLink,
  isBlockActive,
  isButtonActive,
  isLinkActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
  unwrapButton,
  unwrapLink
} from './utils.js'
import { useSlate, useSlateStatic } from 'slate-react'
import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { api } from '../../services/api.js'

export const InsertImageButton = () => {
  const editor = useSlateStatic()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onChange = async (e) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    setLoading(true)
    toast({ title: 'Success', description: 'Uploading image...', status: 'loading' })
    const formData = new FormData()
    formData.append('file', file)
    formData.append('resourceType', 'document')
    const res = await api.uploadFile(formData)
    if (res.data.data) {
      toast.closeAll()
      toast({ title: 'Success', description: 'Upload successful!', status: 'success' })
      insertImage(editor, res.data.data)
    } else {
      toast.closeAll()
      toast({
        title: 'Error',
        description: res.data.msg || 'Oops. There was an error with the upload. Please contact tech support.',
        status: 'error'
      })
    }
  }

  return (
    <>
      <Button>
        <label htmlFor="edit-images-input">
          <Icon>image</Icon>
          <input onChange={onChange} type="file" accept="image/*" id="edit-images-input" name="edit-images-input" hidden />
        </label>
      </Button>
    </>
  )
}

export const AddLinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Icon>link</Icon>
    </Button>
  )
}

export const ToggleEditableButtonButton = () => {
  const editor = useSlate()
  return (
    <Button
      active
      onMouseDown={(event) => {
        event.preventDefault()
        if (isButtonActive(editor)) {
          unwrapButton(editor)
        } else {
          insertButton(editor)
        }
      }}
    >
      <Icon>smart_button</Icon>
    </Button>
  )
}

export const RemoveLinkButton = () => {
  const editor = useSlate()

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault()
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <Icon>link_off</Icon>
    </Button>
  )
}

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
