// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-text h1 {
  font-size: 2rem;
}
.rich-text h2 {
  font-size: 1.3rem;
}
.rich-text a {
  color: #00808a;
  cursor: pointer;
}
.rich-text a:hover {
  text-decoration: underline;
}
.rich-text code {
  background: #bebebe;
  border-radius: 2px;
  padding: 2px;
}
.rich-text img {
  max-width: 100%;
  max-height: 300px;
}
.rich-text ol {
  list-style: inside;
}
.rich-text ul {
  list-style: inside;
  list-style-type: auto;
}
.rich-text .toolbar {
  position: relative;
  padding: 1.25rem 0 1rem 0;
  margin: 1.25rem 0;
  border-bottom: #eee solid 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RichTextEditor/index.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;AAAJ;AAFA;EAOI,iBAAA;AAFJ;AALA;EAUI,cAAA;EACA,eAAA;AAFJ;AAGI;EACE,0BAAA;AADN;AAZA;EAiBI,mBAAA;EACA,kBAAA;EACA,YAAA;AAFJ;AAjBA;EAuBI,eAAA;EACA,iBAAA;AAHJ;AArBA;EA2BI,kBAAA;AAHJ;AAxBA;EA+BI,kBAAA;EACA,qBAAA;AAJJ;AA5BA;EAmCI,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,6BAAA;AAJJ","sourcesContent":[".rich-text {\n  h1 {\n    font-size: 2rem;\n  }\n\n  // h2\n  h2 {\n    font-size: 1.3rem;\n  }\n  a {\n    color: #00808a;\n    cursor: pointer;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n  code {\n    background: #bebebe;\n    border-radius: 2px;\n    padding: 2px;\n  }\n\n  img {\n    max-width: 100%;\n    max-height: 300px;\n  }\n  ol {\n    list-style: inside;\n  }\n\n  ul {\n    list-style: inside;\n    list-style-type: auto;\n  }\n  .toolbar {\n    position: relative;\n    padding: 1.25rem 0 1rem 0;\n    margin: 1.25rem 0;\n    border-bottom: #eee solid 2px;\n  }\n}\n\n// undefined relative pt-0.5 py-5 pb-4 my-5 mb-5 border-b-[#eee] border-b-2 flex flex-wrap gap-1\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
