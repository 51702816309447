import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { Heading } from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

function Courses() {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Heading marginBottom="2rem">ITU Courses</Heading>
      <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        THis is some courseS!
      </div>
    </Layout>
  )
}

export default Courses
