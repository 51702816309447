import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  Input,
  Select,
  useToast,
  Switch,
  Image,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import MultiSelect from 'react-select'
import ITUButton from '../../components/ITUButton'

function ManageForums() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [membershipLevel, setMembershipLevel] = useState([])
  const [membershipLevels, setMembershipLevels] = useState([])
  const [allForums, setAllForums] = useState([])
  const [createData, setCreateData] = useState({
    id: '',
    bannerImage: '',
    forumDesc: '',
    forumName: '',
    forumSlug: '',
    membershipLevelIds: '',
    parentForumId: '',
    status: '',
    visibility: '',
    showVideoPage: false
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: 999
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getForumsList()
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
      api
        .fetchForumsList({
          current: 1,
          size: 9999
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setAllForums(res.data.data.records)
            }
          }
        })
    }
  }, [loggedIn])

  const getForumsList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .fetchForumsList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setMembershipLevel([])
    setCreateData({
      id: '',
      bannerImage: '',
      forumDesc: '',
      forumName: '',
      forumSlug: '',
      membershipLevelIds: '',
      parentForumId: '',
      status: '',
      visibility: ''
    })
  }

  const handleSave = () => {
    if (!createData.forumName) {
      toast({
        title: 'Error',
        description: 'Please enter name.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    if (!createData.visibility) {
      toast({
        title: 'Error',
        description: 'Please select visibility.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    setIsLoading(true)
    ;(createData.id
      ? api.updateForums({
          ...createData,
          membershipLevelIds: membershipLevel.map((el) => el.value).toString()
        })
      : api.createForums({
          ...createData,
          membershipLevelIds: membershipLevel.map((el) => el.value).toString()
        })
    )
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Forum saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getForumsList()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Forum.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    const res = await api.deleteForums([deleteId])
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Forum Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getForumsList()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete prompt.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  const uploadIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadIconLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadIconLoading(false)
      })
    if (data.data) {
      setCreateData({
        ...createData,
        bannerImage: data.data
      })
    }
  }

  return (
    <Layout>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure you want to delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Forums</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Add Forum
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          pagination
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'forumName', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'visibility',
              headerName: 'Visibility',
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            { field: 'status', headerName: 'Status', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'membershipLevelIds',
              headerName: 'Membership Levels',
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'createdAt',
              headerName: 'Created Date',
              flex: '1',
              valueFormatter: (params) => {
                return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
              },
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'id',
              headerName: 'Action',
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const { id, ...other } = params.data
                      setCreateData({
                        id,
                        ...other
                      })
                      setMembershipLevel(
                        other.membershipLevels
                          ? other.membershipLevels.map((el) => {
                              return {
                                value: el.id,
                                label: el.levelName
                              }
                            })
                          : []
                      )
                      setIsOpen(true)
                      setIsEdit(true)
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                  <Button size={'sm'} onClick={() => setDeleteId(params.value)}>
                    <FiTrash2 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragMove={(a) => {
            // console.log(a);
          }}
        ></AgGridReact>
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit' : 'Create'} Forum</ModalHeader>
          <ModalBody>
            <FormControl id="title">
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter Name"
                value={createData.forumName}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    forumName: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="prompt">
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter Description"
                value={createData.forumDesc}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    forumDesc: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="prompt">
              <FormLabel>Banner Image</FormLabel>
              <flex>
                {createData.bannerImage ? (
                  <>
                    <Image boxSize="100px" objectFit="cover" src={createData.bannerImage} alt="icon" />
                  </>
                ) : null}

                <ITUButton size="xs" variant="outline" as="label" htmlFor="icon" m="3">
                  {createData.bannerImage ? 'Change' : 'Upload'}
                  {uploadIconLoading ? <Spinner /> : null}
                  <input hidden id="icon" type="file" accept="image/*" onChange={uploadIcon} />
                </ITUButton>
              </flex>
              <Text fontSize="xs">Please limit upload size to 1240 pixels by 1240 pixels</Text>
            </FormControl>
            <FormControl id="prompt">
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                value={createData.status}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    status: e.target.value
                  })
                }
              >
                <option value="CLOSE">CLOSE</option>
                <option value="OPEN">OPEN</option>
              </Select>
            </FormControl>
            <FormControl id="prompt">
              <FormLabel>Visibility</FormLabel>
              <Select
                placeholder="Select Visibility"
                value={createData.visibility}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    visibility: e.target.value
                  })
                }
              >
                <option value="HIDDEN">HIDDEN</option>
                <option value="PASSWORD_PROTECTED">PASSWORD_PROTECTED</option>
                <option value="PRIVATE">PRIVATE</option>
                <option value="PUBLIC">PUBLIC</option>
              </Select>
            </FormControl>
            <FormControl id="prompt">
              <FormLabel>
                Parent Forum <span className="text-[##ff0000]">*</span>
              </FormLabel>
              <Select
                placeholder="Select Parent Forum"
                value={createData.parentForumId}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    parentForumId: e.target.value
                  })
                }
              >
                {allForums.map((el) => (
                  <option key={`forum-${el.id}`} value={el.id}>
                    {el.forumName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="membershipLevel">
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={(event) => setMembershipLevel(event)}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageForums
