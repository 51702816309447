import React, { useState, useEffect } from 'react'
import { Box, CloseButton, Flex, VStack, Icon, useColorModeValue, Text, Collapse } from '@chakra-ui/react'
import { FiAward, FiHome, FiSettings, FiBell, FiCheckCircle, FiChevronDown, FiUser, FiBookOpen } from 'react-icons/fi'
import { BsMortarboard } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { TimeIcon } from '@chakra-ui/icons'

const LinkItems = [
  {
    name: 'Report',
    icon: BsMortarboard,
    path: '/itu/report',
    children: [
      { name: ' Like Post and Commit', path: '/itu/report/track' },
      { name: 'Manage Assignment', path: '/itu/report/manage-videos' },
      { name: 'Operation Log', path: '/itu/report/operation-log' },
      { name: 'Video', path: '/itu/report/video' }
    ]
  },
  {
    name: 'Users',
    icon: BsMortarboard,
    path: '/itu/users',
    children: [
      { name: 'Manage Students', path: '/itu/users' },
      { name: 'Manage Student of the Month', path: '/itu/users/student-month' },
      { name: 'Manage Profile Types', path: '/itu/users/profileType' },
      {
        name: 'Manage Membership Levels',
        path: '/itu/users/levels'
      }
    ]
  },
  {
    name: 'Courses',
    path: '/itu/courses',
    icon: FiBookOpen,
    children: [
      { name: 'Manage Courses', path: '/itu/courses/createCourse' },
      { name: 'Manage Lessons', path: '/itu/courses/createLesson' },
      { name: 'Manage Topics', path: '/itu/courses/manageTopics' },
      { name: 'Manage Phases', path: '/itu/courses/createPhase' }
    ]
  },
  {
    name: 'Groups and Forums',
    icon: FiUser,
    path: '/itu/groups',
    children: [
      { name: 'Manage Groups', path: '/itu/groups' },
      { name: 'Manage Group Types', path: '/itu/groupTypes' },
      { name: 'Manage Forums', path: '/itu/groups/forums' },
      { name: 'Manage Chat Board Topics', path: '/itu/groups/ituVoice' },
      { name: 'Manage Challenges', path: '/itu/groups/challenge' }
    ]
  },
  {
    name: 'Notifications',
    icon: FiBell,
    path: '/itu/notifications',
    children: [
      { name: 'Manage Message Notifications', path: '/itu/notifications' },
      { name: 'Manage Notification Banners ', path: '/itu/notificationBanner' }
    ]
  },
  {
    name: 'Journals and Goals',
    path: '/itu/journals-goals',
    icon: FiCheckCircle,
    children: [
      {
        name: 'Manage Journal Prompts',
        path: '/itu/journals-goals/manage-journal-prompts'
      },
      {
        name: 'Manage Goal Categories',
        path: '/itu/journals-goals/manageGoalCategory'
      }
    ]
  },
  {
    name: 'Gamification',
    icon: FiAward,
    path: '/itu/gamification',
    children: [
      {
        name: 'Manually Award Points',
        path: '/itu/manuallyAwardPoints'
      },
      {
        name: 'Manually Award Badges',
        path: '/itu/manuallyAwardBadges'
      },
      {
        name: 'Manage Award Triggers',
        path: '/itu/manageAwardTriggers'
      },
      {
        name: 'Manage Point Types',
        path: '/itu/managePointTypes'
      },
      {
        name: 'Manage Badges',
        path: '/itu/manageBadges'
      },
      {
        name: 'Manage Ranks',
        path: '/itu/manageRanks'
      },
      {
        name: 'Manage Leaderboards',
        path: '/itu/manageLeaderboards'
      },
      {
        name: 'Gamification Reports',
        path: '/itu/gamificationReports'
      }
    ]
  },
  {
    name: 'Home Page',
    icon: FiHome,
    path: '/itu/quicklinks',
    children: [
      {
        name: 'Manage Quick Links',
        path: '/itu/quicklinks'
      },
      {
        name: 'Manage Hero Carousel',
        path: '/itu/heroSection'
      }
    ]
  },
  {
    name: 'General Settings',
    icon: FiSettings,
    path: '/itu/general',
    children: [
      {
        name: 'Manage Zoom Cafe',
        path: '/itu/general/manage-zoom-cafe'
      },
      { name: 'Manage Feature Access', path: '/itu/permissions/manageFeatureAccess' },
      { name: 'Manage Product Mapping', path: '/itu/general/manage-product-mapping' }
    ]
  },
  {
    name: 'Events',
    path: '/itu/events',
    icon: TimeIcon,
    children: [
      { name: 'Manage Categories', path: '/itu/events/manageCategory' },
      { name: 'Manage Events', path: '/itu/events/manageEvents' },
      { name: 'Manage Series', path: '/itu/events/manageSeries' }
    ]
  },
  {
    name: 'Send Password Reset Email',
    path: '/itu/send-password-reset-email',
    icon: FiSettings
  }
]

export default function SidebarWithHeader({ children }) {
  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.900')}
      position={'relative'}
      pl={{
        md: '300px',
        base: '0'
      }}
    >
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      <Box p="4">{children}</Box>
    </Box>
  )
}

const SidebarContent = ({ onClose, ...rest }) => {
  const [openNav, setOpenNav] = React.useState('')
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w="300px"
      position={'absolute'}
      left={0}
      top={0}
      h="full"
      {...rest}
      overflowY={'auto'}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          ITU Admin
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((linkItem) => {
        return <NavItem key={linkItem.name} item={linkItem} hasChildren={linkItem?.children} openNav={openNav} setOpenNav={setOpenNav} />
      })}
    </Box>
  )
}

const NavItem = ({ item, hasChildren }) => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const isActive =
    location.pathname.includes(item.path) || (item.children && item.children.some((el) => location.pathname.includes(el.path)))
  // Synchronize isOpen state with isActive
  useEffect(() => {
    setIsOpen(isActive)
  }, [isActive])

  const handleClick = () => {
    // if (!isActive)
    setIsOpen(!isOpen)
  }

  const newLocal = 'center'
  return (
    <Box>
      {hasChildren ? (
        <Flex
          align="center"
          onClick={handleClick}
          cursor="pointer"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          _hover={{
            bg: 'cyan.400',
            color: 'white'
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <Icon as={item.icon} />
            <Text ml="4">{item.name}</Text>
          </Flex>
          <Box>{item.children && <Icon as={FiChevronDown} />}</Box>
        </Flex>
      ) : (
        <Flex
          align="center"
          cursor="pointer"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          as={Link}
          to={item.path}
          _hover={{
            bg: 'cyan.400',
            color: 'white'
          }}
        >
          <Icon as={item.icon} />
          <Text ml="4">{item.name}</Text>
        </Flex>
      )}
      {item.children && (
        <Collapse in={isOpen}>
          <VStack align="start" pl="14" py={1}>
            {item.children.map((child) => (
              <Box
                key={child.name}
                w={'full'}
                py="2"
                px="4"
                borderRadius="lg"
                role="group"
                _hover={{
                  bg: 'cyan.400',
                  color: 'white'
                }}
                fontSize={12}
                bg={location.pathname === child.path ? 'cyan.400' : ''}
                as={Link}
                to={child.path}
              >
                {child.name}
              </Box>
            ))}
          </VStack>
        </Collapse>
      )}
    </Box>
  )
}
