import React from 'react'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import Users from './pages/Users'
import MembershipLevels from './pages/MembershipLevels'
import Courses from './pages/Courses'
import CreateLesson from './pages/CreateLesson'
import CreateCourse from './pages/CreateCourse'
import CreateGroupType from './pages/CreateGroupTypes'
import ManageTopics from './pages/ManageTopics'
import CreatePhase from './pages/CreatePhase'
import CreateGroup from './pages/CreateGroup'
import ManageBadges from './pages/ManageBadges'
import ManageQuicklinks from './pages/ManageQuicklinks'
import ManageHeroSection from './pages/ManageHeroSection'
import ManageForums from './pages/community/ManageForums'
import ManageChallenge from './pages/community/ManageChallenge'
import ManageITUVoice from './pages/community/ManageITUVoice'
import ManageProfileTypes from './pages/ManageProfileTypes'
import ManageJournalPrompts from './pages/journals-goals/ManageJournalPrompts'
import ManageCategory from './pages/event/ManageCategory'
import ManageSeries from './pages/event/ManageSeries'
import ManageEvents from './pages/event/ManageEvents'
import ManageGoalCategory from './pages/journals-goals/ManageGoalCategory'
import ManageRanks from './pages/ManageRanks'
import ManagePointsType from './pages/ManagePointsType'
import ManageAwardTriggers from './pages/ManageAwardTriggers'
import ManageLeaderboards from './pages/ManageLeaderboards'
import ManageFeatureAccess from './pages/Permissions/ManageFeatureAccess'
import ManageZoomCafe from './pages/General/ManageZoomCafe'
import ManageProductMapping from './pages/General/ManageProductMapping'
import ManageStudentMonth from './pages/ManageStudentMonth'
import ManageMessageNotifications from './pages/ManageMessageNotifications'
import ManuallyAwardPoints from './pages/gamification/ManuallyAwardPoints'
import ManageBanner from './pages/CreateBanner'
import SendPasswordResetEmail from './pages/SendPasswordResetEmail'
import { ManuallyAwardBadges } from './pages/gamification/ManuallyAwardBadges'
import { GamificationReports } from './pages/gamification/GamificationReports'
import ManageVideos from './pages/ManageVideos'
import ReportTrackPage from './pages/report/track'
import ReportVideoPage from './pages/report/video'
import OperationLog from './pages/report/OperationLog'

const router = createBrowserRouter([
  {
    path: '/itu/quicklinks',
    element: <ManageQuicklinks />
  },
  {
    path: '/itu/heroSection',
    element: <ManageHeroSection />
  },
  {
    path: '/itu/groups/forums',
    element: <ManageForums />
  },
  {
    path: '/itu/groups/challenge',
    element: <ManageChallenge />
  },
  {
    path: '/itu/groups/ituVoice',
    element: <ManageITUVoice />
  },
  {
    path: '/itu/users/levels',
    element: <MembershipLevels />
  },
  {
    path: '/itu/users/student-month',
    element: <ManageStudentMonth />
  },
  {
    path: '/itu/users/profileType',
    element: <ManageProfileTypes />
  },
  {
    path: '/itu/users',
    element: <Users />
  },
  {
    path: '/itu/courses',
    element: <Courses />
  },
  {
    path: '/itu/courses/createLesson',
    element: <CreateLesson />
  },
  {
    path: '/itu/courses/createCourse',
    element: <CreateCourse />
  },
  {
    path: '/itu/courses/manageTopics',
    element: <ManageTopics />
  },
  {
    path: '/itu/courses/createPhase',
    element: <CreatePhase />
  },
  {
    path: '/itu/groups',
    element: <CreateGroup />
  },
  {
    path: '/itu/groupTypes',
    element: <CreateGroupType />
  },
  {
    path: '/itu/journals-goals/manage-journal-prompts',
    element: <ManageJournalPrompts />
  },
  {
    path: '/itu/events/manageCategory',
    element: <ManageCategory />
  },
  {
    path: '/itu/events/manageSeries',
    element: <ManageSeries />
  },
  {
    path: '/itu/events/manageEvents',
    element: <ManageEvents />
  },
  {
    path: '/itu/manageRanks',
    element: <ManageRanks />
  },
  {
    path: '/itu/journals-goals/manageGoalCategory',
    element: <ManageGoalCategory />
  },
  {
    path: '/itu/manageBadges',
    element: <ManageBadges />
  },
  {
    path: '/itu/managePointTypes',
    element: <ManagePointsType />
  },
  {
    path: '/itu/manageAwardTriggers',
    element: <ManageAwardTriggers />
  },
  {
    path: '/itu/manuallyAwardPoints',
    element: <ManuallyAwardPoints />
  },
  {
    path: '/itu/gamificationReports',
    element: <GamificationReports />
  },
  {
    path: '/itu/manuallyAwardBadges',
    element: <ManuallyAwardBadges />
  },
  {
    path: '/itu/manageLeaderboards',
    element: <ManageLeaderboards />
  },
  {
    path: '/itu/permissions/ManageFeatureAccess',
    element: <ManageFeatureAccess />
  },
  {
    path: '/itu/notifications',
    element: <ManageMessageNotifications />
  },
  {
    path: '/itu/notificationBanner',
    element: <ManageBanner />
  },
  {
    path: '/itu/general/manage-zoom-cafe',
    element: <ManageZoomCafe />
  },
  {
    path: '/itu/general/manage-product-mapping',
    element: <ManageProductMapping />
  },
  {
    path: '/itu/send-password-reset-email',
    element: <SendPasswordResetEmail />
  },
  {
    path: '/itu/report/track',
    element: <ReportTrackPage />
  },
  {
    path: '/itu/report/manage-videos',
    element: <ManageVideos />
  },
  {
    path: '/itu/report/operation-log',
    element: <OperationLog />
  },
  {
    path: '/itu/report/video',
    element: <ReportVideoPage />
  }
])

export default router
