import React, { useState, useEffect, useMemo } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  FormControl,
  Switch,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  Box,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MultiSelect from 'react-select'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../../components/ITUButton'
import Pagination from '../../components/Pagination'

function ManageProductMapping() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    current: 1,
    size: 50
  })
  const [pageSize, setPageSize] = useState(50)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [membershipLevels, setMembershipLevels] = useState([])
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (loggedIn) {
      getTableData(params)
    }
  }, [loggedIn, params.current, params.size])

  const getMembershipLevels = () => {
    api
      .fetchMemberships({
        current: 1,
        size: 1000
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setMembershipLevels(res.data.data.records)
          }
        }
      })
  }

  const [membershipLevel, setMembershipLevel] = useState(null)
  const [currentItem, setCurrentItem] = useState({
    id: '',
    pksProductId: '',
    membershipId: '',
    retentionEnabled: false
  })
  const onEdit = async (item) => {
    setMembershipLevel({
      label: item.memberLevelName,
      value: item.membershipId
    })
    setCurrentItem({
      id: item.id,
      retentionEnabled: item?.retentionEnabled,
      pksProductId: item.pksProductId,
      membershipId: item.membershipId
    })
    setIsModalOpen(true)
  }
  const columnDefs = [
    {
      field: 'pksProductId',
      headerName: 'PKS Product ID',
      flex: '1',
      // sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'memberLevelName',
      headerName: 'Membership level',
      flex: '1',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'retentionEnabled',
      headerName: 'Rentention',
      cellRenderer: (params) => {
        return params.value ? 'true' : 'false'
      },
      flex: '1'
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '1'
    },
    {
      field: 'updatedAt',
      headerName: 'Updated Date',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '1'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.data)} icon={<EditIcon />} />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setDeleteId(params.data.id)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
    getMembershipLevels()
  }, [])

  const handleEdit = async () => {
    if (!currentItem.pksProductId) {
      toast({
        title: 'Error',
        description: 'Please enter PKS Product ID',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    if (!membershipLevel) {
      toast({
        title: 'Error',
        description: 'Please select Membership Level',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    setIsLoading(true)

    const res = await api
      .updateProductMapping({
        ...currentItem,
        membershipId: membershipLevel ? membershipLevel.value : ''
      })
      .catch((error) => {
        setIsModalOpen(true)
        setIsLoading(false)
        toast({
          title: 'Error',
          description: error.response.data.msg,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
    if (res.data.code === 200) {
      toast({
        title: 'Success',
        description: 'Product update successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData({
        current: 1,
        size: 50
      })
      onCancel()
    } else {
      toast({
        title: 'Error',
        description: res.data.msg,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleAddItem = async () => {
    if (!currentItem.pksProductId) {
      toast({
        title: 'Error',
        description: 'Please enter PKS Product ID',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    if (!membershipLevel) {
      toast({
        title: 'Error',
        description: 'Please select Member Ship Level',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    setIsLoading(true)

    const res = await api
      .createProductMapping({
        ...currentItem,
        membershipId: membershipLevel ? membershipLevel.value : ''
      })
      .catch((error) => {
        setIsModalOpen(true)
        setIsLoading(false)
        toast({
          title: 'Error',
          description: error.response.data.msg,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
    if (res.data.code === 200) {
      toast({
        title: 'Success',
        description: 'Product added successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData({
        current: 1,
        size: 50
      })
      onCancel()
    } else {
      toast({
        title: 'Error',
        description: res.data.msg,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const onCancel = () => {
    setIsModalOpen(false)
    setCurrentItem({
      id: '',
      pksProductId: '',
      membershipId: '',
      retentionEnabled: false
    })
    setMembershipLevel(null)
    //
  }

  const getTableData = (params) => {
    setIsFetching(true)
    api.fetchProductList(params).then((res) => {
      if (res.data.code === 200 && res.data.data) {
        setIsFetching(false)
        setRowData(res.data.data.records)
        setTotal(res.data.data.total)
        setPages(res.data.data.pages)
      }
    })
  }

  return (
    <Layout>
      <Flex marginBottom="2rem" justifyContent="space-between" direction="column">
        <Heading>Manage Product Mappings</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            Add Product
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        {isFetching ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
            <Spinner size="xl" marginBottom="1rem" />
            <Text>Loading data...</Text>
          </Flex>
        ) : (
          <>
            <AgGridReact
              style={{ width: '100%', height: '100%' }}
              rowData={rowData}
              columnDefs={columnDefs}
              // onSortChanged={(event) => console.log(event)}
              multiSortKey={'ctrl'}
              // pagination={true}
              // paginationPageSize={'50'}
            ></AgGridReact>
            <Pagination
              loading={isFetching}
              current={params.current}
              size={pageSize}
              total={total}
              onChange={(current, size) => {
                setPageSize(size)
                setParams((_params) => {
                  return {
                    ..._params,
                    size: size,
                    current: current
                  }
                })
              }}
            />
          </>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentItem.id ? 'Edit' : 'Create'} Product Details</ModalHeader>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>PKS Product ID</FormLabel>
              <Input
                value={currentItem.pksProductId}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    pksProductId: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl id="membershipLevel">
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                name="membership"
                onChange={async (event) => {
                  setMembershipLevel(event)
                }}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl marginTop={'24px'}>
              <FormLabel>Rentention Enabled</FormLabel>
              <Switch
                isChecked={currentItem.retentionEnabled}
                onChange={() =>
                  setCurrentItem((_currentItem) => {
                    return { ..._currentItem, retentionEnabled: !_currentItem.retentionEnabled }
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onCancel} colorScheme="cyan" variant="outline">
                Cancel
              </Button>
              <Button
                colorScheme="cyan"
                onClick={() => {
                  if (currentItem.id) {
                    handleEdit()
                  } else {
                    handleAddItem()
                  }
                }}
                disabled={isLoading}
              >
                {isLoading ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .delateProductMapping(deleteId)
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Product saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                        setDeleteId('')
                        getTableData({
                          current: 1,
                          size: 50
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Failed to delete Product.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete Product.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageProductMapping
