import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import useAutoTable from '../components/useAutoTable'
import { Select, Modal as ArcoModal } from '@chakra-ui/react'
import CustomSelect from '../components/CustomSelect'

import ITUButton from '../components/ITUButton'

const timePeriodType = [
  {
    label: 'None',
    value: 'None'
  },
  {
    label: 'Today',
    value: 'Today'
  },
  {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    label: 'Past Week',
    value: 'PastWeek'
  },
  {
    label: 'Past Month',
    value: 'PastMonth'
  },
  {
    label: 'Past Year',
    value: 'PastYear'
  },
  {
    label: 'Current Week',
    value: 'CurrentWeek'
  },
  {
    label: 'Current Month',
    value: 'CurrentMonth'
  },
  {
    label: 'Current Year',
    value: 'CurrentYear'
  }
]
export default function ManageLeaderboards() {
  const toast = useToast()
  const [deleteId, setDeleteId] = useState(false)
  const [editConfig, setEditConfig] = useState({
    open: false,
    form: {}
  })
  const setFormData = (params) => {
    setEditConfig({
      ...editConfig,
      form: {
        ...editConfig.form,
        ...params
      }
    })
  }
  const [isLoading, setIsLoading] = useState(false)
  const [awardTypes, setAwardTypes] = useState([])

  const fetchPointsTypeData = () => {
    api
      .fetchPointsType({
        current: 1,
        size: 100
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setAwardTypes(res.data.data.map(({ id, name }) => ({ label: name, value: id })))
          }
        }
      })
  }

  useEffect(() => {
    fetchPointsTypeData()
  }, [])

  const [Table, refreshTable] = useAutoTable({
    fetch: async (params) => {
      const result = {
        list: [],
        total: 0
      }
      try {
        const res = await api.getLeaderboard(params)
        if (res.data.code === 200) {
          result.list = res.data.data.records
          result.total = res.data.data.total
        }
      } catch (error) {
        // error
      }
      return result
    }
  })

  const handleCloseModal = () => {
    setEditConfig({
      open: false,
      form: {
        id: null,
        description: '',
        metricsPointsTypeId: null,
        name: '',
        numberUserRank: null,
        timePeriod: ''
      }
    })
  }

  const handleDelete = async () => {
    const res = await api.deleteLeaderboard(deleteId)
    if (res.data.code === 200) {
      setDeleteId('')
      await api.getLeaderboard({ current: 1, size: 50 })
      toast({
        title: 'Success',
        description: 'LeaderBoard Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete prompt.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }
  const handleSave = async () => {
    const form = editConfig.form
    if (!form.timePeriod || !form.metricsPointsTypeId || !form.name || !form.numberUserRank) {
      return toast({
        title: 'Error',
        status: 'info',
        description: 'Required fields cannot be empty.'
      })
    }
    setIsLoading(true)
    const { data } = await (editConfig.form.id ? api.updateLeaderboard(editConfig.form) : api.creatLeaderboard(editConfig.form))
    const success = data.code === 200
    setIsLoading(false)
    toast({
      title: success ? 'Success' : 'Error',
      description: success
        ? 'Leaderboard saved successfully.'
        : 'Oops. There was an error saving the leaderboard. Please contact tech support. Error: ' + data.msg,
      status: success ? 'success' : 'error',
      duration: 5000,
      isClosable: true
    })
    if (success) {
      handleCloseModal()
      refreshTable()
    }
  }

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Leaderboards</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setEditConfig({
                open: true,
                form: {}
              })
            }}
          >
            Add Leaderboard
          </ITUButton>
        </Flex>
      </Flex>

      <Table
        columnDefs={[
          { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
          {
            field: 'metricsPointsTypeId',
            headerName: 'Metrics',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
              const item = awardTypes.find(({ value }) => value === params.value) || {}
              return item.label || params.value
            }
          },
          {
            field: 'description',
            headerName: 'description',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'pointsType',
            headerName: 'pointsType',
            flex: '1',
            sortable: true,
            headerTooltip: 'Search',
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
              return params.value.name
            }
          },
          {
            headerName: 'Actions',
            cellRenderer: (params) => {
              return (
                <Flex gap={4}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      const { id, ...other } = params.data
                      setEditConfig({
                        open: true,
                        form: { ...params.data }
                      })
                    }}
                    icon={<EditIcon />}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setDeleteId(params.data.id)
                    }}
                    icon={<DeleteIcon />}
                  />
                </Flex>
              )
            }
          }
        ]}
      />
      <Modal isOpen={editConfig.open} oncancel={handleCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Leaderboard</ModalHeader>
          <ModalBody>
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input type="text" value={editConfig.form.name} onChange={(e) => setFormData({ name: e.target.value })} />
            </FormControl>
            <FormControl id="description" mt="4">
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Add description..."
                value={editConfig.form.description}
                onChange={(e) => setFormData({ description: e.target.value })}
              />
            </FormControl>
            <FormControl id="numberUserRank" isRequired mt="4">
              <FormLabel>Numbers of users to rank</FormLabel>
              <Input
                type="number"
                value={editConfig.form.numberUserRank}
                onChange={(e) => setFormData({ numberUserRank: e.target.value })}
              />
            </FormControl>

            <FormControl id="metricsPointsTypeId" isRequired mt="4">
              <FormLabel>Metrics to track</FormLabel>
              <CustomSelect
                size="large"
                value={editConfig.form.metricsPointsTypeId}
                onChange={(metricsPointsTypeId) => setFormData({ metricsPointsTypeId })}
                options={awardTypes}
              />
            </FormControl>

            <FormControl id="timePeriod" isRequired mt="4">
              <FormLabel>Time Period</FormLabel>
              <CustomSelect
                size="large"
                value={editConfig.form.timePeriod}
                onChange={(timePeriod) => setFormData({ timePeriod })}
                options={timePeriodType}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteId} onClose={() => setDeleteId('')}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteId('')}>Cancel</Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}
