import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { Card, Text, FormControl, useToast, FormLabel, Select, Checkbox, Box, Button, Spinner } from '@chakra-ui/react'
import { api } from '../../services/api'
import SearchableSelect from './SearchableSelect'

export const ManuallyAwardBadges = () => {
  const [awardBadges, setAwardBadges] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBadge, setSelectedBadge] = useState('')
  const [userIds, setUserIds] = useState([])
  const [isAll, setIsAll] = useState(false)
  const toast = useToast()

  const getBadges = async () => {
    const { data } = await api.fetchBadges({ size: 100 })
    if (data?.data?.records) {
      setAwardBadges(data.data.records)
    }
  }

  const handleAward = async () => {
    if (!selectedBadge) {
      return toast({
        status: 'error',
        description: 'Please select badge'
      })
    }

    if (!isAll && !userIds.length) {
      return toast({
        status: 'error',
        description: 'Please select users'
      })
    }

    setIsLoading(true)
    try {
      if (selectedBadge && (isAll || userIds?.length)) {
        const { data } = await api.awardBadge({
          badgeId: selectedBadge,
          everyOne: isAll,
          userIds: userIds
        })
        const success = data.code === 200
        toast({
          title: success ? 'Success' : 'Error',
          description: success ? 'Award Save successfully.' : data.msg,
          status: success ? 'success' : 'error',
          duration: 5000,
          isClosable: true
        })
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(async () => {
    setIsLoading(true)
    try {
      await getBadges({})
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }, [])

  return (
    <Layout>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 160px)', width: '100%' }}>
        <Card padding={'48px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'}>
            Manually Award Badges
          </Text>
          <FormControl marginTop={'24px'} width={'400px'}>
            <FormLabel>What type of badge?</FormLabel>
            <Select
              value={selectedBadge}
              onChange={(e) => {
                setSelectedBadge(e.target.value)
              }}
              placeholder="Select a badge ..."
            >
              {awardBadges?.map((badge) => {
                return (
                  <option value={badge.id} key={badge.id}>
                    {badge.name}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl marginTop={'24px'} width={'400px'}>
            <Checkbox isChecked={isAll} onChange={(e) => setIsAll(e.target.checked)}>
              Award to all users
            </Checkbox>
          </FormControl>
          {!isAll && (
            <FormControl marginTop={'24px'} width={'400px'}>
              <FormLabel>Users to receive badge</FormLabel>
              <SearchableSelect
                onChange={(list) => {
                  setUserIds(list.map(({ value }) => value))
                }}
              />
            </FormControl>
          )}
          <Box textAlign={'end'}>
            <Button colorScheme="blue" mt="20px" width={'200px'} onClick={handleAward}>
              {isLoading ? <Spinner size="sm" /> : 'Award Badges'}
            </Button>
          </Box>
        </Card>
      </div>
    </Layout>
  )
}
