import React, { useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  useToast,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Spinner,
  Input,
  Select,
  Switch
} from '@chakra-ui/react'
import AsyncSelect from 'react-select/async'
import RichTextEditor from '../../components/RichTextEditor/index'
import ITUButton from '../../components/ITUButton'
import { api } from '../../services/api'
import dayjs from 'dayjs'

const UploadPointsKey = 'gamipress_ld_assignment_upload_specific_challenge_task'
const PostPointsKey = 'gamipress_bbp_specific_new_topic'
const ReplyPointskey = 'gamipress_bbp_specific_new_reply'

const pointEvents = [
  {
    name: 'Upload Points',
    key: UploadPointsKey
  },
  {
    name: 'Post Points',
    key: PostPointsKey
  },
  {
    name: 'Reply Points',
    key: ReplyPointskey
  }
]

const defaultChallengeTaskPointConfig = {
  [UploadPointsKey]: {
    pointsCount: 1,
    status: 'pending', // publish
    targetDetailId: null,
    maxEarningTimes: 1
  },
  [PostPointsKey]: {
    pointsCount: 1,
    status: 'pending', // publish
    targetDetailId: null,
    maxEarningTimes: 1
  },
  [ReplyPointskey]: {
    pointsCount: 1,
    status: 'pending', // publish
    targetDetailId: null,
    maxEarningTimes: 1
  }
}

function NewTask({ isOpen, handleCloseModal, awardTypes, tasks, editTask }) {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [createData, setCreateData] = useState({
    id: '',
    content: '',
    description: '',
    type: '',
    name: '',
    required: false,
    pointsCount: 0,
    taskRequiredId: 0,
    pointsTypeId: 0,
    endDate: '',
    startDate: ''
  })

  useEffect(() => {
    fetchChallengeTaskPointConfig()
    if (editTask) {
      setCreateData({
        ...editTask,
        startDate: dayjs(editTask.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(editTask.endDate).format('YYYY-MM-DD')
      })
    } else {
      setCreateData({
        id: '',
        content: '',
        description: '',
        type: '',
        name: '',
        required: false,
        pointsCount: 0,
        taskRequiredId: 0,
        pointsTypeId: 0,
        endDate: '',
        startDate: ''
      })
    }
  }, [editTask])

  const [challengeTaskPointConfig, setChallengeTaskPointConfig] = useState(defaultChallengeTaskPointConfig)

  const fetchChallengeTaskPointConfig = async () => {
    const defaultData = { ...defaultChallengeTaskPointConfig }
    const events = Object.keys(defaultData)

    if (editTask) {
      const {
        data: { data, code }
      } = await api.getChallengeTaskPointConfig(editTask.id)
      if (code === 200 && data) {
        data.map((item) => {
          if (events.includes(item.triggerType)) {
            defaultData[item.triggerType] = { ...item }
          }
        })
      }
    }
    setChallengeTaskPointConfig({ ...defaultData })
  }

  const updateChallengeTask = async (configDTOList) => {
    await api.challengeAwardUpdateAwardConfigComplex(configDTOList)
    return await api.updateChallengeTask(createData)
  }

  const handleSave = () => {
    const configDTOList = pointEvents
      .map(({ key }) => ({
        ...challengeTaskPointConfig[key],
        triggerType: key,
        taskId: editTask?.id,
        targetDetailId: key === UploadPointsKey ? editTask?.id : challengeTaskPointConfig[key]?.targetDetailId
      }))
      .filter(({ targetDetailId }) => targetDetailId)

    ;(editTask
      ? updateChallengeTask(configDTOList)
      : api.createChallengeTask({
          ...createData,
          configDTOList
        })
    )
      .then((res) => {
        if (res.status === 200 && res.data) {
          toast({
            title: 'Success',
            description: 'Task saved successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          handleCloseModal('refresh')
        } else {
          toast({
            title: 'Error',
            description: 'Failed to save Task.',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        }
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Failed to save Task.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      })
  }

  useEffect(() => {
    api.discussionList()
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Task</ModalHeader>
        <ModalBody>
          <Box>
            <FormControl marginBottom={'24px'} id="prompt">
              <FormLabel width={'100px'}>Type</FormLabel>
              <Select
                placeholder="Select Type"
                value={createData.type}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    type: e.target.value
                  })
                }
              >
                <option value="Classic">Classic</option>
                <option value="Form Only">Form Only</option>
              </Select>
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                Start Date
              </FormLabel>
              <Input
                type="date"
                name="startDate"
                value={createData.startDate}
                onChange={() => {
                  setCreateData({
                    ...createData,
                    startDate: event.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                End Date
              </FormLabel>
              <Input
                type="date"
                name="endDate"
                value={createData.endDate}
                onChange={(event) => {
                  setCreateData({
                    ...createData,
                    endDate: event.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl marginBottom={'24px'} id="prompt">
              <FormLabel width={'100px'}>Prerequisite</FormLabel>
              <Select
                placeholder="Select Prerequisite Task"
                value={createData.taskRequiredId}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    taskRequiredId: e.target.value
                  })
                }
              >
                {tasks.map((el) => (
                  <option key={`task-item-option-${el.label}`} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                Required
              </FormLabel>
              <Switch
                isChecked={createData.required}
                onChange={() => {
                  setCreateData({
                    ...createData,
                    required: !createData.required
                  })
                }}
                id="email-alerts"
              />
            </FormControl>

            {pointEvents.map(({ key, name }) => {
              const itemData = challengeTaskPointConfig[key]
              return (
                <div key={key}>
                  <FormControl display="flex" marginBottom={'24px'} size={'sm'} id={key}>
                    <FormLabel width={'120px'}>{name}</FormLabel>
                    <div>
                      <FormControl display="flex" marginBottom={'12px'}>
                        {key === PostPointsKey ? (
                          <>
                            <ForumsSelect
                              value={itemData.targetDetailId}
                              onChange={({ value }) => {
                                challengeTaskPointConfig[key].targetDetailId = value
                                challengeTaskPointConfig[ReplyPointskey].targetDetailId = null
                                setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                              }}
                            />
                          </>
                        ) : key === ReplyPointskey ? (
                          <>
                            <DiscussionSelect
                              value={itemData.targetDetailId}
                              forumId={challengeTaskPointConfig[PostPointsKey].targetDetailId}
                              onChange={({ value }) => {
                                challengeTaskPointConfig[key].targetDetailId = value
                                setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                              }}
                            />
                          </>
                        ) : null}
                      </FormControl>
                      <FormControl display="flex" marginBottom={'12px'} alignItems="center" size={'sm'}>
                        <FormLabel width={'100px'}>Title</FormLabel>
                        <Input
                          value={itemData.title}
                          placeholder="Enter Title"
                          onChange={(e) => {
                            challengeTaskPointConfig[key].title = e.target.value
                            setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                          }}
                        />
                      </FormControl>

                      <FormControl display="flex" marginBottom={'12px'} alignItems="center" size={'sm'}>
                        <FormLabel width={'100px'}>Points</FormLabel>
                        <Input
                          min={1}
                          value={itemData.pointsCount}
                          type="number"
                          placeholder="Enter Points"
                          onChange={(e) => {
                            challengeTaskPointConfig[key].pointsCount = +(e.target.value || 0)
                            setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                          }}
                        />
                      </FormControl>
                      <FormControl display="flex" marginBottom={'12px'} alignItems="center" size={'sm'}>
                        <FormLabel width={'100px'}>Count</FormLabel>
                        <Input
                          min={1}
                          value={itemData.maxEarningTimes}
                          type="number"
                          placeholder="Enter Points"
                          onChange={(e) => {
                            challengeTaskPointConfig[key].maxEarningTimes = e.target.value
                            setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                          }}
                        />
                      </FormControl>
                      <FormControl display="flex" marginBottom={'12px'} alignItems="center">
                        <FormLabel width={'100px'} mb="0">
                          Status
                        </FormLabel>
                        <Select
                          value={itemData.status || 'pending'}
                          onChange={(e) => {
                            challengeTaskPointConfig[key].status = e.target.value
                            setChallengeTaskPointConfig({ ...challengeTaskPointConfig })
                          }}
                        >
                          <option value={'pending'}>OFF</option>
                          <option value={'publish'}>ON</option>
                        </Select>
                      </FormControl>
                    </div>
                  </FormControl>
                </div>
              )
            })}
            <FormControl marginBottom={'24px'} id="title">
              <FormLabel width={'100px'}>Name</FormLabel>
              <Input
                placeholder="Enter Name"
                value={createData.name}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    name: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl marginBottom={'24px'} id="prompt">
              <FormLabel width={'100px'}>Description</FormLabel>
              <RichTextEditor
                placeholder="Enter Description"
                value={createData.description}
                onChange={(description) => {
                  setCreateData({
                    ...createData,
                    description: description
                  })
                }}
              />
            </FormControl>
            <FormControl marginBottom={'24px'}>
              <FormLabel width={'100px'}>Content</FormLabel>
              <RichTextEditor
                placeholder="Enter Content"
                value={createData.content}
                onChange={(content) =>
                  setCreateData({
                    ...createData,
                    content
                  })
                }
              />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
            Cancel
          </ITUButton>
          <ITUButton onClick={handleSave} disabled={isLoading}>
            {isLoading ? <Spinner size="sm" /> : 'Save'}
          </ITUButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const DiscussionSelect = ({ value, onChange, forumId }) => {
  const [optionList, setOptionList] = useState([])
  const promiseOptions = async (discussionName) => {
    const { data } = await api.discussionList({
      discussionName,
      size: 100,
      current: 1,
      forumId
    })

    const list = data.data.records.map(({ id, discussionName }) => ({ value: id, label: discussionName }))

    setOptionList(list)
    return list
  }

  const [defaultOptions, setDefaultOptions] = useState([])
  useEffect(() => {
    if (value) {
      api
        .discussionList({
          size: 1,
          current: 1,
          discussionId: value,
          forumId
        })
        .then(({ data }) => {
          setDefaultOptions(data.data.records.map(({ id, discussionName }) => ({ value: id, label: discussionName })))
        })
    }
  }, [value, forumId])

  const selectValue = useMemo(() => {
    return [...defaultOptions, optionList].find((item) => item.value === value)
  }, [value, defaultOptions, optionList, forumId])

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <AsyncSelect
        defaultValue={selectValue}
        value={selectValue}
        cacheOptions
        onChange={onChange}
        defaultOptions={defaultOptions}
        loadOptions={promiseOptions}
        placeholder="Please Search to select"
      />
    </div>
  )
}

const ForumsSelect = ({ value, onChange }) => {
  const [optionList, setOptionList] = useState([])
  const promiseOptions = async (forumName) => {
    const { data } = await api.fetchForumsList({
      forumName,
      size: 100,
      current: 1
    })
    const list = data.data.records.map(({ id, forumName }) => ({ value: id, label: forumName }))

    setOptionList(list)
    return list
  }

  const [defaultOptions, setDefaultOptions] = useState([])

  const getDefaultOptions = async () => {
    const { data } = await api.fetchForumsList({
      size: 1,
      current: 1,
      forumId: value
    })

    setDefaultOptions(data.data.records.map(({ id, forumName }) => ({ value: id, label: forumName })))
  }
  useEffect(() => {
    if (value) {
      getDefaultOptions()
    }
  }, [value])

  const selectValue = useMemo(() => {
    return [...defaultOptions, optionList].find((item) => item.value === value)
  }, [value, defaultOptions, optionList])

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <AsyncSelect
        defaultValue={selectValue}
        value={selectValue}
        cacheOptions
        onChange={onChange}
        loadOptions={promiseOptions}
        defaultOptions={defaultOptions}
        placeholder="Please Search to select"
      />
    </div>
  )
}

export default NewTask
