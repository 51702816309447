import React, { useState, useEffect } from 'react'
import { Flex, Box, Heading } from '@chakra-ui/react'
import SidebarWithHeader from './SidebarWithHeader'
import { userHasAccess } from '@impact-theory/app-auth'

const Layout = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
      setLoading(false)
    }
    run()
  }, [])

  if (loading) {
    return (
      <Heading marginBottom="2rem" textAlign={'center'}>
        Loading
      </Heading>
    )
  }

  if (!loggedIn) return <Heading marginBottom="2rem">No Permission</Heading>

  return (
    <>
      <Flex width="100%" alignItems="center" flexDirection="column">
        <Box width="100%">{loggedIn && <SidebarWithHeader>{loading ? 'loading' : children}</SidebarWithHeader>}</Box>
      </Flex>
    </>
  )
}

export default Layout
