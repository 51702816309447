import React, { useState, useEffect, useMemo } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  Box,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MultiSelect from 'react-select'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../components/ITUButton'

const initialPhase = {
  id: '',
  lessonIds: [
    {
      id: '',
      topicIds: []
    }
  ]
}

function CreateCourse() {
  const [params, setParams] = useState({
    current: 1,
    size: 50
  })
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [pages, setPages] = useState(0)
  const [loggedIn, setLoggedIn] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [rowData, setRowData] = useState([])
  const [lessons, setLessons] = useState([])
  const [phases, setPhases] = useState([])
  const [topics, setTopics] = useState([])
  const [isTopic, setIsTopic] = useState(false)
  const [membershipLevels, setMembershipLevels] = useState([])
  const [instructors, setInstructors] = useState([])
  const [topicsObject, setTopicsObject] = useState({})

  const [title, setTitle] = useState('')
  const [type, setType] = useState('')
  const [bannerImageUrl, setBannerImageUrl] = useState('')
  const [trackImageUrl, setTrackImageUrl] = useState('')
  const [previewImageUrl, setPreviewImageUrl] = useState('')
  const [description, setDescription] = useState('')
  const [externalLink, setExternalLink] = useState('')
  const [membershipLevel, setMembershipLevel] = useState([])
  const [instructor, setInstructor] = useState('')
  const [selectPhases, setSelectPhases] = useState([{ ...initialPhase }])
  const [showInPreview, setShowInPreview] = useState(false)
  const [isBonus, setIsBonus] = useState(false)

  const toast = useToast()

  const getCourses = () => {
    if (loggedIn) {
      api
        .fetchCourses({
          current: params.current,
          size: 100000
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setRowData(res.data.data.records)
            setPages(res.data.data.pages)
          }
        })
    }
  }

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  const handleCloseModal = () => {
    setShowInPreview(false)
    setIsBonus(false)
    setBannerImageUrl('')
    setDescription('')
    setExternalLink('')
    setInstructor('')
    setTrackImageUrl('')
    setPreviewImageUrl('')
    setTitle('')
    setSelectPhases([{ ...initialPhase }])
    setType('')
    setMembershipLevel([])
    setIsOpen(false)
  }

  useEffect(() => {
    getCourses()
  }, [loggedIn, params.size, params.current])

  useEffect(() => {
    if (loggedIn) {
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
      api
        .fetchPhase({
          current: 1,
          size: 9999999
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setPhases(res.data.data.records)
          }
        })
      api
        .fetchTopics({
          current: 1,
          size: 9999999
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setTopicsObject(
              [...res.data.data.records].reduce((acc, topic) => {
                return {
                  ...acc,
                  [topic.id]: topic.title
                }
              }, {})
            )

            setTopics(res.data.data.records)
          }
        })
      api
        .fetchInstructor({
          current: 1,
          size: 9999999,
          profileTypeId: 5
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setInstructors(res.data.data.records)
          }
        })
      api
        .fetchLessons({
          current: 1,
          size: 9999999
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setLessons(res.data.data.records)
          }
        })
    }
  }, [loggedIn])

  const handleSave = () => {
    let isTopic = selectPhases.every((el) => el.id === '' && el.lessonIds.every((lel) => lel.id === '' && lel.topicIds.length > 0))
    let phases = selectPhases
    if (isTopic) {
      let topicIds = []
      phases.forEach((el) => {
        el.lessonIds.forEach((lel) => {
          topicIds = Array.from(new Set([...topicIds, ...lel.topicIds]))
        })
      })
      phases = topicIds.map((el) => {
        return {
          id: el,
          isTopic: true
        }
      })
    }
    setIsLoading(true)
    if (item) {
      // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
      api
        .updateCourses({
          id: item.id,
          title,
          trackImageUrl,
          type,
          previewImageUrl,
          bannerImageUrl,
          content: description,
          description,
          externalLink,
          membershipLevelIds: membershipLevel.map((el) => el.value),
          relations: phases && JSON.stringify(phases) !== 'null' ? JSON.stringify(phases) : null,
          instructorId: instructor,
          showInPreviews: showInPreview,
          isBonus: isBonus
        })
        .then((response) => {
          // Handle the response

          getCourses()
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Course saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error saving the course. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    } else {
      // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
      api
        .createCourses({
          title,
          trackImageUrl,
          bannerImageUrl,
          content: description,
          description,
          type,
          membershipLevelIds: membershipLevel.map((el) => el.value),
          phaseIds: phases,
          instructorId: instructor,
          externalLink,
          showInPreviews: showInPreview,
          isBonus: isBonus
        })
        .then((response) => {
          // Handle the response
          getCourses()
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Course saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error saving the course. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    }
  }

  const [columnDefs] = useState([
    {
      field: 'title',
      headerName: 'Title',
      flex: '3',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      rowDrag: true
    },
    // { field: "content", headerName: "Content", flex: "1" },
    { field: 'description', headerName: 'Description', flex: '3.5' },
    {
      field: 'showInPreviews',
      headerName: 'Show Preview',
      width: '130px',
      cellRenderer: (params) => {
        return <span>{params.value ? 'Yes' : 'No'}</span>
      }
    },
    {
      field: 'isBonus',
      headerName: 'Is Bonus',
      width: '100px',
      cellRenderer: (params) => {
        return <span>{params.value ? 'Yes' : 'No'}</span>
      }
    },
    {
      field: 'membershipLevels',
      headerName: 'MembershipLevels',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => (params.value ? params.value.map((el) => el.levelName).toString() : ''),
      flex: '2'
    },
    { field: 'status', headerName: 'Status', width: '90px' },
    {
      headerName: 'Actions',
      width: '220px',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              size={'sm'}
              onClick={() => {
                setTitle(params.data.title)
                setType(params.data.type)
                setDescription(params.data.content)
                setExternalLink(params.data.externalLink)
                let relations = JSON.parse(params.data.relations)
                let flag = relations.some((el) => el.isTopic)
                setIsTopic(flag)
                setSelectPhases(
                  flag
                    ? relations.map((el) => {
                        return {
                          id: '',
                          lessonIds: [
                            {
                              id: '',
                              topicIds: [el.id]
                            }
                          ]
                        }
                      })
                    : relations
                )
                setItem(params.data)
                setBannerImageUrl(params.data.bannerImageUrl)
                setTrackImageUrl(params.data.trackImageUrl)
                setPreviewImageUrl(params.data.previewImageUrl)
                if (params.data.membershipLevels && params.data.membershipLevels.length > 0) {
                  setMembershipLevel(
                    params.data.membershipLevels.map((el) => {
                      return {
                        value: el.id,
                        label: el.levelName
                      }
                    })
                  )
                }
                setInstructor(params.data.instructorId)
                setIsOpen(true)
                setShowInPreview(params.data.showInPreviews)
                setIsBonus(params.data.isBonus)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
              size={'sm'}
            />
            <ChangeStatus
              onRefresh={(data) => {
                setRowData((rowData) => {
                  return rowData.map((item) => {
                    if (item.id === data.id) {
                      return data
                    }
                    return item
                  })
                })
              }}
              item={params.data}
            />
          </Flex>
        )
      }
    }
  ])

  const onAddLesson = (phaseIndex) => {
    setSelectPhases((phases) => [
      ...phases.map((phase, index) =>
        index === phaseIndex ? { ...phase, lessonIds: [...phase.lessonIds, { id: '', topicIds: [] }] } : phase
      )
    ])
  }

  const onChangePhase = (phaseIndex, value) => {
    setSelectPhases((phases) => [...phases.map((item, index) => (index === phaseIndex ? { ...item, id: value } : item))])
  }

  const onChangeLesson = (phaseIndex, lessonIndex, value) => {
    setSelectPhases((phases) => [
      ...phases.map((phase, index) =>
        index === phaseIndex
          ? {
              ...phase,
              lessonIds: [...phase.lessonIds.map((lesson, index2) => (index2 === lessonIndex ? { ...lesson, id: value } : lesson))]
            }
          : phase
      )
    ])
  }

  const onChangeTopic = (phaseIndex, lessonIndex, topics) => {
    setSelectPhases((phases) => [
      ...phases.map((phase, index) =>
        index === phaseIndex
          ? {
              ...phase,
              lessonIds: [
                ...phase.lessonIds.map((lesson, index2) =>
                  index2 === lessonIndex ? { ...lesson, topicIds: topics.map((item) => item.value) } : lesson
                )
              ]
            }
          : phase
      )
    ])
  }

  const onRowDragEnd = async ({ overIndex, overNode }) => {
    const startIndex = rowData.findIndex(({ id }) => id === overNode.data.id)
    const newTableData = [...rowData]
    const tmpData = newTableData[startIndex]
    if (startIndex > overIndex) {
      //Move from front to back
      newTableData.splice(startIndex, 1)
      newTableData.splice(overIndex, 0, tmpData)
    } else {
      // Move from back to front
      newTableData.splice(overIndex, 0, tmpData)
      newTableData.splice(startIndex, 1)
    }

    const res = await api.updateCourseOrderNumber({
      courseIds: newTableData.map(({ id }) => id)
    })

    if (res?.data?.code === 200) {
      setRowData(newTableData)
      toast({
        title: 'Success',
        description: 'Successful sorting',
        status: 'success'
      })
    } else {
      toast({
        title: 'Error',
        description: 'Failed to sort',
        status: 'error'
      })
    }
  }

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Courses</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsTopic(false)
              setItem(null)
              setIsOpen(true)
            }}
          >
            Add Course
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 282px)', width: '100%' }} key={rowData}>
        <AgGridReact
          rowDragManaged
          rowData={rowData}
          columnDefs={columnDefs}
          style={{ width: '100%', height: '100%' }}
          onRowDragEnd={onRowDragEnd}
        />
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Course</ModalHeader>
          <ModalBody>
            <FormControl id="Title">
              <FormLabel>Title</FormLabel>
              <Input placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>

            <FormControl id="bannerImageUrl" mt="5">
              <FormLabel>Banner Image</FormLabel>
              {bannerImageUrl && <img h="400" src={bannerImageUrl} />}
              <ITUButton variant="outline" size="xs" as="label" for="bannerImageUrlInput">
                {bannerImageUrl ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                id="bannerImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setBannerImageUrl(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>

            <FormControl id="trackImageUrl" mt="5">
              <FormLabel>Track Image</FormLabel>
              {trackImageUrl && <img h="400" src={trackImageUrl} />}

              <ITUButton variant="outline" size="xs" as="label" for="trackImageUrlInput">
                {trackImageUrl ? 'Change' : 'Upload'}
              </ITUButton>

              <Input
                hidden
                id="trackImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setTrackImageUrl(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>

            <FormControl id="previewImageUrl" mt="5">
              <FormLabel>Preview Image</FormLabel>
              {previewImageUrl && <img h="200" src={previewImageUrl} />}

              <ITUButton variant="outline" size="xs" as="label" for="previewImageUrlInput">
                {previewImageUrl ? 'Change' : 'Upload'}
              </ITUButton>

              <Input
                hidden
                id="previewImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setPreviewImageUrl(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl>
              <Flex align="center" mt="4">
                <FormLabel htmlFor="toggle" mb="0">
                  Show in Previews
                </FormLabel>
                <Switch id="toggle" isChecked={showInPreview} onChange={() => setShowInPreview(!showInPreview)} colorScheme="teal" />
              </Flex>
            </FormControl>
            <FormControl>
              <Flex align="center" mt="4">
                <FormLabel htmlFor="toggle" mb="0">
                  Is Bonus
                </FormLabel>
                <Switch id="toggle" isChecked={isBonus} onChange={() => setIsBonus(!isBonus)} colorScheme="teal" />
              </Flex>
            </FormControl>
            <FormControl id="description" mt="5">
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </FormControl>
            <FormControl id="type" mt="5">
              <FormLabel>Type</FormLabel>
              <Select placeholder="Select Type" value={type} onChange={(event) => setType(event.target.value)}>
                <option value="Challenge">Challenge</option>
              </Select>
            </FormControl>
            <FormControl id="membershipLevel" mt="5">
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={(event) => setMembershipLevel(event)}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="Phase" mt="5">
              <FormLabel>Instructor</FormLabel>
              <Select
                placeholder="Select Instructor"
                value={instructor}
                onChange={(e) => {
                  // selectPhases[index].id = e.target.value;
                  // setSelectPhases([...selectPhases]);
                  setInstructor(e.target.value)
                }}
              >
                {instructors &&
                  instructors.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.userNickname}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="learnMoreLink" mt="5">
              <FormLabel>Learn More Link</FormLabel>
              <Input placeholder="Enter Link" value={externalLink} onChange={(e) => setExternalLink(e.target.value)} />
            </FormControl>
            <Tabs marginTop={'24px'} defaultIndex={isTopic ? 1 : 0}>
              <TabList>
                <Tab>have phases</Tab>
                <Tab>no phases</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {selectPhases &&
                    selectPhases.map((phase, phaseIndex) => (
                      <Box
                        key={`Phases-${phaseIndex}`}
                        padding={'20px'}
                        marginTop={'24px'}
                        border="1px solid #f5f5f5"
                        sx={{
                          borderRadius: '10px'
                        }}
                      >
                        <FormControl id="Phase">
                          <FormLabel>Phase</FormLabel>
                          <Select placeholder="Select phase" value={phase.id} onChange={(e) => onChangePhase(phaseIndex, e.target.value)}>
                            {phases &&
                              phases.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.title}
                                </option>
                              ))}
                          </Select>
                        </FormControl>
                        {phase.lessonIds.map((lesson, lessonIndex) => {
                          return (
                            <Box
                              key={`Lessons-${lessonIndex}`}
                              padding={'20px'}
                              marginTop={'24px'}
                              border="1px solid #f5f5f5"
                              sx={{
                                borderRadius: '10px'
                              }}
                            >
                              <FormControl id="Lessons">
                                <FormLabel>Lessons</FormLabel>
                                <Select
                                  placeholder="Select lessons"
                                  value={lesson.id}
                                  onChange={(e) => onChangeLesson(phaseIndex, lessonIndex, e.target.value)}
                                >
                                  {lessons &&
                                    lessons.map((el) => (
                                      <option key={el.id} value={el.id}>
                                        {el.title}
                                      </option>
                                    ))}
                                </Select>
                              </FormControl>
                              <FormControl id="Topic">
                                <FormLabel>Topic</FormLabel>
                                <MultiSelect
                                  placeholder="Select Topics"
                                  isMulti
                                  value={lesson.topicIds.map((topicId) => ({ value: topicId, label: topicsObject[topicId] })) || []}
                                  onChange={(e) => onChangeTopic(phaseIndex, lessonIndex, e)}
                                  options={
                                    topics
                                      ? topics.map((el) => {
                                          return {
                                            value: el.id,
                                            label: el.title
                                          }
                                        })
                                      : []
                                  }
                                />
                              </FormControl>
                              <ButtonGroup gap="4" marginTop={'24px'}>
                                {lessonIndex === phase.lessonIds.length - 1 && (
                                  <ITUButton variant="outline" onClick={() => onAddLesson(phaseIndex)}>
                                    Add Lesson
                                  </ITUButton>
                                )}
                                {phase.lessonIds && phase.lessonIds.length > 1 && (
                                  <Button
                                    colorScheme="red"
                                    onClick={() => {
                                      setSelectPhases((_selectPhases) => [
                                        ..._selectPhases.map((phase, index) =>
                                          index === phaseIndex
                                            ? {
                                                ...phase,
                                                lessonIds: [...phase.lessonIds.filter((_, index1) => index1 !== lessonIndex)]
                                              }
                                            : phase
                                        )
                                      ])
                                    }}
                                  >
                                    Delete Lesson
                                  </Button>
                                )}
                              </ButtonGroup>
                            </Box>
                          )
                        })}
                        <ButtonGroup gap="4" marginTop={'24px'}>
                          {phaseIndex === selectPhases.length - 1 && (
                            <ITUButton
                              variant="outline"
                              onClick={() => {
                                setSelectPhases((_selectPhases) => [
                                  ..._selectPhases,
                                  {
                                    id: '',
                                    lessonIds: [
                                      {
                                        id: '',
                                        topicIds: []
                                      }
                                    ]
                                  }
                                ])
                              }}
                            >
                              Add Phase
                            </ITUButton>
                          )}
                          {selectPhases && selectPhases.length > 1 && (
                            <Button
                              colorScheme="red"
                              onClick={() => {
                                selectPhases.splice(phaseIndex, 1)
                                setSelectPhases([...selectPhases])
                              }}
                            >
                              Delete Phase
                            </Button>
                          )}
                        </ButtonGroup>
                      </Box>
                    ))}
                </TabPanel>
                <TabPanel>
                  {selectPhases &&
                    selectPhases.map((phase, phaseIndex) => (
                      <Box
                        key={`Phases-${phaseIndex}`}
                        sx={{
                          borderRadius: '10px'
                        }}
                      >
                        {phase.lessonIds.map((lesson, lessonIndex) => {
                          return (
                            <Box key={`Lessons-${lessonIndex}`}>
                              <FormControl id="Topic">
                                <FormLabel>Topic</FormLabel>
                                <MultiSelect
                                  placeholder="Select Topics"
                                  isMulti
                                  value={lesson.topicIds.map((topicId) => ({ value: topicId, label: topicsObject[topicId] })) || []}
                                  onChange={(e) => onChangeTopic(phaseIndex, lessonIndex, e)}
                                  options={
                                    topics
                                      ? topics.map((el) => {
                                          return {
                                            value: el.id,
                                            label: el.title
                                          }
                                        })
                                      : []
                                  }
                                />
                              </FormControl>
                            </Box>
                          )
                        })}
                        <ButtonGroup gap="4" marginTop={'24px'}>
                          {phaseIndex === selectPhases.length - 1 && (
                            <ITUButton
                              variant="outline"
                              onClick={() => {
                                setSelectPhases((_selectPhases) => [
                                  ..._selectPhases,
                                  {
                                    id: '',
                                    lessonIds: [
                                      {
                                        id: '',
                                        topicIds: []
                                      }
                                    ]
                                  }
                                ])
                              }}
                            >
                              Add Topic
                            </ITUButton>
                          )}
                          {selectPhases && selectPhases.length > 1 && (
                            <Button
                              colorScheme="red"
                              onClick={() => {
                                selectPhases.splice(phaseIndex, 1)
                                setSelectPhases([...selectPhases])
                              }}
                            >
                              Delete Topic
                            </Button>
                          )}
                        </ButtonGroup>
                      </Box>
                    ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteCourses({
                      coursesId: item.id,
                      id: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        getCourses()
                        toast({
                          title: 'Success',
                          description: 'Course deleted successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Oops. There was an error deleting the course. Please contact tech support.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Oops. There was an error deleting the course. Please contact tech support. Error: ' + error,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

const ChangeStatus = ({ onRefresh, item }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const updateCoursesStatus = async () => {
    setIsLoading(true)
    const res = await api
      .updateCoursesStatus({
        courseId: item.id,
        status: item.status === 'Publish' ? 'Draft' : 'Publish'
      })
      .catch((e) => e)
      .finally(() => {
        setIsLoading(false)
      })

    if (res.data.code === 200) {
      toast({
        title: 'Success',
        description: 'change success',
        status: 'success'
      })
      onRefresh?.({
        ...item,
        status: item.status === 'Publish' ? 'Draft' : 'Publish'
      })
      onClose()
    } else {
      toast({
        title: 'Error',
        description: res.data.msg || 'change error',
        status: 'error'
      })
    }
  }

  return (
    <>
      <Button aria-label="change-status" fontSize={'12px'} size={'sm'} onClick={onOpen}>
        {item.status === 'Publish' ? 'Unpublish' : 'Publish'}
      </Button>
      <AlertDialog isOpen={isOpen} onClose={() => !isLoading && onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Status
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure change status .</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => !isLoading && onClose}>Cancel</Button>
              <Button ml={3} colorScheme="blue" isLoading={isLoading} onClick={updateCoursesStatus}>
                {item.status === 'Publish' ? 'Unpublish' : 'Publish'}
                {/* Delete */}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default CreateCourse
