import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Switch,
  Image,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import Pagination from '../../components/Pagination'
import MultiSelect from 'react-select'
import ITUButton from '../../components/ITUButton'

function ManageFeatureAccess() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [membershipLevel, setMembershipLevel] = useState([])
  const [membershipLevels, setMembershipLevels] = useState([])
  const [createData, setCreateData] = useState({
    id: 0,
    membershipLevel: '',
    description: ''
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: 20
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getAccessList()
    }
  }, [loggedIn])

  useEffect(() => {
    if (loggedIn) {
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
    }
  }, [loggedIn])

  const getAccessList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .fetchAccessList(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: 0,
      profileTypeName: '',
      enableFilter: false,
      enableRemove: false,
      enableSearchRemove: false
    })
  }
  const handleSave = () => {
    setIsLoading(true)
    api
      .updateAccessFeature({
        ...createData,
        membershipLevelIds: membershipLevel.map((el) => el.value)
      })
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Feature access saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getAccessList()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Feature access settings.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )
  console.log(tableData)
  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Feature Access</Heading>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'name', flex: '1', headerName: 'Name', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'description',
              headerName: 'Description',
              flex: '2',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'membershipLevelList',
              headerName: 'Membership Level',
              flex: '3',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => {
                return params.value.map((el) => el.levelName).toString()
              }
            },
            {
              field: 'id',
              headerName: 'Action',
              flex: '1',

              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const { id, ...other } = params.data
                      setMembershipLevel(
                        params.data.membershipLevelList.map((el) => {
                          return { value: el.id, label: el.levelName }
                        })
                      )
                      setCreateData({
                        id,
                        ...other
                      })
                      setIsOpen(true)
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragMove={(a) => {
            // console.log(a);
          }}
        ></AgGridReact>
      </div>

      <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getAccessList({ current, size })
          }}
        />
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Feature Access Setting</ModalHeader>
          <ModalBody>
            <FormControl id="membershipLevel" marginY={'24px'}>
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={(event) => {
                  setMembershipLevel(event)
                }}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="Description">
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter Description"
                value={createData.description}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    description: e.target.value
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Close
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageFeatureAccess
