import { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import { normalizeToJSON, serializeToHTML, toggleMark, withCustom, TextAlignType } from './utils'
import { BadgeComponent, EditableButtonComponent, ImageComponent, LinkComponent } from './ElementNodes'
import { AddLinkButton, BlockButton, InsertImageButton, MarkButton, RemoveLinkButton } from './MenuButtons'
import Toolbar from './components/Toolbar'
import './index.less'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
}

const initialValue = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
]

const RichText = ({ placeholder, hiddenTool, textAreaStyle, readOnly, value, onChange }) => {
  const editor = useMemo(() => withCustom(withHistory(withReact(createEditor()))), [])
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const [loading, serLoading] = useState(true)

  const [defaultValue, setDefaultValue] = useState(() => initialValue)

  useEffect(() => {
    try {
      const nodes = normalizeToJSON(value || '')
      setDefaultValue(nodes.length ? nodes : initialValue)
    } catch (error) {
      //
    }
    serLoading(false)
  }, [])

  return loading ? (
    <div
      style={{
        minHeight: '100px',
        padding: '4px',
        overflow: 'scroll',
        ...textAreaStyle
      }}
    >
      loading
    </div>
  ) : (
    <Slate
      editor={editor}
      initialValue={defaultValue}
      onChange={(value) => {
        onChange?.(serializeToHTML(value) || '')
      }}
    >
      {readOnly ? null : hiddenTool ? null : (
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
          <InsertImageButton />
          <AddLinkButton />
          <RemoveLinkButton />
          {/* <ToggleEditableButtonButton /> */}
        </Toolbar>
      )}
      <Editable
        style={{
          minHeight: '100px',
          padding: '4px',
          overflow: 'scroll',
          ...textAreaStyle
        }}
        readOnly={readOnly}
        className="rich-text"
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        placeholder={placeholder || readOnly ? ' ' : 'Enter some text...'}
      />
    </Slate>
  )
}

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={{ textAlign: element.align || TextAlignType.Justify }} {...attributes}>
          {children}
        </ol>
      )
    case 'link':
      return <LinkComponent {...props} />
    case 'button':
      return <EditableButtonComponent {...props} />
    case 'badge':
      return <BadgeComponent {...props} />
    case 'image':
      return <ImageComponent {...props} />
    default:
      return (
        <p {...attributes} style={{ textAlign: element.align || TextAlignType.Justify }}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default RichText
