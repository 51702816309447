import React from 'react'
import AsyncSelect from 'react-select/async'
import { api } from '../../services/api'
import { ActionMeta, MultiValue } from 'react-select'

type ColourOption = {
  label: string
  value: number
}

const getUsers = async (compositeLike: string) => {
  const { data } = await api.usersNameSearch({ size: 50, compositeLike }).catch((e) => e)
  return (data?.data?.records || []).map(({ displayName, userId }: any) => ({ label: displayName, value: userId }))
}

const loadOptions = (inputValue: string, callback: (options: ColourOption[]) => void) => {
  setTimeout(async () => {
    callback(await getUsers(inputValue))
  }, 1000)
}

export default ({ onChange }: { onChange: (newValue: MultiValue<ColourOption>, actionMeta: ActionMeta<ColourOption>) => void }) => {
  return (
    <AsyncSelect
      isMulti
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onChange={(newValue, actionMeta) => {
        onChange?.(newValue, actionMeta)
      }}
    />
  )
}
