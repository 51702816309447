import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  Input,
  Select,
  useToast,
  Switch,
  Image,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import { AddIcon } from '@chakra-ui/icons'
import Pagination from '../../components/Pagination'
import MultiSelect, { components, MultiValueLabelProps } from 'react-select'
import ITUButton from '../../components/ITUButton'
import NewTask from './NewTask'
import RichTextEditor from '../../components/RichTextEditor/index'

function ManageChallenge() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [addNewTask, setAddNewTask] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [awardTypes, setAwardTypes] = useState([])
  const [tasks, setTasks] = useState([])
  const [groups, setGroups] = useState([])
  const [editTask, setEditTask] = useState()
  const [createData, setCreateData] = useState({
    id: '',
    name: '',
    taskIds: [],
    type: '',
    taskCompletionFrequency: '',
    isPrivate: false,
    disabled: false,
    description: '',
    completionPointsTypeId: 0,
    completionPointsCount: 0,
    endDate: '',
    taskCompletionFrequency: '',
    startDate: ''
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: 20
  })
  const [isLoading, setIsLoading] = useState(false)
  const [groupIds, setGroupIds] = useState([])

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getChallengeList()
      getTaskList()
      getChallengeGroups()
      api
        .fetchPointsType({
          current: 1,
          size: -1
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setAwardTypes(res.data.data.map(({ id, name }) => ({ label: name, value: id })))
            }
          }
        })
    }
  }, [loggedIn])

  const getTaskList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .getChallengeTaskList({
        current: 1,
        size: 9999
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          if (editTask) {
            const _task = res.data.data.records.find((el) => el.id === editTask.id)
            setCreateData((_createData) => {
              return {
                ..._createData,
                taskIds: _task
                  ? [
                      ..._createData.taskIds.filter((el) => el.value !== editTask.id),
                      {
                        value: _task.id,
                        label: _task.name
                      }
                    ]
                  : _createData.taskIds
              }
            })
          }
          setTasks(res.data.data.records)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const getChallengeList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .getChallenges(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data.records,
            total: res.data.data.total
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getChallengeGroups = async () => {
    setIsLoading(true)
    const res = await api.fetchGroups({ current: 1, size: 13000 }).finally(() => {
      setIsLoading(false)
    })
    if (res.data.code === 200) {
      setGroups(res.data.data.records)
    }
  }

  const getChallengetTeams = async (challengeId) => {
    setIsLoading(true)
    const res = await api.getChallengeTeams(challengeId)
    setGroupIds((res.data.data.records || []).map((item) => ({ value: item.id, label: item.name })))
    setIsLoading(false)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: '',
      name: '',
      taskIds: [],
      type: '',
      taskCompletionFrequency: '',
      isPrivate: false,
      disabled: false,
      description: '',
      completionPointsTypeId: 0,
      completionPointsCount: 0,
      endDate: '',
      taskCompletionFrequency: '',
      startDate: ''
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      if (createData.id) {
        await api.updateChallenge({
          ...createData,
          taskIds: createData.taskIds.map((el) => el.value)
        })
        await api.updateChallengeTeams(
          createData.id,
          groupIds.map((item) => item.value)
        )
      } else {
        const res = await api.createChallenge({
          ...createData,
          taskIds: createData.taskIds.map((el) => el.value)
        })
        await api.updateChallengeTeams(
          res.data.data.id,
          groupIds.map((item) => item.value)
        )
      }
      setIsLoading(false)
      toast({
        title: 'Success',
        description: 'Challenge saved successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      handleCloseModal()
      getChallengeList()
    } catch (e) {
      setIsLoading(false)
      toast({
        title: 'Error',
        description: 'Failed to save Challenge.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const removeChallenge = async (challengeId) => {
    setIsLoading(true)
    try {
      await api.removeChallenge(challengeId)
      getChallengeList()
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  const uploadIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadIconLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadIconLoading(false)
      })
    if (data.data) {
      setCreateData({
        ...createData,
        bannerImage: data.data
      })
    }
  }

  const MultiValueLabel = (props, ch) => {
    return (
      <components.MultiValueLabel {...props}>
        <Flex alignItems={'center'}>
          <span {...props}></span>
          <FiEdit3
            style={{
              cursor: 'pointer',
              marginLeft: '6px'
            }}
            onClick={(e) => {
              const _task = tasks.find((el) => el.id === props.data.value)
              setEditTask(_task)
              setAddNewTask(true)
              e.stopPropagation()
            }}
          />
        </Flex>
      </components.MultiValueLabel>
    )
  }
  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Challenges</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setGroupIds([])
              setIsOpen(true)
            }}
          >
            Add Challenge
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
            {
              field: 'disabled',
              headerName: 'Active',
              cellRenderer: (params) => {
                return <Text>{params.value ? 'Yes' : 'No'}</Text>
              },
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'createdAt',
              headerName: 'Starts',
              flex: '1',
              valueFormatter: (params) => {
                return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
              },
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'createdAt',
              headerName: 'Ends',
              flex: '1',
              valueFormatter: (params) => {
                return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
              },
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'id',
              headerName: 'Action',
              flex: '1',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter',
              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const { id, ...other } = params.data
                      setCreateData({
                        id,
                        ...other,
                        description:
                          other.description && other.description.indexOf('data-slate-node=') > -1
                            ? other.description
                            : `<p data-slate-node="element" style="text-align: ;"><span data-slate-node="text">${other.description}<span data-slate-string="true"></span></span></p>`,
                        taskIds: other.taskList
                          ? other.taskList.map((el) => {
                              return {
                                value: el.id,
                                label: el.name
                              }
                            })
                          : [],
                        startDate: dayjs(other.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(other.endDate).format('YYYY-MM-DD')
                      })
                      setGroupIds([])
                      getChallengetTeams(id)
                      setIsOpen(true)
                      setIsEdit(true)
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                  <Button
                    size={'sm'}
                    mr={2}
                    disabled={isLoading}
                    onClick={() => {
                      const { id, ...other } = params.data
                      removeChallenge(id)
                    }}
                  >
                    <FiTrash2 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragMove={(a) => {}}
        ></AgGridReact>
      </div>

      <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getChallengeList({ current, size })
          }}
        />
      </div>
      <Modal isOpen={isOpen && !addNewTask} onClose={handleCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? 'Edit' : 'Create'} Challenge</ModalHeader>
          <ModalBody>
            <FormControl id="title" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Name</FormLabel>
              <Input
                placeholder="Enter Name"
                value={createData.name}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    name: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="prompt" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Description</FormLabel>
              <RichTextEditor
                placeholder="Enter Description"
                value={createData.description}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    description: e
                  })
                }}
              ></RichTextEditor>
            </FormControl>
            <FormControl id="prompt" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Registration Form Embed</FormLabel>
              <Textarea
                placeholder="Enter Registration Form Embed"
                value={createData.embed}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    embed: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                Private
              </FormLabel>
              <Switch
                isChecked={createData.isPrivate}
                onChange={() => {
                  setCreateData({
                    ...createData,
                    isPrivate: !createData.isPrivate
                  })
                }}
                id="email-alerts"
              />
            </FormControl>
            {/* thumbnailPicture */}
            <FormControl mt="5">
              <FormLabel>Banner Image</FormLabel>
              {createData.coverPicture && <Image h="100" src={createData.coverPicture} />}
              <ITUButton variant="outline" size="xs" as="label" for="BannerImageUrlInput">
                {createData.coverPicture ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                id="BannerImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api
                      .uploadFile(formData)
                      .then((res) => {
                        if (res.status === 200) {
                          if (res.data.code === 200) {
                            setCreateData({
                              ...createData,
                              coverPicture: res.data.data
                            })
                          }
                        }
                      })
                      .catch((e) => {
                        console.log(e)
                      })
                  }
                }}
              />
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Thumbnail Image</FormLabel>
              {createData.thumbnailPicture && <img h="100" src={createData.thumbnailPicture} />}
              <ITUButton variant="outline" size="xs" as="label" for="ThumbnailImageUrlInput">
                {createData.thumbnailPicture ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                id="ThumbnailImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setCreateData({
                            ...createData,
                            thumbnailPicture: res.data.data
                          })
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl id="prompt" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Type</FormLabel>
              <Select
                placeholder="Select Type"
                value={createData.type}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    type: e.target.value
                  })
                }
              >
                <option value="Classic">Classic</option>
                <option value="Form Only">Form Only</option>
              </Select>
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                Disabled
              </FormLabel>
              <Switch
                isChecked={createData.disabled}
                onChange={() => {
                  setCreateData({
                    ...createData,
                    disabled: !createData.disabled
                  })
                }}
                id="email-alerts"
              />
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                Start Date
              </FormLabel>
              <Input
                type="date"
                name="startDate"
                value={createData.startDate}
                onChange={() => {
                  setCreateData({
                    ...createData,
                    startDate: event.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl display="flex" marginBottom={'24px'} alignItems="center">
              <FormLabel width={'100px'} htmlFor="email-alerts" mb="0">
                End Date
              </FormLabel>
              <Input
                type="date"
                name="endDate"
                value={createData.endDate}
                onChange={(event) => {
                  setCreateData({
                    ...createData,
                    endDate: event.target.value
                  })
                }}
              />
            </FormControl>

            <Text fontWeight={700} fontSize={'20px'} marginBottom={'24px'}>
              Tasks:
            </Text>
            <FormControl id="task" marginBottom={'24px'}>
              <FormLabel>Task</FormLabel>
              {tasks && tasks.length > 0 && (
                <MultiSelect
                  defaultValue={createData.taskIds}
                  isMulti
                  name="taskIds"
                  onChange={(value) => {
                    setCreateData({
                      ...createData,
                      taskIds: value
                    })
                  }}
                  components={{ MultiValueLabel }}
                  options={
                    tasks
                      ? tasks.map((el) => {
                          return {
                            value: el.id,
                            label: el.name
                          }
                        })
                      : []
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )}
            </FormControl>
            <FormControl id="prompt" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Type</FormLabel>
              <Select
                placeholder="Select Type"
                value={createData.taskCompletionFrequency}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    taskCompletionFrequency: e.target.value
                  })
                }
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </Select>
            </FormControl>
            <Button
              onClick={() => {
                setEditTask(undefined)
                setAddNewTask(true)
              }}
              marginBottom={'24px'}
              eftIcon={<AddIcon />}
            >
              Add New Task
            </Button>
            <FormControl id="task" marginBottom={'24px'}>
              <FormLabel>Teams</FormLabel>
              {groups && groups.length > 0 && (
                <MultiSelect
                  value={groupIds}
                  isMulti
                  name="taskIds"
                  onChange={(value) => {
                    setGroupIds(value)
                  }}
                  options={
                    tasks
                      ? groups.map((el) => {
                          return {
                            value: el.id,
                            label: el.name
                          }
                        })
                      : []
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )}
            </FormControl>
            <Text fontWeight={700} fontSize={'20px'} marginBottom={'24px'}>
              Completion:
            </Text>
            <FormControl id="prompt" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Points Type</FormLabel>
              <Select
                placeholder="Select Points Type"
                value={createData.completionPointsTypeId}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    completionPointsTypeId: e.target.value
                  })
                }
              >
                {awardTypes.map((el) => (
                  <option key={`option-${el.label}`} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="Points" marginBottom={'24px'}>
              <FormLabel width={'100px'}>Points</FormLabel>
              <Input
                placeholder="Enter Name"
                value={createData.completionPointsCount}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    completionPointsCount: e.target.value
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NewTask
        editTask={editTask}
        isOpen={addNewTask}
        tasks={tasks}
        awardTypes={awardTypes}
        handleCloseModal={(type) => {
          setEditTask(undefined)
          if (type === 'refresh') {
            if (editTask) {
              setTasks([])
            }
            getTaskList()
          }
          setAddNewTask(false)
        }}
      ></NewTask>
    </Layout>
  )
}

export default ManageChallenge
