import React, { useState, useEffect, useMemo, useRef } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Switch,
  Spacer,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  IconButton,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Box,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  useToast
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MultiSelect from 'react-select'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../components/ITUButton'

function ManageTopics() {
  const [users, setUsers] = useState([])
  const [instructorId, setInstructorId] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [materialsTitle, setMaterialsTitle] = useState('')
  const [keyword, setKeyword] = useState('')
  const [autoStart, setAutoStart] = useState(0)
  const [videoControlsDisplay, setVideoControlsDisplay] = useState(0)
  const [materialsUrl, setMaterialsUrl] = useState('')
  const [videoPause, setVideoPause] = useState(0)
  const [videoResume, setVideoResume] = useState(0)
  const [assignmentUploads, setAssignmentUploads] = useState(0)
  const [topicTimer, setTopicTimer] = useState(0)
  const [videoDisplaySetting, setVideoDisplaySetting] = useState('')
  const [membershipLevel, setMembershipLevel] = useState([])
  const [membershipLevels, setMembershipLevels] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [phaseId, setPhaseId] = useState(0)
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('')
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState('')
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [pages, setPages] = useState(0)
  const [flag, setFlag] = useState(false)
  const [item, setItem] = useState(null)
  const inputRef = useRef()
  const [params, setParams] = useState({
    current: 1,
    size: 1000,
    title: ''
  })
  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      api
        .fetchTopics({
          current: params.current,
          title: params.title,
          size: params.size
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setRowData(res.data.data.records)
            setPages(res.data.data.pages)
          }
        })
    }
  }, [loggedIn, params.size, params.current, flag, params.title])
  const handleCloseModal = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchUsers({
          current: 1,
          size: 1000,
          profileTypeId: 5
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setUsers(res.data.data.records)
            }
          }
        })
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
    }
  }, [loggedIn])
  const handleSave = () => {
    setIsLoading(true)
    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .updateTopic({
          id: item.id,
          description: content,
          content: content,
          title,
          phaseId,
          videoPreviewUrl,
          videoThumbnailUrl,
          autoStart,
          videoControlsDisplay,
          membershipLevelIds: membershipLevel.map((el) => el.value),
          videoPause,
          videoResume,
          topicTimer,
          instructorId: instructorId ? instructorId.value : '',
          videoDisplaySetting,
          materialsTitle,
          materialsUrl,
        })
        .then((response) => {
          // Handle the response

          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Topics saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          setFlag((_flag) => !_flag)
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to save Topics.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    } else {
      api
        .createTopic({
          description: content,
          title,
          phaseId,
          videoPreviewUrl,
          materialsTitle,
          materialsUrl,
          autoStart,
          instructorId: instructorId ? instructorId.value : '',
          membershipLevelIds: membershipLevel.map((el) => el.value),
          videoControlsDisplay,
          videoPause,
          videoResume,
          assignmentUploads,
          topicTimer,
          videoDisplaySetting
        })
        .then((response) => {
          // Handle the response

          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Topics saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          setFlag((_flag) => !_flag)
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to save Topics.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    }
  }
  const gotoPage = (page) => {
    setParams((_params) => {
      return { ..._params, current: page }
    })
  }
  const previousPage = () => {
    setParams((_params) => {
      return { ..._params, current: _params.current - 1 }
    })
  }
  const canPreviousPage = useMemo(() => {
    return params.current > 1
  }, [params, pages])

  const canNextPage = useMemo(() => {
    return params.current < pages
  }, [params, pages])

  const nextPage = () => {
    setParams((_params) => {
      return { ..._params, current: _params.current + 1 }
    })
  }

  const setDefaultValue = () => {
    setInstructorId(null)
    setContent(null)
    setTitle(null)
    setVideoPreviewUrl(null)
    setVideoThumbnailUrl(null)
    setVideoControlsDisplay(null)
    setVideoDisplaySetting(null)
    setVideoPause(null)
    setVideoResume(null)
    setTopicTimer(null)
    setAutoStart(null)
    setItem(null)
    setUploadedFileName('')
    setMembershipLevel([])
  }

  const onEdit = (params) => {
    setDefaultValue()
    if (params.data.instructorId) {
      const _instructor = users.find((el) => el.id === params.data.instructorId)
      if (_instructor) {
        setInstructorId({
          label: _instructor.userNickname || _instructor.userLogin,
          value: _instructor.id
        })
      }
    }
    setContent(params.data.content)
    setTitle(params.data.title)
    setVideoPreviewUrl(params.data.videoPreviewUrl)
    setVideoThumbnailUrl(params.data.videoThumbnailUrl)
    setVideoControlsDisplay(params.data.videoControlsDisplay)
    setVideoDisplaySetting(params.data.videoDisplaySetting)
    setVideoPause(params.data.videoPause)
    setVideoResume(params.data.videoResume)
    setTopicTimer(params.data.topicTimer)
    setAutoStart(params.data.autoStart)
    setItem(params.data)
    const materialUrl = params?.data?.materials?.[0]?.materialsPreviewUrl
    if (materialUrl) setUploadedFileName(materialUrl.slice(materialUrl.lastIndexOf('/') + 1, materialUrl.length))
    if (params.data.membershipLevels && params.data.membershipLevels.length > 0) {
      setMembershipLevel(
        params.data.membershipLevels.map((el) => {
          return {
            value: el.id,
            label: el.levelName
          }
        })
      )
    }
    setIsOpen(true)
  }

  const descriptionToolTip = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, [])
    return (
      <Box
        className="custom-tooltip"
        style={{ backgroundColor: props.color || 'beige' }}
        maxW="300px"
        p={3}
        border={props.border || '1px solid black'}
      >
        <p>
          <span>{data.title}</span>
        </p>
      </Box>
    )
  }

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      tooltipComponent: descriptionToolTip
    }
  }, [])

  const columnDefs = [
    {
      field: 'title',
      headerName: 'Title',
      flex: '3',
      tooltipField: 'title',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      tooltipComponentParams: { title: 'foobar', description: 'my desc' }
    },
    {
      field: 'membershipLevels',
      headerName: 'MembershipLevels',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => (params.value ? params.value.map((el) => el.levelName).toString() : ''),
      flex: '2'
    },
    { field: 'videoPreviewUrl', headerName: 'Video Url', flex: '2' },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '2'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                onEdit(params)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Topics</Heading>
        <Flex gap={'24px'} justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setDefaultValue()
              setIsOpen(true)
            }}
          >
            Add Topic
          </ITUButton>
        </Flex>
      </Flex>

      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
        ></AgGridReact>
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{item ? 'Create' : 'Edit'} Topics</ModalHeader>
          <ModalBody>
            <FormControl id="Title">
              <FormLabel>Title</FormLabel>
              <Input placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl id="description" marginTop={'24px'}>
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter description" value={content} onChange={(e) => setContent(e.target.value)} height={'300px'} />
            </FormControl>
            <FormControl id="instructor" marginTop={'24px'}>
              <FormLabel>Instructor</FormLabel>
              <MultiSelect
                defaultValue={instructorId}
                name="instructor"
                onChange={(event) => {
                  setInstructorId(event)
                }}
                options={
                  users
                    ? users.map((el) => {
                        return {
                          value: el.id,
                          label: el.userNickname || el.userLogin
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="membershipLevel" marginY={'24px'}>
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={(event) => {
                  setMembershipLevel(event)
                }}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>

            <FormControl id="CourseContent">
              <FormLabel>Course Content</FormLabel>
              <Flex>
                <Box width={'180px'} textAlign={'right'}>
                  Document Title:
                </Box>
                <Input marginLeft={5} value={materialsTitle} onChange={(e) => setMaterialsTitle(e.target.value)} />
              </Flex>
              <Flex marginTop={3}>
                <Box width={'180px'} textAlign={'right'}>
                  Document File Name:
                </Box>
                <Text marginLeft={5}>{uploadedFileName}</Text>
              </Flex>
              <ITUButton
                variant="outline"
                size="xs"
                onClick={() => {
                  if (inputRef && inputRef.current) {
                    inputRef.current.click()
                  }
                }}
              >
                Upload PDF
              </ITUButton>
              <input
                ref={inputRef}
                type="file"
                single
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    toast({
                      status: 'info',
                      title: 'Uploading PDF'
                    })
                    api.uploadPdf(formData).then((res) => {
                      if (res.status === 200) {
                        toast({
                          status: 'success',
                          title: `Completed uploading ${event.target.files[0].name}`
                        })
                        setUploadedFileName(event.target.files[0].name)
                        setMaterialsUrl(res.data.data)
                      }
                    })
                  }
                }}
                style={{ visibility: 'hidden' }}
                accept="application/pdf"
              ></input>
            </FormControl>

            <FormControl id="videoLink" marginTop={'24px'}>
              <FormLabel>Video Link</FormLabel>
              <Input value={videoPreviewUrl} onChange={(e) => setVideoPreviewUrl(e.target.value)} />
            </FormControl>
            <FormControl id="videoThumbnailUrl" mt="5">
              <FormLabel>Thumbnail Image</FormLabel>
              {videoThumbnailUrl && <img height="200" src={videoThumbnailUrl} />}

              <ITUButton variant="outline" size="xs" as="label" for="previewImageUrlInput">
                {videoThumbnailUrl ? 'Change' : 'Upload'}
              </ITUButton>

              <Input
                hidden
                id="previewImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setVideoThumbnailUrl(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl id="videoDisplaySetting" marginTop={'24px'}>
              <FormLabel>Video Display Setting</FormLabel>
              <RadioGroup onChange={setVideoDisplaySetting} value={videoDisplaySetting}>
                <Stack direction="column">
                  <Radio value="1">
                    <div>Before completed sub-steps</div>
                    <Box fontSize={'14px'}>
                      The video will be shown and must be fully watched before the user can access the topic's associated steps.
                    </Box>
                  </Radio>
                  <Radio value="2">
                    <div>After completing sub-steps</div>
                    <Box fontSize={'14px'}>
                      The video will be visible after the user has completed the topic's associated steps. The full video must be watched in
                      order to complete the topic.
                    </Box>
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <Flex alignItems={'center'} gap={'60px'} marginTop={'24px'}>
              <FormLabel width={'300px'}>AutoStart</FormLabel>
              <Switch id="autoStart" isChecked={autoStart === 1} onChange={(event) => setAutoStart((_autoStart) => 1 - _autoStart)} />
            </Flex>
            <Flex alignItems={'center'} gap={'60px'}>
              <FormLabel width={'300px'}>Video Controls Display</FormLabel>
              <Switch
                id="videoControlsDisplay"
                isChecked={videoControlsDisplay === 1}
                onChange={(event) => setVideoControlsDisplay((_videoControlsDisplay) => 1 - _videoControlsDisplay)}
              />
            </Flex>
            <Flex alignItems={'center'} gap={'60px'}>
              <FormLabel width={'300px'}>Video Pause on Window Unfocused</FormLabel>
              <Switch id="videoPause" isChecked={videoPause === 1} onChange={(event) => setVideoPause((_videoPause) => 1 - _videoPause)} />
            </Flex>
            <Flex alignItems={'center'} gap={'60px'}>
              <FormLabel width={'300px'}>Video Resume</FormLabel>
              <Switch
                id="videoResume"
                isChecked={videoResume === 1}
                onChange={(event) => setVideoResume((_videoResume) => 1 - _videoResume)}
              />
            </Flex>
            <Flex alignItems={'center'} gap={'60px'}>
              <FormLabel width={'300px'}>Assignment Uploads</FormLabel>
              <Switch
                id="assignmentUploads"
                isChecked={assignmentUploads === 1}
                onChange={(event) => setAssignmentUploads((_assignmentUploads) => 1 - _assignmentUploads)}
              />
            </Flex>
            <Flex alignItems={'center'} gap={'60px'}>
              <FormLabel width={'300px'}>Topic Timer</FormLabel>
              <Switch id="topicTimer" isChecked={topicTimer === 1} onChange={(event) => setTopicTimer((_topicTimer) => 1 - _topicTimer)} />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteTopic({
                      topicId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Topics saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                        setFlag((_flag) => !_flag)
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Failed to delete Topics.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete Topics.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageTopics
