import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Switch,
  Image
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'

function ManageBadge() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [users, setUsers] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [pointsAwarded, setPointsAwarded] = useState('')
  const [earnedBy, setEarnedBy] = useState('')
  const [congratulationsMessage, setCongratulationsMessage] = useState('')
  const [maxEarningPerUser, setMaxEarningPerUser] = useState('')
  const [globalMaxEarning, setGlobalMaxEarning] = useState('')
  const [showUser, setShowUser] = useState(false)
  const [allowUnlockWithPoints, setAllowUnlockWithPoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [pointsType, setPointsType] = useState(null)
  const [pointsTypes, setPointsTypes] = useState(null)
  const [badgeImage, setBadgeImage] = useState('')

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchBadges({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setRowData(res.data.data.records)
            }
          }
        })
      api
        .fetchPointsType({
          current: 1,
          size: 100
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              console.log(res.data.data)
              setPointsTypes(res.data.data)
            }
          }
        })
    }
  }, [loggedIn])

  const handleCloseModal = () => {
    setName('')
    setDescription('')
    setPointsAwarded('')
    setEarnedBy('')
    setCongratulationsMessage('')
    setMaxEarningPerUser('')
    setGlobalMaxEarning('')
    setShowUser(false)
    setAllowUnlockWithPoints(false)
    setIsOpen(false)
    setPointsType()
  }

  const handleSave = () => {
    setIsLoading(true)
    if (item) {
      api
        .updateBadge({
          imageUrl: badgeImage,
          allowUnlock: allowUnlockWithPoints,
          congratulationMsg: congratulationsMessage,
          description: description,
          earnedBy: earnedBy,
          maxEarn: maxEarningPerUser,
          maxGlobalEarn: globalMaxEarning,
          name: name,
          pointsAward: pointsAwarded,
          pointsTypeId: pointsType,
          showUser: showUser,
          id: item.id
        })
        .then((response) => {
          // Handle the response
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Badge updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error updating the badge. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    } else {
      api
        .createBadge({
          imageUrl: badgeImage,
          allowUnlock: allowUnlockWithPoints,
          congratulationMsg: congratulationsMessage,
          description: description,
          earnedBy: earnedBy,
          maxEarn: maxEarningPerUser,
          maxGlobalEarn: globalMaxEarning,
          name: name,
          pointsAward: pointsAwarded,
          pointsTypeId: pointsType,
          showUser: showUser
        })
        .then((response) => {
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Badge saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error saving the badge. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    }
    // Perform your save logic here

    // Clear the input fields
  }

  const columnDefs = [
    { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '2',
      width: 400,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Badge Image',
      field: 'imageUrl',
      cellRenderer: (params) => {
        return params.value && <Image h={45} src={params.value} alt="badge image" />
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setIsOpen(true)
                setItem(params.data)
                setName(params.data.name)
                setAllowUnlockWithPoints(params.data.allowUnlock)
                setCongratulationsMessage(params.data.congratulationMsg)
                setDescription(params.data.description)
                setEarnedBy(params.data.earnedBy)
                setMaxEarningPerUser(params.data.maxEarn)
                setGlobalMaxEarning(params.data.maxGlobalEarn)
                setPointsAwarded(params.data.pointsAward)
                setShowUser(params.data.showUser)
                setPointsType(params.data.pointsTypeId)
                setBadgeImage(params.data.imageUrl)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Badges</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsOpen(true)
              setItem(null)
            }}
          >
            Add Badge
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Badge</ModalHeader>
          <ModalBody>
            <Flex direction="column">
              <FormControl id="Name" isRequired>
                <FormLabel>Title</FormLabel>
                <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
              <FormControl id="description" mt="4">
                <FormLabel>Content</FormLabel>
                <Textarea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
              </FormControl>
              <FormControl id="badgeImage" mt="5">
                <FormLabel>Badge Image</FormLabel>
                {badgeImage && <Image height="100" src={badgeImage} />}

                <ITUButton variant="outline" size="xs" as="label" for="trackImageUrlInput">
                  {badgeImage ? 'Change' : 'Upload'}
                </ITUButton>

                <Input
                  hidden
                  id="trackImageUrlInput"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      const formData = new FormData()
                      formData.append('file', event.target.files[0])
                      formData.append('resourceType', 'document')
                      api.uploadFile(formData).then((res) => {
                        if (res.status === 200) {
                          if (res.data.code === 200) {
                            setBadgeImage(res.data.data)
                          }
                        }
                      })
                    }
                  }}
                />
              </FormControl>
              <FormControl id="pointsType" isRequired mt="4">
                <FormLabel>Points Type</FormLabel>
                <Select placeholder="Select Points Type" value={pointsType} onChange={(e) => setPointsType(e.target.value)}>
                  {pointsTypes &&
                    pointsTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  {/* <option value="completingSteps">Completing Steps</option>
                  <option value="adminAwardedOnly">Admin-awarded Only</option> */}
                </Select>
              </FormControl>

              <FormControl id="pointsAwarded" isRequired mt="4">
                <FormLabel>Points Awarded</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter points awarded"
                  value={pointsAwarded}
                  onChange={(e) => setPointsAwarded(e.target.value)}
                />
              </FormControl>
              <FormControl id="earnedBy" isRequired mt="4">
                <FormLabel>Earned By</FormLabel>
                <Select placeholder="Select earned by" value={earnedBy} onChange={(e) => setEarnedBy(e.target.value)}>
                  <option value="COMPLETING_STEPS">Completing Steps</option>
                  <option value="ADMIN_AWARDED">Admin-awarded Only</option>
                  <option value="REACH_RANK">Reach a Rank</option>
                  <option value="MINIMUM_NUMBER_OF_POINTS">Minimum Number of Points</option>
                </Select>
              </FormControl>
              <FormControl id="congratulationsMessage" mt="4">
                <FormLabel>Congratulations Message</FormLabel>
                <Textarea
                  placeholder="Enter congratulations message"
                  value={congratulationsMessage}
                  onChange={(e) => setCongratulationsMessage(e.target.value)}
                />
              </FormControl>
              <FormControl id="maxEarningPerUser" isRequired mt="4">
                <FormLabel>Max Earning per User</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter max earning per user"
                  value={maxEarningPerUser}
                  onChange={(e) => setMaxEarningPerUser(e.target.value)}
                />
              </FormControl>
              <FormControl id="globalMaxEarning" isRequired mt="4">
                <FormLabel>Global Max Earning</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter global max earning"
                  value={globalMaxEarning}
                  onChange={(e) => setGlobalMaxEarning(e.target.value)}
                />
              </FormControl>
              <FormControl id="showUser" mt="4">
                <FormLabel>Show User</FormLabel>
                <Switch colorScheme="teal" isChecked={showUser} onChange={() => setShowUser(!showUser)} />
              </FormControl>
              <FormControl id="allowUnlockWithPoints" mt="4">
                <FormLabel>Allow Unlock with Points</FormLabel>
                <Switch
                  colorScheme="teal"
                  isChecked={allowUnlockWithPoints}
                  onChange={() => setAllowUnlockWithPoints(!allowUnlockWithPoints)}
                />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  // Perform delete logic here
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageBadge
