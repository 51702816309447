import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  Input,
  Select,
  useToast,
  Switch,
  Image,
  Text,
  Box,
  List,
  ListItem
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DragHandleIcon } from '@chakra-ui/icons'
import ITUButton from '../../components/ITUButton'

function ManageITUVoice() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  // const [allForums, setAllForums] = useState([]);
  const [ituVoiceForums, setITUVoiceForums] = useState([])
  const [selectedForumsLeft, setSelectedForumsLeft] = useState([])
  const [selectedForumsRight, setSelectedForumsRight] = useState([])
  const [unselectedForums, setUnselectedForums] = useState([])

  // const [pageQuery, setPageQuery] = useState({
  //   // active: true,
  //   current: 1,
  //   size: 20,
  // });
  const [isLoading, setIsLoading] = useState(false)
  const [pageName, setPageName] = useState('ITU Voice')

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reorderedList = Array.from(ituVoiceForums)
    const [reorderedItem] = reorderedList.splice(result.source.index, 1)
    reorderedList.splice(result.destination.index, 0, reorderedItem)

    setITUVoiceForums(reorderedList)
  }

  const toast = useToast()
  const handleAddItem = () => {
    if (selectedForumsLeft) {
      setITUVoiceForums([...ituVoiceForums, selectedForumsLeft])
      setUnselectedForums(unselectedForums.filter((item) => item !== selectedForumsLeft))
      setSelectedForumsLeft(null)
    } else {
      return
    }
  }
  const handleRemoveItem = () => {
    if (selectedForumsRight) {
      // Remove the selected item from the ituVoiceForums list
      const updatedITUVoiceForums = ituVoiceForums.filter((item) => item !== selectedForumsRight)

      // Add the selected item back to the unselectedForums list
      setUnselectedForums([...unselectedForums, selectedForumsRight])

      // Update the ituVoiceForums state
      setITUVoiceForums(updatedITUVoiceForums)

      // Clear the selection
      setSelectedForumsRight(null)
    } else {
      return
    }
  }
  const saveForumsOrder = async () => {
    setIsLoading(true) // Start loading indicator

    const orderedForumsData = {
      updateItemList: ituVoiceForums.map((forum, index) => ({
        forumId: forum.id,
        showVideoPage: true,
        order: index
      }))
    }
    try {
      const response = await api.updateVoiceForums(orderedForumsData) // Assuming the method is named `saveOrderedForums`

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Forums order saved successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    setIsLoading(false) // End loading indicator
  }

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      api
        .fetchForumsList({
          current: 1,
          size: 9999
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              const filteredForums = res.data.data.records.filter((record) => record.showVideoPage === false)
              setUnselectedForums(filteredForums)
              const ITUForums = res.data.data.records
                .filter((record) => record.showVideoPage === true)
                .sort((a, b) => a.forumVideoOrder - b.forumVideoOrder)
              setITUVoiceForums(ITUForums)
            }
          }
        })
    }
  }, [loggedIn])

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage ITU Voices Page</Heading>

        <FormControl id="pageName">
          <Flex alignItems="center">
            <FormLabel mr={3} width={100}>
              Page Name
            </FormLabel>
            <Input
              type="text"
              placeholder="ITU Voices"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              width={300}
              border={'1px solid grey'}
            />
          </Flex>
        </FormControl>
      </Flex>

      <Heading marginBottom="2rem" mt={5} size={'md'}>
        Add Topic
      </Heading>
      {/* Input field for page name */}
      <Flex mt={6} alignItems={'center'} justifyContent={'space-between'} maxWidth={800}>
        <Box>
          {/* Left side: Unselected forums */}
          <List
            border="1px solid"
            width="300px"
            minHeight="200px"
            height={400}
            overflowY="auto"
            sx={{
              '::-webkit-scrollbar': {
                width: '8px' // adjust width as needed
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderRadius: '4px'
              },
              '::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.1)'
              },
              scrollbarWidth: 'thin', // for Firefox
              scrollbarColor: 'rgba(0,0,0,0.5) rgba(0,0,0,0.1)' // for Firefox
            }}
          >
            {unselectedForums.map((item) => (
              <ListItem
                key={item}
                onClick={() => setSelectedForumsLeft(item)}
                cursor="pointer"
                bg={selectedForumsLeft === item ? 'blue.100' : 'transparent'}
                p="10px"
              >
                {item.forumName}
              </ListItem>
            ))}
          </List>
        </Box>

        <Flex alignItems={'center'} justifyContent={'center'} width={200} direction="column">
          <Button onClick={handleRemoveItem} disabled={!!selectedForumsLeft} _disabled={{ borderColor: 'red' }}>
            &lt;
          </Button>
          <Button onClick={handleAddItem} disabled={!!selectedForumsRight} _disabled={{ borderColor: 'red' }}>
            &gt;
          </Button>
        </Flex>
        <Box>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="ituVoiceForums">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  border="1px solid"
                  width="300px"
                  minHeight="200px"
                  height={400}
                  overflowY="auto"
                >
                  {ituVoiceForums.map((item, index) => (
                    <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          onClick={() => setSelectedForumsRight(item)}
                          cursor="pointer"
                          bg={selectedForumsRight === item ? 'blue.100' : 'transparent'}
                          p="10px"
                        >
                          <DragHandleIcon mr="10px" cursor="grab" />
                          {item.forumName}
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>

      {/* Save Button */}
      <Flex justifyContent="flex-end" mt={4}>
        <ITUButton isLoading={isLoading} onClick={saveForumsOrder}>
          Save
        </ITUButton>
      </Flex>
    </Layout>
  )
}

export default ManageITUVoice
