import lifecycles from './impact-theory-app-itu-admin'

export const ituSubMenu = [
  {
    label: 'View All Users',
    subLabel: 'View All Users',
    href: '/itu/users'
  }
]

export const { bootstrap, mount, unmount } = lifecycles
