import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  HStack,
  Radio,
  Select,
  FormControl,
  FormLabel,
  RadioGroup,
  Flex,
  Switch,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Image
} from '@chakra-ui/react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import useAutoTable from '../components/useAutoTable'
// import { Modal } from '@arco-design/web-react'
import ITUButton from '../components/ITUButton'
import { AsyncPaginate } from 'react-select-async-paginate'

function CreateGroups() {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [challenge, setChallenge] = useState(false)
  const [isForum, setIsForum] = useState(false)
  const [description, setDescription] = useState('')
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState([])
  const [forums, setForums] = useState([])
  const [forum, setForum] = useState()
  const [member, setMember] = useState([])
  const [members, setMembers] = useState([])
  const [groupType, setGroupType] = useState('')
  const [maxMember, setMaxMember] = useState('100')
  const [groupPrivacy, setGroupPrivacy] = useState('PUBLIC')
  const [inviteJoin, setInviteJoin] = useState('All Members')
  const [uploadVideos, setUploadVideos] = useState('All Members')
  const [manageMessages, setManageMessages] = useState('All Members')
  const [createAlbums, setCreateAlbums] = useState('All Members')
  const [uploadDocuments, setUploadDocuments] = useState('All Members')
  const [postInto, setPostInto] = useState('All Members')
  const [uploadUpload, setUploadUpload] = useState('All Members')
  const [groupTypes, setGroupTypes] = useState([])
  const [groupOrganizer, setGroupOrganizer] = useState([])
  const [moderator, setModerator] = useState([])
  const [coverPicture, setCoverPicture] = useState('')
  const [thumbnailPicture, setThumbnailPicture] = useState('')
  const [fetching, setFetching] = useState(false)
  const [value, onChange] = useState(null)

  const toast = useToast()

  const getCourses = () => {
    api
      .fetchCourses({
        current: 1,
        size: 9999,
        type: 'Challenge'
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setCourses(res.data.data.records)
        }
      })
    api
      .fetchForumsList({
        current: 1,
        size: 9999
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setForums(res.data.data.records)
        }
      })
  }
  useEffect(() => {
    getCourses()
  }, [])

  const getTableData = async (query) => {
    const result = {
      list: [],
      total: 0
    }

    try {
      setFetching(true)
      const res = await api.fetchGroups(query).finally(() => {
        setFetching(false)
      })
      if (res.data.code === 200) {
        result.list = res.data.data.records
        result.total = res.data.data.total
      }
    } catch (error) {
      // error
    }
    return result
  }

  const getGroupOrganizer = (params) => {
    console.log('params', params)
    if (params.data.members) {
      const organizer = params.data.members.filter((el) => el.userTitle === 'Group Organizer' || el.userTitle === 'Organizer')
      const organizerId = organizer
        ? organizer.map((el) => {
            return {
              value: el.userId,
              label: el.displayName || el.userNickname
            }
          })
        : []
      return organizerId
    }
  }
  const getModerator = (params) => {
    if (params.data.members) {
      const moderator = params.data.members.filter((el) => el.userTitle === 'Group Moderator' || el.userTitle === 'Moderator')
      const moderatorId = moderator
        ? moderator.map((el) => {
            return {
              value: el.userId,
              label: el.displayName || el.userNickname
            }
          })
        : []
      return moderatorId
    }
  }

  useEffect(() => {
    // getTableData()

    api
      .fetchGroupTypes({
        current: 1,
        size: 20
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setGroupTypes(res.data.data.records)
          }
        }
      })
  }, [])
  const handleCloseModal = () => {
    setIsOpen(false)
    setItem(null)
    setTitle('')
    setDescription('')
    setMember([])
    setChallenge(false)
    setGroupType('')
    setGroupOrganizer([])
    setModerator([])
    setCoverPicture('')
    setThumbnailPicture('')
    setCourse([])
    setForum()
    setInviteJoin('All Members')
    setUploadVideos('All Members')
    setManageMessages('All Members')
    setCreateAlbums('All Members')
    setUploadDocuments('All Members')
    setPostInto('All Members')
    setUploadUpload('All Members')
    setGroupPrivacy('PUBLIC')
  }
  const handleSave = () => {
    if (!title) {
      toast({
        title: 'Error',
        description: 'Please enter title',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    if (!groupType) {
      toast({
        title: 'Error',
        description: 'Please select groupType',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
      return
    }
    let params = {
      groupId: item?.id || 0,
      userId: 0,
      groupMember: {
        member: member.map((el) => el.value),
        moderator: moderator ? moderator.map((el) => el.value) : undefined,
        organizer: groupOrganizer ? groupOrganizer.map((el) => el.value) : undefined
      },
      enableForum: isForum ? 1 : 0,
      forumId: forum ? forum.value : undefined,
      status: groupPrivacy,
      groupSetting: {
        activity_feed_status: postInto,
        album_status: createAlbums,
        document_status: uploadDocuments,
        invite_status: inviteJoin,
        media_status: uploadUpload,
        message_status: manageMessages,
        video_status: uploadVideos
      },
      description: description,
      maxMember: Number(maxMember),
      name: title,
      challenge,
      courseIds: course.map((el) => el.value),
      groupTypeId: groupType,
      coverPicture,
      thumbnailPicture
    }

    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, description, videoPreviewUrl)
    api
      .createGroup(params)
      .then((response) => {
        // Handle the response
        setIsLoading(false)
        handleCloseModal()
        toast({
          title: 'Success',
          description: 'Group updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        refreshTable()
      })
      .catch((error) => {
        // Handle the error
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Oops. There was an error updating the group. Error: ' + error.response.data.msg,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }
  const columnDefs = [
    { field: 'id', headerName: 'ID', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    { field: 'groupTypeName', headerName: 'TypeName', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'name',
      headerName: 'Name',
      flex: '1',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '2',
      width: 400,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'lastModifiedAt',
      headerName: 'Last Modified At',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '2',
      width: 400,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={async () => {
                const _course = await api.getGroupCourse({
                  groupId: params.data.id
                })
                if (_course.status === 200 && _course.data.code === 200) {
                  setCourse(
                    _course.data.data.map((el) => {
                      return {
                        value: el.courseId,
                        label: el.coursesTitle
                      }
                    })
                  )
                }
                setMaxMember(params.data.maxMember)
                setDescription(params.data.description)
                setChallenge(params.data.challenge || false)
                setTitle(params.data.name)
                if (params.data.forumId) {
                  const _forum = forums.find((el) => el.id === params.data.forumId)
                  if (_forum) {
                    setForum({
                      value: _forum.id,
                      label: _forum.forumName
                    })
                  }
                }
                setIsForum(params.data.enableForum === 1)
                setMember(
                  params.data.members
                    ? params.data.members.map((el) => ({
                        value: el.userId,
                        label: `${
                          el.profileMap && el.profileMap.first_name && el.profileMap.last_name
                            ? el.profileMap.first_name + '' + el.profileMap.last_name
                            : el.userNickname
                        } (${el.userEmail})`
                      }))
                    : []
                )
                if (params.data.groupMetaVo) {
                  if (params.data.groupMetaVo.activity_feed_status) {
                    setPostInto(params.data.groupMetaVo.activity_feed_status)
                  }
                  if (params.data.groupMetaVo.album_status) {
                    setCreateAlbums(params.data.groupMetaVo.album_status)
                  }
                  if (params.data.groupMetaVo.document_status) {
                    setUploadDocuments(params.data.groupMetaVo.document_status)
                  }
                  if (params.data.groupMetaVo.invite_status) {
                    setInviteJoin(params.data.groupMetaVo.invite_status)
                  }
                  if (params.data.groupMetaVo.media_status) {
                    setUploadUpload(params.data.groupMetaVo.media_status)
                  }
                  if (params.data.groupMetaVo.message_status) {
                    setManageMessages(params.data.groupMetaVo.message_status)
                  }
                  if (params.data.groupMetaVo.video_status) {
                    setUploadVideos(params.data.groupMetaVo.video_status)
                  }
                }
                setGroupPrivacy(params.data.status)
                setGroupType(params.data.groupTypeId)
                setModerator(getModerator(params))
                setGroupOrganizer(getGroupOrganizer(params))
                setItem(params.data)
                setIsOpen(true)
                setCoverPicture(params.data.coverPicture)
                setThumbnailPicture(params.data.thumbnailPicture)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]

  const [Table, refreshTable] = useAutoTable({ fetch: getTableData })

  const loadPageOptions = async (q, prevOptions, { page }) => {
    // const { options, hasMore } = await loadOptions(q, page);
    const res = await api.fetchUsersLike({
      current: page,
      size: 100,
      compositeLike: q,
      showMembership: false,
      showProfile: true,
      showProfileItems: 'first_name,last_name',
      showProfileName: false
    })
    if (res && res.data && res.data.code === 200) {
      return {
        options: res.data.data.records.map((el) => {
          return {
            value: el.id,
            label: `${
              el.profileMap && el.profileMap.first_name && el.profileMap.last_name
                ? el.profileMap.first_name + ' ' + el.profileMap.last_name
                : el.userNickname
            } (${el.userEmail})`
          }
        }),
        hasMore: res.data.data.pages > page,
        additional: {
          page: page + 1
        }
      }
    }
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0
      }
    }
  }

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Groups</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Add Group
          </ITUButton>
        </Flex>
      </Flex>
      {fetching ? (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
          <Spinner size="xl" marginBottom="1rem" />
          <Text>Loading data...</Text>
        </Flex>
      ) : (
        <Table columnDefs={columnDefs} />
      )}

      <Modal
        size="6xl"
        title={item ? 'Update Group' : 'Create Groups'}
        isOpen={isOpen}
        onCancel={() => handleCloseModal}
        autoFocus={false}
        focusLock={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{item ? 'Update Group' : 'Create Group'}</ModalHeader>
          <ModalBody>
            <FormControl id="title">
              <FormLabel>Title</FormLabel>
              <Input placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl id="description" mt="5">
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} h="200px" />
            </FormControl>
            <FormControl id="description" mt="5">
              <FormLabel>Max Member</FormLabel>
              <Input placeholder="Enter MaxMember" value={maxMember} onChange={(e) => setMaxMember(e.target.value)} />
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Cover Photo</FormLabel>
              {coverPicture && <Image h="200" src={coverPicture} />}
              <ITUButton as="label" for="bannerImageUrlInput" borderRadius="md" variant="outline" size="xs" mt="2">
                {coverPicture ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                accept="image/*"
                hidden
                id="bannerImageUrlInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setCoverPicture(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Thumbnail Photo</FormLabel>
              {thumbnailPicture && <Image h="200" src={thumbnailPicture} />}
              <ITUButton as="label" for="thumbnailPictureInput" borderRadius="md" variant="outline" size="xs" mt="2">
                {thumbnailPicture ? 'Change' : 'Upload'}
              </ITUButton>
              <Input
                hidden
                accept="image/*"
                id="thumbnailPictureInput"
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append('resourceType', 'document')
                    api.uploadFile(formData).then((res) => {
                      if (res.status === 200) {
                        if (res.data.code === 200) {
                          setThumbnailPicture(res.data.data)
                        }
                      }
                    })
                  }
                }}
              />
            </FormControl>
            <FormControl display="flex" mt="5" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                Is this group a challenge?
              </FormLabel>
              <Switch
                isChecked={challenge}
                onChange={() => {
                  setChallenge((_challenge) => !challenge)
                }}
                id="email-alerts"
              />
            </FormControl>
            {challenge && (
              <FormControl id="course" mt="5">
                <FormLabel>Select a course for this challenge</FormLabel>
                <MultiSelect
                  defaultValue={course}
                  isMulti
                  name="course"
                  onChange={(value) => {
                    setCourse(value)
                  }}
                  options={
                    courses
                      ? courses.map((el) => {
                          return {
                            value: el.id,
                            label: el.title
                          }
                        })
                      : []
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormControl>
            )}
            <Box mt="5">
              Connect a discussion forum to allow members of this group to communicate in a structured, bulletin-board style fashion.
              Unchecking this option will not delete existing forum content.
            </Box>

            <FormControl display="flex" mt="5" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                <Box>Yes, I want this group to have a discussion forum.</Box>
              </FormLabel>
              <Switch
                isChecked={isForum}
                onChange={() => {
                  setIsForum((_isForum) => !_isForum)
                }}
                id="email-alerts"
              />
            </FormControl>
            {isForum && (
              <>
                <Box mt="5">Only site administrators can reconfigure which forum belongs to this group.</Box>
                <FormControl id="forum" mt="5">
                  <FormLabel>Connected Forum</FormLabel>
                  <MultiSelect
                    defaultValue={forum}
                    name="forum"
                    onChange={(value) => {
                      setForum(value)
                    }}
                    options={
                      forums
                        ? forums.map((el) => {
                            return {
                              value: el.id,
                              label: el.forumName
                            }
                          })
                        : []
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </FormControl>
              </>
            )}
            <FormControl id="groupType" mt="5">
              <FormLabel>Group Type</FormLabel>
              <Select
                placeholder="Select Group Type"
                value={groupType}
                onChange={(e) => {
                  setGroupType(e.target.value)
                }}
              >
                {groupTypes &&
                  groupTypes.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.groupTypeName}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="member" mt="5">
              <FormLabel>Members</FormLabel>
              <AsyncPaginate
                additional={{
                  page: 1
                }}
                isMulti
                defaultValue={member}
                loadOptions={loadPageOptions}
                onChange={(value) => {
                  setMember(value)
                }}
              />
              {/* <MultiSelect
                defaultValue={member}
                isMulti
                onInputChange={search}
                name="member"
                onChange={(value) => {
                  setMember(value)
                }}
                options={
                  members
                    ? members.map((el) => {
                        return {
                          value: el.id,
                          label: `${
                            el.profileMap && el.profileMap.first_name && el.profileMap.last_name
                              ? el.profileMap.first_name + ' ' + el.profileMap.last_name
                              : el.userNickname
                          } (${el.userEmail})`
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              /> */}
            </FormControl>
            <FormControl id="groupOrganizer" mt="5">
              <FormLabel>Group Organizer</FormLabel>
              <MultiSelect
                defaultValue={groupOrganizer}
                name="groupOrganizer"
                isMulti
                placeholder="Select Group Organizer"
                onChange={(value) => {
                  setGroupOrganizer(value)
                }}
                options={
                  member
                    ? member.map((el) => {
                        return {
                          value: el.value,
                          label: el.label
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="moderator" mt="5">
              <FormLabel>Moderator</FormLabel>
              <MultiSelect
                defaultValue={moderator}
                name="moderator"
                isMulti
                placeholder="Select Moderator"
                onChange={(value) => {
                  setModerator(value)
                }}
                options={
                  member
                    ? member
                        .filter((el) => !groupOrganizer.some((gel) => gel.value === el.value))
                        .map((el) => {
                          return {
                            value: el.value,
                            label: el.label
                          }
                        })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>

            <FormControl id="groupPrivacy" mt="5">
              <FormLabel>Group Privacy</FormLabel>
              <RadioGroup
                value={groupPrivacy}
                onChange={(event) => {
                  setGroupPrivacy(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="PUBLIC">Public</Radio>
                  <Radio value="PRIVATE">Private</Radio>
                  <Radio value="HIDDEN">Hidden</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl id="inviteJoin" mt="5">
              <FormLabel>Who can invite others to join this group ?</FormLabel>
              <RadioGroup
                value={inviteJoin}
                onChange={(event) => {
                  setInviteJoin(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            <FormControl id="postInto" mt="5">
              <FormLabel>Who can post into this group?</FormLabel>
              <RadioGroup
                value={postInto}
                onChange={(event) => {
                  setPostInto(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All group Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators only</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl id="uploadUpload" mt="5">
              <FormLabel>Who can upload photos in this group?</FormLabel>
              <RadioGroup
                value={uploadUpload}
                onChange={(event) => {
                  setUploadUpload(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            <FormControl id="createAlbums" mt="5">
              <FormLabel>Who can create albums in this group?</FormLabel>
              <RadioGroup
                value={createAlbums}
                onChange={(event) => {
                  setCreateAlbums(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl id="uploadDocuments" mt="5">
              <FormLabel>Who can upload documents in this group?</FormLabel>
              <RadioGroup
                value={uploadDocuments}
                onChange={(event) => {
                  setUploadDocuments(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl id="uploadVideos" mt="5">
              <FormLabel>Who can upload videos in this group?</FormLabel>
              <RadioGroup
                value={uploadVideos}
                onChange={(event) => {
                  setUploadVideos(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
            <FormControl id="manageMessages" mt="5">
              <FormLabel>Who can manage group messages in this group?</FormLabel>
              <RadioGroup
                value={manageMessages}
                onChange={(event) => {
                  setManageMessages(event)
                }}
              >
                <HStack spacing="24px">
                  <Radio value="All Members">All Members</Radio>
                  <Radio value="Organizers and Moderators">Organizers and Moderators</Radio>
                  <Radio value="Organizers">Organizers</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteLessons({
                      groupId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Group saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Oops. There was an error deleting the group. Please contact tech support.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Oops. There was an error deleting the group. Please contact tech support. Error: ' + error,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default CreateGroups
