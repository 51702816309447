import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  InputGroup,
  InputRightElement,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Image
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'

function CreatePhase() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [title, setTitle] = useState('')
  const [membershipLevel, setMembershipLevel] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [badgeImage, setBadgeImage] = useState(null)
  const [mainImage, setMainImage] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [item, setItem] = useState(null)

  const handleImageChange = async (e, imageSetter) => {
    const url = await uploadImage(e)
    imageSetter(url)
  }

  const uploadImage = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    const { data } = await api.uploadFile(formData, {
      resourceType: 'images'
    })
    return data.data
  }

  const toast = useToast()

  const getPhase = () => {
    if (loggedIn) {
      api
        .fetchPhase({
          current: 1,
          size: 100
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setRowData(res.data.data.records)
          }
        })
    }
  }

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])
  useEffect(() => {
    getPhase()
  }, [loggedIn])
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    if (item) {
      api
        .updatePhase({
          badgeUrl: badgeImage,
          mainImageUrl: mainImage,
          description,
          title,
          id: item.id
        })
        .then((response) => {
          // Handle the response

          getPhase()
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Phase updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error updating the phase. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    } else {
      api
        .createPhase({
          badgeUrl: badgeImage,
          mainImageUrl: mainImage,
          description,
          title
        })
        .then((response) => {
          // Handle the response

          getPhase()
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Phase saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error daving the phase. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    }
    // Use Promise.all to wait for both uploads to finish
  }

  const columnDefs = [
    // { field: "id", headerName: "ID", flex: "1" },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '1',
      width: 400
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '1',
      width: 400
    },
    // { field: "coursesId", headerName: "Course Id", flex: "1", width: 220 },
    {
      field: 'title',
      headerName: 'Title',
      flex: '3',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'badgeUrl',
      headerName: 'Badge Image',
      flex: '2',
      width: 220,
      cellRenderer: (params) => {
        return params.data.badgeUrl ? <Image maxH={45} maxW={45} src={params.data.badgeUrl} /> : null
      }
    },
    {
      field: 'mainImageUrl',
      headerName: 'Main Image',
      flex: '2',
      width: 220,
      cellRenderer: (params) => {
        return params.data.mainImageUrl ? <Image maxH={45} maxW={45} src={params.data.mainImageUrl} /> : null
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setTitle(params.data.title)
                setDescription(params.data.description)
                setBadgeImage(params.data.badgeUrl)
                setMainImage(params.data.mainImageUrl)
                setIsOpen(true)
                setItem(params.data)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Phases</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setItem(null)
              setDescription('')
              setTitle('')
              setBadgeImage(null)
              setMainImage(null)
              setIsOpen(true)
            }}
          >
            Add Phase
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Phase</ModalHeader>
          <ModalBody>
            <FormControl id="description">
              <FormLabel>Title</FormLabel>
              <Input placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl id="description">
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </FormControl>
            <FormControl id="badgeImage">
              <FormLabel>Upload Badge Image</FormLabel>
              <InputGroup>
                {badgeImage && <Image maxW={150} maxH={150} src={badgeImage} />}
                <ITUButton size="xs" variant="outline" as="label" htmlFor="badgeImage">
                  {badgeImage ? 'Change' : 'Upload'}
                  <input hidden id="badgeImage" type="file" accept="image/*" onChange={async (e) => setBadgeImage(await uploadImage(e))} />
                </ITUButton>
              </InputGroup>
            </FormControl>
            <FormControl id="mainImage">
              <FormLabel>Upload Main Image</FormLabel>
              <InputGroup>
                {mainImage && <Image maxW={150} maxH={150} src={mainImage} />}
                <ITUButton size="xs" variant="outline" as="label" htmlFor="mainImage">
                  {mainImage ? 'Change' : 'Upload'}
                  <input hidden id="mainImage" type="file" accept="image/*" onChange={async (e) => setMainImage(await uploadImage(e))} />
                </ITUButton>
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deletePhase({
                      phaseId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        getPhase()
                        toast({
                          title: 'Success',
                          description: 'Phase deleted successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Oops. There was an error deleting the phase. Please contact tech support.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default CreatePhase
