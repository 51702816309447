import axios from 'axios'
import { getUsersJWT } from '@impact-theory/app-auth'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER
  // baseURL: `https://dev-itu-api.impacttheoryservers.com`
})
const axiosInstanceParamsSerializer = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  paramsSerializer: (params) => {
    const searchParams = new URLSearchParams()

    for (const key of Object.keys(params)) {
      const param = params[key]
      if (Array.isArray(param)) {
        for (const value of param) {
          searchParams.append(key, value)
        }
      } else {
        searchParams.append(key, param)
      }
    }

    return searchParams.toString()
  }
})

const axiosInstanceWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER
})

axiosInstanceParamsSerializer.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      getUsersJWT()
        .then((jwt) => {
          resolve({
            ...config,
            headers: {
              ...config.headers,
              'content-type': config.headers['Content-Type'] || 'application/json',
              Authorization: `Bearer ${jwt}`
            }
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  (error) => Promise.reject(error)
)
axiosInstance.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      getUsersJWT()
        .then((jwt) => {
          resolve({
            ...config,
            headers: {
              ...config.headers,
              'content-type': config.headers['Content-Type'] || 'application/json',
              Authorization: `Bearer ${jwt}`
            }
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  (error) => Promise.reject(error)
)

export const api = {
  fetchUsers: (data) =>
    axiosInstance.get('/server/admin/users/all_users', {
      params: data
    }),
  deleteUser: (userEmail) => axiosInstance.delete(`/server/admin/users/remove/account/${userEmail}`),
  fetchRemoveAccountPage: (params) => axiosInstance.get(`/server/admin/users/remove/account/history`, { params }),

  fetchDropdownUser: (data) =>
    axiosInstance.get('/server/admin/users/select_box_user', {
      params: data
    }),
  fetchUsersLike: (data) =>
    axiosInstance.get('/server/admin/users/all_users_like', {
      params: data
    }),
  fetchInstructor: (data) =>
    axiosInstance.get('/server/admin/users/select_box_user', {
      params: data
    }),
  updateUsers: (data) => axiosInstance.post('/server/admin/users/update_user', data),
  createUsers: (data) => axiosInstance.post('/server/admin/users/create_user', data),
  updateMembership: (data) => axiosInstance.post('/server/admin/membership/update_user_membership', data),
  resetpassword: (data) => axiosInstance.post('/server/admin/users/reset_pass', data),
  fetchProfile: (data) => axiosInstance.get('/server/users/profile_type', { params: data }),
  uploadFile: (data, params) =>
    axiosInstance.post('/server/admin/storage/uploadFile', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: params
    }),
  uploadPdf: (data, params) =>
    axiosInstance.post('/server/admin/storage/uploadClassWorksheets', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: params
    }),
  fetchMemberships: (data) =>
    axiosInstance.get('/server/membership/level', {
      params: data
    }),
  fetchLessons: (data) => axiosInstance.get('/server/admin/courses/lesson_list', { params: data }),
  deleteLessons: (data) => axiosInstance.post('/server/admin/courses/delete_lesson', data),
  updateLessons: (data) => axiosInstance.post('/server/admin/courses/update_lesson', data),
  fetchTopics: (data) =>
    axiosInstance.get('/server/admin/courses/topic_list', {
      params: data
    }),
  deleteTopic: (data) => axiosInstance.post('/server/admin/courses/delete_topic', data),
  fetchCourses: (data) =>
    axiosInstance.get('/server/admin/courses/courses_list', {
      params: data
    }),
  getGroupCourse: (data) =>
    axiosInstance.get('/server/admin/groups/get_group_course', {
      params: data
    }),
  fetchCoursesByLevel: (data) =>
    axiosInstanceParamsSerializer.get('/server/admin/courses/courses_list_by_level', {
      params: data
    }),
  //Groups
  createGroup: (data) => axiosInstance.post('/server/admin/groups/create_group', data),
  deleteGroup: (data) => axiosInstance.post('/server/admin/groups/delete_group', data),
  fetchGroups: (data) =>
    axiosInstance.get('/server/admin/groups/list', {
      params: data
    }),
  deleteCourses: (data) => axiosInstance.post('/server/admin/courses/delete_courses', data),
  createTopic: (data) => axiosInstance.post('/server/admin/courses/create_courses_topic', data),
  updateTopic: (data) => axiosInstance.post('/server/admin/courses/update_topic', data),
  fetchPhase: (data) =>
    axiosInstance.get('/server/admin/courses/phase_list', {
      params: data
    }),
  createPhase: (data, params) => axiosInstance.post('/server/admin/courses/create_courses_phase', data),
  updatePhase: (data, params) => axiosInstance.post('/server/admin/courses/update_phase', data),
  deletePhase: (data) => axiosInstance.post('/server/admin/courses/delete_phase', data),
  createLesson: (data) => axiosInstance.post('/server/admin/courses/create_courses_lesson', data),
  createCourses: (data) => axiosInstance.post('/server/admin/courses/create_courses', data),
  updateCourses: (data) => axiosInstance.post('/server/admin/courses/update_courses', data),
  updateCoursesStatus: (data) => axiosInstance.post('/server/admin/courses/update_courses_status', data),
  updateCourseOrderNumber: (data) => axiosInstance.post('/server/admin/courses/update_course_order_number', data),
  updataJournalPrompt: (data) => axiosInstance.post('/server/admin/journal/create_update_prompt', data),
  deleteJournalPrompt: (data) => axiosInstance.post('/server/admin/journal/delete_prompt', data),
  getJournalPrompt: (params) => axiosInstance.get('/server/admin/journal/prompt_page', { params }),
  updateMembershipLevel: (data) => axiosInstance.post('/server/admin/membership/create_membership', data),
  fetchGroupTypes: (params) => axiosInstance.get('/server/groups/group_type_list', { params }),
  deleteGroupTypes: (id) => axiosInstance.post(`/server/admin/groups/remove_group_type/${id}`),
  createOrUpdateGroupType: (data) => axiosInstance.post('/server/admin/groups/update_group_type', data),
  getProfileTypes: () => axiosInstance.get('/server/users/profile_type'),
  createCategory: (data, params) => axiosInstance.post('/server/admin/term/taxonomy/category/create', data),
  updateCategory: (data, params) => axiosInstance.post('/server/admin/term/taxonomy/category/update', data),
  fetchCategory: (data) =>
    axiosInstance.get('/server/term/taxonomy/event/category/list', {
      params: data
    }),
  fetchGoalCategory: (data) =>
    axiosInstance.get('/server/term/taxonomy/goal/category', {
      params: data
    }),
  createSeries: (data) => axiosInstance.post('/server/admin/term/taxonomy/series/create', data),
  fetchSeries: (data) => axiosInstance.get('/server/admin/term/taxonomy/series/list', data),
  fetchEventSeries: (data) => axiosInstance.get('/server/admin/tribe_event/list', data),
  fetchSeriesEvent: (data) =>
    axiosInstance.get('/server/admin/term/taxonomy/series/event/list', {
      params: data
    }),
  updateSeries: (data) => axiosInstance.post('/server/admin/term/taxonomy/series/update', data),
  deleteSeries: (data) => axiosInstance.post('/server/admin/term/taxonomy/series/delete', data),
  //ranks
  fetchRanks: (data) => axiosInstance.get('/server/admin/gamification/ranks', { params: data }),
  createRanks: (data) => axiosInstance.post('/server/admin/gamification/create_rank', data),
  updateRanks: (data) => axiosInstance.post('/server/admin/gamification/update_rank', data),
  //badges
  fetchBadges: (data) => axiosInstance.get('/server/admin/gamification/badges', { params: data }),
  //points type
  fetchPointsType: (data) =>
    axiosInstance.get('/server/admin/gamification/points_type_list', {
      params: data
    }),
  createPointsType: (data) => axiosInstance.post('/server/admin/gamification/create_points_type', data),
  updatePointsType: (data) => axiosInstance.post('/server/admin/gamification/update_points_type', data),
  deletePointsType: (data) => axiosInstance.post('/server/admin/gamification/remove_points_type', data),
  createBadge: (data) => axiosInstance.post('/server/admin/gamification/create_badges', data),
  updateBadge: (data) => axiosInstance.post('/server/admin/gamification/update_badges', data),
  fetchSeriesList: (params) =>
    axiosInstance.get('/server/admin/term/taxonomy/series/list', {
      params
    }),
  // event
  fetchEventList: (params) => axiosInstance.get('/server/admin/tribe_event/list', { params }),
  crentEvent: (data) => axiosInstance.post('/server/admin/tribe_event/create', data),
  updateEvent: (data) => axiosInstance.post('/server/admin/tribe_event/update', data),
  deleteEvent: (data) => axiosInstance.post('/server/admin/tribe_event/delete', data),
  fetchForumsList: (params) => axiosInstance.get('/server/admin/forums/forums_list', { params }),
  deleteForums: (data) => axiosInstance.delete('/server/admin/forums/delete', { data: data }),
  createForums: (data) => axiosInstance.post('/server/admin/forums/create_forum', data),
  updateForums: (data) => axiosInstance.post('/server/admin/forums/update_forum', data),
  updateVoiceForums: (data) => axiosInstance.post('/server/admin/forums/update_video_status', data),
  fetchProfileType: (params) => axiosInstance.get('/server/users/profile_type', { params }),
  createProfileType: (data) => axiosInstance.post('/server/admin/users/update_profile_type', data),
  deleteProfileType: (id) => axiosInstance.post(`/server/admin/users/remove_profile_type/${id}`),
  // quick link
  getQuickLinkList: (params) => axiosInstance.get('/server/admin/quick_link/list', { params }),
  creatQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/create', data),
  updateQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/update', data),
  deleteQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/delete', data),
  updateQuickLinkBatch: (data) => axiosInstance.post('/server/admin/quick_link/updateBatch', data),
  //Hero section
  createHeroSection: (data) => axiosInstance.post('/server/admin/hero_section/create', data),
  getHeroList: (params) => axiosInstance.get('/server/admin/hero_section/list', { params }),
  updateHeroSection: (data) => axiosInstance.post('/server/admin/hero_section/update', data),
  deleteHeroSection: (id) => axiosInstance.post(`/server/admin/hero_section/delete/${id}`),
  updateHeroSectionBatch: (data) => axiosInstance.post('/server/admin/hero_section/updateBatch', data),
  getCategoryList: (params) => axiosInstance.get('/server/admin/hero_section/category/list'),
  //banner

  getBannerList: (params) => axiosInstanceParamsSerializer.get('/server/admin/notification/banner/list', { params }),
  updateBanner: (data) => axiosInstance.post('/server/admin/notification/banner/update', data),
  deleteBanner: (id) => axiosInstance.post(`/server/admin/notification/banner/delete/${id}`),
  createBanner: (data) => axiosInstance.post('/server/admin/notification/banner/create', data),
  // zoom cafe
  getZoomCafeList: (params) => axiosInstance.get('/server/admin/page/page', { params }),
  createZoomCafe: (data) => axiosInstance.post('/server/admin/page/create', data),
  updateZoomCafe: (data) => axiosInstance.post('/server/admin/page/update', data),

  // award trigger
  awardUser: (params) => axiosInstance.post('/server/admin/gamification/admin_award_user', params),
  awardBadge: (params) => axiosInstance.post('/server/admin/gamification/admin_award_badge', params),
  getAwardTriggerEvent: (params) => axiosInstance.get('/server/admin/gamification/award_trigger_event', { params }),
  getAwardTriggerList: (params) => axiosInstance.get('/server/admin/gamification/award_trigger_list', { params }),
  getUserAwardList: (params) => axiosInstance.get('/server/admin/gamification/user_award_page', { params }),
  creatAwardTrigger: (data) => axiosInstance.post('/server/admin/gamification/create_award_trigger', data),
  updateAwardTrigger: (data) => axiosInstance.post('/server/admin/gamification/update_award_trigger', data),
  // leaderboard
  getLeaderboard: (params) => axiosInstance.get('/server/admin/gamification/leaderboard', { params }),
  creatLeaderboard: (data) => axiosInstance.post('/server/admin/gamification/create_leaderboard', data),
  updateLeaderboard: (data) => axiosInstance.post('/server/admin/gamification/update_leaderboard', data),
  deleteLeaderboard: (data) => axiosInstance.post('/server/admin/gamification/delete_leaderboard', data),
  fetchAccessList: (params) =>
    axiosInstance.get('/server/admin/access/list', {
      params
    }),
  updateAccessFeature: (params) => axiosInstance.post('/server/admin/access/update_feature_object', params),
  studentMonth: {
    create: (params) => axiosInstance.post('/server/admin/users/create_month_student', params),
    getList: (params) => axiosInstance.get('/server/admin/users/month_student_page', { params }),
    update: (params) => axiosInstance.post('/server/admin/users/update_month_student', params)
  },
  // Notifications
  notifications: {
    getConfigEnum: () => axiosInstance.get('/server/admin/config/notification/config_enum', {}),
    getList: (params) => axiosInstance.get('/server/admin/config/notification/config_list', { params }),
    create: (params) => axiosInstance.post('/server/admin/config/notification/create', params),
    update: (params) => axiosInstance.post('/server/admin/config/notification/update', params),
    delete: (id) => axiosInstance.post(`/server/admin/config/notification/delete`, { id })
  },
  // Reports
  fetchGamificationReports: () => axiosInstance.get('/server/admin/gamification/gamification_reports'),

  // Challenges
  getChallenges: (params) => axiosInstance.get('/server/admin/challenge/list', { params }),
  getChallengeTaskList: (params) => axiosInstance.get('/server/admin/challenge/task_list', { params }),
  createChallengeTask: (params) => axiosInstance.post('/server/admin/challenge/create_challenge_task', params),
  updateChallengeTask: (params) => axiosInstance.post('/server/admin/challenge/update_challenge_task', params),
  createChallenge: (params) => axiosInstance.post('/server/admin/challenge/create_challenge', params),
  updateChallenge: (params) => axiosInstance.post('/server/admin/challenge/update_challenge', params),
  updateChallengeTeams: (challengeId, teamIds) =>
    axiosInstance.post('/server/admin/challenge/bind_challenge_teams', { challengeId, teamIds }),
  getChallengeTeams: (challengeId) => axiosInstance.get(`/server/admin/groups/list?challengeId=${challengeId}`),
  removeChallenge: (challengeId) => axiosInstance.post(`/server/admin/challenge/delete_challenge/${challengeId}`),
  getChallengeTaskPointConfig: (taskId) => axiosInstance.get(`/server/admin/challenge_award/listByTaskId?taskId=${taskId}`),
  discussionList: (params) => axiosInstance.get(`/server/admin/forums/discussion_list`, { params }),
  challengeAwardUpdateAwardConfig: (params) => axiosInstance.post(`/server/admin/challenge_award/update_award_config`, { params }),
  challengeAwardUpdateAwardConfigComplex: (params) =>
    axiosInstance.post(`/server/admin/challenge_award/update_award_config_complex`, params),
  sendEmail: (params) => axiosInstance.post(`/server/admin/users/send_password_reset_email`, params),
  // creatQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/create', data),
  // updateQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/update', data),
  // deleteQuickLink: (data) => axiosInstance.post('/server/admin/quick_link/delete', data),
  // updateQuickLinkBatch: (data) => axiosInstance.post('/server/admin/quick_link/updateBatch', data)
  fetchStudentPoint: (params) => axiosInstance.get(`/server/admin/gamification/gamification_user_point_total`, { params }),
  userChallengePointsDetail: (params) => axiosInstance.get(`/server/admin/gamification/user_challenge_points_detail`, { params }),
  userImportPointsDetail: (params) => axiosInstance.get(`/server/admin/gamification/user_import_points_detail`, { params }),
  fetchUserBadgeList: (params) => axiosInstance.get(`/server/admin/gamification/user_badge_list`, { params }),

  fetchProductList: (params) => axiosInstance.get(`/server/admin/membership/mapping/list`, { params }),
  createProductMapping: (data) => axiosInstance.post('/server/admin/membership/mapping/create', data),
  updateProductMapping: (data) => axiosInstance.post('/server/admin/membership/mapping/update', data),
  delateProductMapping: (id) => axiosInstance.delete(`/server/admin/membership/mapping/remove/${id}`),
  reportSummary: (params) => axiosInstance.get('/server/admin/report/summary', { params }),
  fetchAssignmentPage: (params) => axiosInstance.get(`/server/admin/challenge/challenge_assignment_page`, { params }),
  videoAnalyticsTimeTop: (params) => axiosInstance.get('/server/admin/video/analytics/time_top', { params }),
  videoAnalyticsCountTop: (params) => axiosInstance.get('/server/admin/video/analytics/count_top', { params }),
  videoAnalyticsVideoPlayHistory: (params) => axiosInstance.get('/server/admin/video/analytics/video_play_history', { params }),
  videoAnalyticsViewProgress: (params) => axiosInstance.get('/server/admin/video/analytics/view_progress', { params }),
  usersNameSearch: (params) => axiosInstance.get('/server/admin/users/name_search', { params })
}
