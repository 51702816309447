import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  IconButton
} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc); 
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { EditIcon } from '@chakra-ui/icons'
import Pagination from '../components/Pagination'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import ITUButton from '../components/ITUButton'

const CreateGroupTypes = () => {
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [groupTypeName, setGroupTypeName] = useState('')
  const [profileTypes, setProfileTypes] = useState([])
  const [profileTypeInvites, setProfileTypeInvites] = useState([])
  const [profileTypeJoining, setProfileTypeJoining] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(null)
  const toast = useToast()
  const [pageQuery, setPageQuery] = useState({
    current: 0,
    size: 10
  })
  const [tableData, setTableData] = useState({
    total: 0,
    list: []
  })

  const getTableData = async (page = pageQuery) => {
    setIsLoading(true)
    const res = await api.fetchGroupTypes(page).finally(() => {
      setIsLoading(false)
    })
    if (res.data.code === 200 && res.data.data) {
      setRowData(res.data.data.records)
      setTableData({
        total: res.data.data.total,
        list: res.data.data.records
      })
      setPageQuery(page)
    } else {
      toast.error(res.data.msg)
    }
  }

  useEffect(() => {
    api.getProfileTypes().then((res) => {
      if (res.status === 200) {
        if (res.data.code === 200) {
          setProfileTypes(res.data.data)
        }
      }
    })
  }, [])

  const handleCloseModal = () => {
    setIsOpen(false)
  }
  const handleSave = () => {
    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .createOrUpdateGroupType({
          id: item.id,
          groupTypeName,
          profileTypeInvites: profileTypeInvites.map((item) => item.value).join(','),
          profileTypeJoining: profileTypeJoining.map((item) => item.value).join(',')
        })
        .then((response) => {
          // Handle the response

          getTableData({
            current: 1,
            size: 10
          })
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Group type updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Oops. There was an error updating the group type. Please contact tech support.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    }
    api
      .createOrUpdateGroupType({
        id: 0,
        groupTypeName,
        profileTypeInvites: profileTypeInvites.map((item) => item.value).join(','),
        profileTypeJoining: profileTypeJoining.map((item) => item.value).join(',')
      })
      .then((response) => {
        // Handle the response

        getTableData({
          current: 1,
          size: 10
        })
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Group type saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
      })
      .catch((error) => {
        // Handle the error

        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Oops. There was an error saving the group type. Please contact tech support.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    // const await
    const res = await api.deleteGroupTypes(deleteId)
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Prompt deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData()
    } else {
      toast({
        title: 'Error',
        description: 'Oops. There was an error deleting the prompt. Please contact tech support. ' + res.data.message || '',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')

  const columnDefs = [
    { field: 'id', headerName: 'ID', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'groupTypeName',
      headerName: 'Name',
      flex: '1',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueFormatter: (params) => {
        return params.value 
          ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z')
          : '';
      },
      flex: '2',
      width: 400,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      valueFormatter: (params) => {
        return params.value 
          ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z')
          : '';
      },
      flex: '2',
      width: 400,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Actions',
      field: 'id',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <Button
              size={'sm'}
              mr={2}
              onClick={() => {
                setGroupTypeName(params.data.groupTypeName)
                const profileTypeInvitesIds = params.data.profileTypeInvites.split(',')
                setProfileTypeInvites(
                  profileTypes
                    .filter((profileType) => profileTypeInvitesIds.includes(profileType.id.toString()))
                    .map((profileType) => ({
                      value: profileType.id,
                      label: profileType.profileTypeName
                    }))
                )

                const profileTypeJoiningIds = params.data.profileTypeJoining.split(',')

                setProfileTypeJoining(
                  profileTypes
                    .filter((profileType) => profileTypeJoiningIds.includes(profileType.id.toString()))
                    .map((profileType) => ({
                      value: profileType.id,
                      label: profileType.profileTypeName
                    }))
                )
                setIsOpen(true)
                setItem(params.data)
              }}
            >
              <FiEdit3 />
            </Button>
            <Button size={'sm'} onClick={() => setDeleteId(params.value)}>
              <FiTrash2 />
            </Button>
          </Flex>
        )
      }
    }
  ]

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Group Types</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Add Group Type
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getTableData({ current, size })
          }}
        />
      </div>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Group Type</ModalHeader>
          <ModalBody>
            <FormControl id="groupTypeName">
              <FormLabel>Group Type Name</FormLabel>
              <Input placeholder="Enter group type name." value={groupTypeName} onChange={(e) => setGroupTypeName(e.target.value)} />
            </FormControl>
            <FormControl id="profileTypeInvites" mt="4">
              <FormLabel>Profile Type Invites</FormLabel>
              <MultiSelect
                defaultValue={profileTypeInvites}
                isMulti
                name="profileTypeInvites"
                onChange={(event) => {
                  setProfileTypeInvites(event)
                }}
                options={
                  profileTypes.length
                    ? profileTypes.map((el) => {
                        return {
                          value: el.id,
                          label: el.profileTypeName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="profileTypeJoining" mt="4">
              <FormLabel>Profile Type Joining</FormLabel>
              <MultiSelect
                defaultValue={profileTypeJoining}
                isMulti
                name="profileTypeJoining"
                onChange={(event) => {
                  setProfileTypeJoining(event)
                }}
                options={
                  profileTypes.length
                    ? profileTypes.map((el) => {
                        return {
                          value: el.id,
                          label: el.profileTypeName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default CreateGroupTypes
