import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'

function ManageRank() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  // Inside the component function
  const [name, setName] = useState('') // State variable for the 'name' input
  const [description, setDescription] = useState('') // State variable for the 'Description' textarea
  const [congratulationsMessage, setCongratulationsMessage] = useState('') // State variable for the 'Congratulations Message' textarea
  const [pointsNeeded, setPointsNeeded] = useState('') // State variable for the 'points Needed to achieve this rank' input

  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      api
        .fetchRanks({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setRowData(res.data.data.records)
            }
          }
        })
    }
  }, [loggedIn])
  const handleCloseModal = () => {
    setCongratulationsMessage('')
    setName('')
    setDescription('')
    setPointsNeeded('')
    setIsOpen(false)
  }
  const handleSave = () => {
    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .updateRanks({
          congratulationMsg: congratulationsMessage,
          description: description,
          id: item.id,
          name: name,
          pointsNeed: parseInt(pointsNeeded)
        })
        .then((response) => {
          // Handle the response
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Rank Updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to Updated Rank.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    }
    api
      .createRanks({
        congratulationMsg: congratulationsMessage,
        description: description,
        name: name,
        pointsNeed: parseInt(pointsNeeded)
      })
      .then((response) => {
        // Handle the respons
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Rank saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
      })
      .catch((error) => {
        // Handle the error

        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Rank.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const columnDefs = [
    { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'description',
      headerName: 'Description',
      flex: '1',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'pointsNeed',
      headerName: 'Points Threshold',
      flex: '1',
      width: 220,
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCongratulationsMessage(params.data.congratulationMsg)
                setName(params.data.name)
                setPointsNeeded(params.data.pointsNeed)
                setDescription(params.data.description)
                setIsOpen(true)
                setItem(params.data)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Ranks</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsOpen(true)
              setItem(null)
            }}
          >
            Add Rank
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Rank</ModalHeader>
          <ModalBody>
            <ModalBody>
              {/* Name Input */}
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>

              {/* Description Textarea */}
              <FormControl id="description" mt="4">
                <FormLabel>Description</FormLabel>
                <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
              </FormControl>

              {/* Congratulations Message Textarea */}
              <FormControl id="congratulationsMessage" mt="4">
                <FormLabel>Congratulations Message</FormLabel>
                <Textarea value={congratulationsMessage} onChange={(e) => setCongratulationsMessage(e.target.value)} />
              </FormControl>

              {/* Points Needed Input */}
              <FormControl id="pointsNeeded" isRequired mt="4">
                <FormLabel>Points Needed to Achieve This Rank</FormLabel>
                <Input type="number" value={pointsNeeded} onChange={(e) => setPointsNeeded(e.target.value)} />
              </FormControl>
            </ModalBody>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteRanks({
                      lessonId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        getLessions()
                        toast({
                          title: 'Success',
                          description: 'Rank saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Failed to delete Rank.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete Rank.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageRank
