import React, { useEffect, useState } from 'react'
import Pagination from './Pagination'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Box, Spinner, Text } from '@chakra-ui/react'
/**
 *
 * @param {fetch}
 * @returns {list,total}
 */
const useAutoTable = ({ fetch }) => {
  const [tableData, setTableData] = useState({
    list: [],
    total: 0
  })
  const [pageQuery, setPageQuery] = useState({
    current: 1,
    size: 20
  })
  const [isLoading, setIsLoading] = useState(false)

  const getTableData = async (p = pageQuery) => {
    setIsLoading(true)
    const res = await fetch(p)
    setPageQuery(p)
    setTableData(res)
    setIsLoading(false)
  }

  useEffect(() => {
    refreshTable()
  }, [])

  const refreshTable = () => {
    getTableData({
      current: 1,
      size: 50
    })
  }

  const Table = ({ columnDefs }) => (
    <>
      <Box position={'relative'} className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <Box
          display={isLoading ? 'flex' : 'none'}
          justifyContent="center"
          alignItems="center"
          position={'absolute'}
          zIndex={3}
          bg="#fefefed9"
          w={'full'}
          h="full"
        >
          <Box>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            <Text color="blue.500">Loading</Text>
          </Box>
        </Box>
        <AgGridReact style={{ width: '100%', height: '100%' }} rowData={tableData.list} columnDefs={columnDefs}></AgGridReact>
      </Box>
      <Pagination
        loading={isLoading}
        current={pageQuery.current}
        size={pageQuery.size}
        total={tableData.total}
        onChange={(current, size) => {
          getTableData({ current, size })
        }}
      />
    </>
  )

  return [Table, refreshTable]
}

export default useAutoTable
