import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Flex,
  Box,
  Spinner,
  useToast,
  Card,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  Select,
  Input,
  InputNumber,
  Switch
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import useAutoTable from '../components/useAutoTable'
import ITUButton from '../components/ITUButton'

export default function SendPasswordResetEmail() {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)

  const handleCloseModal = () => {
    setUser(null)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const { data } = await api.sendEmail({
        email: user.value
      })
      const success = data.code === 200
      setIsLoading(false)
      toast({
        title: success ? 'Success' : 'Error',
        description: success ? 'Send successfully.' : data.msg,
        status: success ? 'success' : 'error',
        duration: 5000,
        isClosable: true
      })
      setUser(null)
      if (success) {
        handleCloseModal()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    // const { options, hasMore } = await loadOptions(q, page);
    const res = await api.fetchUsersLike({
      current: page,
      size: 100,
      compositeLike: q,
      showMembership: false,
      showProfile: true,
      showProfileItems: 'first_name,last_name',
      showProfileName: false
    })
    if (res && res.data && res.data.code === 200) {
      return {
        options: res.data.data.records.map((el) => {
          return {
            value: el.userEmail,
            label: `${
              el.profileMap && el.profileMap.first_name && el.profileMap.last_name
                ? el.profileMap.first_name + ' ' + el.profileMap.last_name
                : el.userNickname
            } (${el.userEmail})`
          }
        }),
        hasMore: res.data.data.pages > page,
        additional: {
          page: page + 1
        }
      }
    }
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0
      }
    }
  }

  return (
    <Layout>
      <Card padding={'48px'}>
        <Heading as="h2" size="xl">
          Send Password Reset Email
        </Heading>
        <div>
          <FormControl marginTop={'24px'} id="instructor" width={'400px'}>
            <FormLabel>Users to receive email</FormLabel>
            <AsyncPaginate
              additional={{
                page: 1
              }}
              value={user}
              loadOptions={loadPageOptions}
              onChange={(event) => {
                setUser(event)
              }}
            />
          </FormControl>
          <Box textAlign={'end'} mt={'48px'}>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Send Email'}
            </ITUButton>
          </Box>
        </div>
      </Card>
    </Layout>
  )
}
