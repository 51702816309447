import { Box } from '@chakra-ui/react'
import React from 'react'

const Button = React.forwardRef(({ className, active, reversed, children, ...props }, ref) => (
  <Box
    {...props}
    ref={ref}
    fontSize={'20px'}
    cursor={'pointer'}
    p={1}
    rounded={'md'}
    color={reversed ? (active ? '#fff' : '#aaa') : active ? '#000' : '#ccc'}
    bg={reversed ? '' : active ? '#d2d2d2f0' : ''}
    _hover={{
      bg: reversed ? '' : active ? '' : '#f3f3f3f0'
    }}
  >
    {children}
  </Box>
))
export default Button
