import React, { useState, useEffect, useMemo } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MultiSelect from 'react-select'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../components/ITUButton'
import Pagination from '../components/Pagination'

function CreateUser() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [params, setParams] = useState({
    current: 1,
    size: 50,
    sortAsc: true,
    sortType: 'DISPLAY_NAME',
    showProfile: true
  })
  const [pageSize, setPageSize] = useState(50)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [membershipLevels, setMembershipLevels] = useState([])
  const toast = useToast()
  const [profileTypes, setProfileTypes] = useState([])
  const [courses, setCourses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    if (loggedIn) {
      getTableData(params, keyword)
    }
  }, [loggedIn, params.current, params.size, params.sortAsc, params.sortType])
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchMemberships({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setMembershipLevels(res.data.data.records)
            }
          }
        })
      api
        .fetchProfile({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setProfileTypes(res.data.data)
            }
          }
        })
    }
  }, [loggedIn])

  const getMembershipLevels = () => {
    api
      .fetchMemberships({
        current: 1,
        size: 1000
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            setMembershipLevels(res.data.data.records)
          }
        }
      })
  }

  const getCourses = async (levelIds = []) => {
    const res = await api.fetchCoursesByLevel({
      current: 1,
      size: 1000,
      levelIds
    })
    setCourses(res?.data?.data || [])
  }

  const [course, setCourse] = useState([])
  const [newPassword, setNewPassword] = useState('')
  const [membershipLevel, setMembershipLevel] = useState([])
  const [currentItem, setCurrentItem] = useState({
    id: '',
    firstName: '',
    lastName: '',
    nickname: '',
    email: '',
    membershipLevel: [],
    profileType: [],
    website: '',
    course: []
  })
  const onEdit = async (item) => {
    getCourses(item?.membershipUserLevelList?.map((el) => el.levelId))
    setCourse([])
    setNewPassword('')
    setMembershipLevel(item?.membershipUserLevelList?.map((el) => ({ label: el.levelName, value: el.levelId })))
    setCurrentItem({
      id: item.id,
      firstName: item?.profileMap?.first_name,
      lastName: item?.profileMap?.last_name,
      nickname: item.userNickname,
      email: item.userEmail,
      profileType: {
        label: item.profileTypeName,
        value: item.profileTypeId
      },
      website: item?.userUrl
    })
    setIsModalOpen(true)
  }

  const columnDefs = [
    {
      field: 'displayName',
      headerName: 'Student name',
      flex: '1',
      // sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    { field: 'userLogin', headerName: 'Username', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    { field: 'userNickname', headerName: 'Nickname', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    { field: 'userEmail', headerName: 'Email', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'membershipName',
      headerName: 'Membership level',
      flex: '1',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'createdAt',
      headerName: 'Date joined',
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z') : ''
      },
      flex: '2',
      // sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.data)} icon={<EditIcon />} />
            {/* <IconButton
              aria-label="delete"
              onClick={() => {
                setCurrentItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            /> */}
          </Flex>
        )
      }
    }
  ]

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
    getMembershipLevels()
  }, [])

  const handleEdit = () => {
    setIsLoading(true)
    const updateUser = api.updateUsers({
      baseInfo: {
        displayName: '',
        userNickName: currentItem.nickname,
        email: currentItem.email,
        profileTypeId: currentItem.profileType?.value || undefined,
        // membershipIds: membershipLevel.map((el) => el.value),
        userUrl: currentItem.website,
        course: course.map((el) => el.value)
      },
      profileInfo: {
        first_name: currentItem.firstName,
        last_name: currentItem.lastName
      },
      userId: currentItem.id
    })

    const updateMembership = api.updateMembership({
      levelIds: membershipLevel.map((el) => el.value),
      overdueDate: '',
      statusEnum: '',
      userId: currentItem.id
    })
    const resetPassword = api.resetpassword({ newPass: newPassword, userId: currentItem.id })

    const promiseList = [updateUser, updateMembership]

    if (newPassword.length > 0) {
      promiseList.push(resetPassword)
    }

    Promise.all(promiseList)
      .then((response) => {
        getTableData(
          {
            current: 1,
            size: 20
          },
          keyword
        )
        toast({
          title: 'Success',
          description: 'Student updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        onCancel()
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Failed to update student.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
      .finally(() => {
        setIsModalOpen(false)
      })
  }

  const handleAddItem = async () => {
    setIsLoading(true)

    const res = await api
      .createUsers({
        baseInfo: {
          userNickName: currentItem.nickname,
          email: currentItem.email,
          passWord: newPassword,
          profileTypeId: currentItem.profileType?.value || '',
          webSite: currentItem.website,
          membershipIds: membershipLevel.map((el) => el.value)
        },
        profileInfo: {
          first_name: currentItem.firstName,
          last_name: currentItem.lastName
        }
      })
      .catch((error) => {
        setIsModalOpen(true)
        // Handle the network or other unexpected errors
        setIsLoading(false)
        toast({
          title: 'Error',
          description:
            error.response.data.msg === 'Email Duplicate'
              ? 'Email is already in use for another student. Please modify email'
              : JSON.stringify(error.response.data.msg),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
    if (res.data.code === 200) {
      toast({
        title: 'Success',
        description: 'Student added successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getTableData(
        {
          current: 1,
          size: 20
        },
        keyword
      )
      onCancel()
    } else {
      toast({
        title: 'Error',
        description: res.data.msg === 'Email Duplicate' ? 'Email is already in use for another student. Please modify email' : res.data.msg,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const onCancel = () => {
    setIsModalOpen(false)
    //
  }

  const getTableData = (params, keyword) => {
    setIsFetching(true)
    api
      .fetchUsersLike({
        ...params,
        compositeLike: keyword && keyword.length > 1 ? keyword : ''
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setIsFetching(false)
          setRowData(res.data.data.records)
          setTotal(res.data.data.total)
          setPages(res.data.data.pages)
        }
      })
  }

  return (
    <Layout>
      <Heading marginBottom="2rem">Manage Students</Heading>
      <Flex gap={4} marginBottom={'24px'}>
        <FormControl>
          <FormLabel>Sort Asc</FormLabel>
          <Select
            bgColor={'#ffffff'}
            placeholder="Sort Asc"
            onChange={(event) =>
              setParams((_params) => {
                return {
                  ..._params,
                  sortAsc: event.target.value === 'Asc'
                }
              })
            }
            value={params.sortAsc ? 'Asc' : 'Desc'}
          >
            <option value="Asc">Asc</option>
            <option value="Desc">Desc</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Sort Type</FormLabel>
          <Select
            bgColor={'#ffffff'}
            placeholder="Sort Type"
            onChange={(event) =>
              setParams((_params) => {
                return {
                  ..._params,
                  sortType: event.target.value
                }
              })
            }
            value={params.sortType}
          >
            <option value="DISPLAY_NAME">displayName</option>
            <option value="USER_NICK_NAME">nickName</option>
            <option value="USER_EMAIL">email</option>
            <option value="CREATED_AT">createdAt</option>
          </Select>
        </FormControl>
      </Flex>
      <Flex gap={1}>
        <Spacer flex={1}>
          <Input
            placeholder="Type at least two characters to search"
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            bg="white"
            mb="15px"
          />
        </Spacer>
        <ITUButton
          variant="outline"
          onClick={() => {
            getTableData(
              {
                current: 1,
                size: 20
              },
              keyword
            )
          }}
        >
          Search
        </ITUButton>
        <ITUButton
          onClick={() => {
            onEdit({})
          }}
        >
          Add Student
        </ITUButton>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        {isFetching ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
            <Spinner size="xl" marginBottom="1rem" />
            <Text>Loading data...</Text>
          </Flex>
        ) : (
          <>
            <AgGridReact
              style={{ width: '100%', height: '100%' }}
              rowData={rowData}
              columnDefs={columnDefs}
              // onSortChanged={(event) => console.log(event)}
              multiSortKey={'ctrl'}
              // pagination={true}
              // paginationPageSize={'50'}
            ></AgGridReact>
            <Pagination
              loading={isFetching}
              current={params.current}
              size={pageSize}
              total={total}
              onChange={(current, size) => {
                setPageSize(size)
                setParams((_params) => {
                  return {
                    ..._params,
                    current: current
                  }
                })
              }}
            />
          </>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentItem.id ? 'Edit' : 'Create'} Student Details</ModalHeader>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>First Name</FormLabel>
              <Input
                value={currentItem.firstName}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    firstName: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={currentItem.lastName}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    lastName: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Nickname</FormLabel>
              <Input
                value={currentItem.nickname}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    nickname: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                value={currentItem.email}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    email: e.target.value
                  })
                }}
              />
            </FormControl>
            <FormControl id="membershipLevel">
              <FormLabel>Membership Level</FormLabel>
              <MultiSelect
                defaultValue={membershipLevel}
                isMulti
                name="membership"
                onChange={async (event) => {
                  setMembershipLevel([...event])
                  await getCourses(event.map((el) => el.value))
                }}
                options={
                  membershipLevels
                    ? membershipLevels.map((el) => {
                        return {
                          value: el.id,
                          label: el.levelName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            <FormControl id="membershipLevel">
              <FormLabel>Profile Type</FormLabel>
              <MultiSelect
                defaultValue={currentItem.profileType}
                name="profile"
                onChange={(event) => {
                  setCurrentItem({
                    ...currentItem,
                    profileType: event
                  })
                }}
                options={
                  profileTypes
                    ? profileTypes.map((el) => {
                        return {
                          value: el.id,
                          label: el.profileTypeName
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
            {currentItem.id && (
              <FormControl id="membershipLevel">
                <FormLabel>Course</FormLabel>
                <MultiSelect
                  defaultValue={course}
                  isMulti
                  name="membership"
                  onChange={(event) => {
                    setCourse(event)
                  }}
                  options={
                    courses
                      ? courses.map((el) => {
                          return {
                            value: el.id,
                            label: el.title
                          }
                        })
                      : []
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormControl>
            )}

            <FormControl mb={4}>
              <FormLabel>New Password</FormLabel>
              <Input
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Website</FormLabel>
              <Input
                value={currentItem.website}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    website: e.target.value
                  })
                }}
              />
            </FormControl>
            {currentItem?.id && <StudentPoint userId={currentItem.id} />}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onCancel} colorScheme="cyan" variant="outline">
                Cancel
              </Button>
              <Button
                colorScheme="cyan"
                onClick={() => {
                  if (currentItem.id) {
                    handleEdit()
                  } else {
                    handleAddItem()
                  }
                }}
                disabled={isLoading}
              >
                {isLoading ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteUser(currentItem.userEmail)
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Delete successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                        getTableData(
                          {
                            current: 1,
                            size: 20
                          },
                          keyword
                        )
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Oops. There was an error deleting the user. Please contact tech support.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Oops. There was an error deleting the user. Please contact tech support. Error: ' + error,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

const StudentPoint = ({ userId }) => {
  const [pointDetails, setPointDetails] = useState([])
  const [importPointsDetail, setImportPointsDetail] = useState([])
  const [challengePointsDetail, setChallengePointsDetail] = useState([])

  const getPointDetails = async () => {
    const res = await api.fetchStudentPoint({ userId })
    if (res?.status === 200) {
      if (res?.data?.code === 200) {
        setPointDetails(res.data.data)
      }
    }
  }
  const getImportPointsDetail = async () => {
    const res = await api.userImportPointsDetail({ userId })
    setImportPointsDetail(res?.data?.data)
  }
  const getChallengePointsDetail = async () => {
    const res = await api.userChallengePointsDetail({ userId })
    setChallengePointsDetail(res?.data?.data)
  }

  const [badgeList, setBadgeList] = useState([])
  const getUserBadgeList = async () => {
    const res = await api.fetchUserBadgeList({ userId })
    setBadgeList(res?.data?.data)
  }

  useEffect(() => {
    if (userId) {
      getPointDetails()
      getImportPointsDetail()
      getChallengePointsDetail()
      getUserBadgeList()
    }
  }, [userId])

  return (
    <>
      <Text fontSize={'2xl'}>Students Points</Text>
      <Box mb={'12px'}>
        {pointDetails.map((el) => {
          return (
            <div
              key={el.pointTypeId}
              style={{
                padding: '2px 6px',
                display: 'flex',
                gap: '12px'
              }}
            >
              <span>{el.pointTypeName}: </span>
              <span>{el.totalAward}</span>
            </div>
          )
        })}
      </Box>
      <Box display={'flex'} gap={4} flexWrap={'wrap'}>
        {badgeList?.map((item) => (
          <Image flexShrink={'0'} w={'40px'} key={item?.id} title={item?.name} src={item?.imageUrl} alt={item?.name} />
        ))}
      </Box>

      <Tabs>
        <TabList>
          <Tab>Impact Points</Tab>
          <Tab>Challenge Points</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box h={'800px'} w={'full'}>
              <AgGridReact
                style={{ width: '100%', height: '100%' }}
                rowData={importPointsDetail}
                columnDefs={[
                  {
                    field: 'date',
                    headerName: 'Date Earned'
                  },
                  {
                    field: 'pointTypeName',
                    headerName: 'Points Type'
                  },
                  {
                    field: 'pointCount',
                    headerName: 'Points'
                  },
                  {
                    field: 'triggerName',
                    headerName: 'Trigger'
                  }
                ]}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box h={'800px'} w={'full'}>
              <AgGridReact
                style={{ width: '100%', height: '100%' }}
                rowData={challengePointsDetail}
                columnDefs={[
                  {
                    field: 'date',
                    headerName: 'Date Earned'
                  },
                  {
                    field: 'pointTypeName',
                    headerName: 'Points Type'
                  },
                  {
                    field: 'pointCount',
                    headerName: 'Points'
                  },
                  {
                    field: 'triggerName',
                    headerName: 'Trigger'
                  }
                ]}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default CreateUser
