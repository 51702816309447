// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arco-form-label-item > label {
  display: flex;
  align-items: center;
}

/*  rich text */

.rich-text a {
  color: #00808a;
  cursor: pointer;
}
.rich-text a:hover {
  text-decoration: underline;
}

.rich-text code {
  background: #bebebe;
  border-radius: 2px;
  padding: 2px;
}

.rich-text img {
  max-width: 100%;
  max-height: 300px;
}
.rich-text ol {
  list-style: inside;
}

.rich-text ul {
  list-style: inside;
  list-style-type: auto;
}

.ag-center-cols-viewport {
  width: 100%;
}

.ag-body-viewport > div[role='presentation'] {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,eAAe;;AAEf;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".arco-form-label-item > label {\n  display: flex;\n  align-items: center;\n}\n\n/*  rich text */\n\n.rich-text a {\n  color: #00808a;\n  cursor: pointer;\n}\n.rich-text a:hover {\n  text-decoration: underline;\n}\n\n.rich-text code {\n  background: #bebebe;\n  border-radius: 2px;\n  padding: 2px;\n}\n\n.rich-text img {\n  max-width: 100%;\n  max-height: 300px;\n}\n.rich-text ol {\n  list-style: inside;\n}\n\n.rich-text ul {\n  list-style: inside;\n  list-style-type: auto;\n}\n\n.ag-center-cols-viewport {\n  width: 100%;\n}\n\n.ag-body-viewport > div[role='presentation'] {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
