import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Spinner,
  useToast,
  Switch,
  Image,
  Text
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import Pagination from '../components/Pagination'
import ITUButton from '../components/ITUButton'

function ManageProfileTypes() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [createData, setCreateData] = useState({
    id: 0,
    profileTypeName: '',
    enableFilter: false,
    enableRemove: false,
    enableSearchRemove: false
  })
  const [tableData, setTable] = useState({
    list: [],
    total: 0
  })

  const [pageQuery, setPageQuery] = useState({
    // active: true,
    current: 1,
    size: 20
  })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      getUserList()
    }
  }, [loggedIn])

  const getUserList = (params = pageQuery) => {
    setIsLoading(true)
    api
      .fetchProfileType(params)
      .then((res) => {
        if (res.data.code === 200 && res.data.data) {
          setTable({
            list: res.data.data,
            total: res.data.data.length
          })
          setPageQuery(params)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setCreateData({
      id: 0,
      profileTypeName: '',
      enableFilter: false,
      enableRemove: false,
      enableSearchRemove: false
    })
  }

  const handleSave = () => {
    setIsLoading(true)
    api
      .createProfileType({
        ...createData,
        enableFilter: createData.enableFilter ? 1 : 0,
        enableRemove: createData.enableRemove ? 1 : 0,
        enableSearchRemove: createData.enableSearchRemove ? 1 : 0
      })
      .then((response) => {
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'Profile Types saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        getUserList()
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save Profile Types.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const handleDelete = async () => {
    // const await
    const res = await api.deleteProfileType(deleteId)
    if (res.data.code === 200) {
      setDeleteId('')
      toast({
        title: 'Success',
        description: 'Profile Types Deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      getUserList()
    } else {
      toast({
        title: 'Error',
        description: res.data.message || 'Failed to delete Profile Types.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const [deleteId, setDeleteId] = useState('')
  const [uploadIconLoading, setUploadIconLoading] = useState(false)

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  const uploadIcon = async (e) => {
    const img = e.target.files[0]
    const formData = new FormData()
    formData.append('file', img)
    setUploadIconLoading(true)
    const { data } = await api
      .uploadFile(formData, {
        resourceType: 'images'
      })
      .finally(() => {
        setUploadIconLoading(false)
      })
    if (data.data) {
      setCreateData({
        ...createData,
        icon: data.data
      })
    }
  }
  return (
    <Layout>
      <Modal isOpen={!!deleteId} onClose={() => setDeleteId('')} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalBody>Are you sure delete this item ?</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setDeleteId('')}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Profile Types</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton marginBottom="1rem" onClick={() => setIsOpen(true)}>
            Add Profile Type
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          rowDragManaged={true}
          animateRows={true}
          rowData={tableData.list}
          columnDefs={[
            {
              field: 'profileTypeName',
              flex: '1',
              headerName: 'Name',
              sortable: true,
              headerTooltip: 'Search',
              filter: 'agTextColumnFilter'
            },
            {
              field: 'id',
              headerName: 'Action',
              flex: '1',

              cellRenderer: (params) => (
                <>
                  <Button
                    size={'sm'}
                    mr={2}
                    onClick={() => {
                      const { id, ...other } = params.data
                      setCreateData({
                        id,
                        ...other,
                        enableFilter: other.enableFilter === 1,
                        enableRemove: other.enableRemove === 1,
                        enableSearchRemove: other.enableSearchRemove === 1
                      })
                      setIsOpen(true)
                    }}
                  >
                    <FiEdit3 />
                  </Button>
                  <Button size={'sm'} onClick={() => setDeleteId(params.value)}>
                    <FiTrash2 />
                  </Button>
                </>
              )
            }
          ]}
          onRowDragMove={(a) => {
            // console.log(a);
          }}
        ></AgGridReact>
      </div>

      <div>
        <Pagination
          loading={isLoading}
          current={pageQuery.current}
          size={pageQuery.size}
          total={tableData.total}
          onChange={(current, size) => {
            getUserList({ current, size })
          }}
        />
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Profile Types</ModalHeader>
          <ModalBody>
            <FormControl id="title">
              <FormLabel>Name</FormLabel>
              <Textarea
                placeholder="Enter Name"
                value={createData.profileTypeName}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    profileTypeName: e.target.value
                  })
                }
              />
            </FormControl>
            <FormControl id="isFilter" marginTop={'24px'}>
              <FormLabel>Display this profile type in "Types" filter in Members Directory</FormLabel>
              <Switch
                id="isFilter"
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    enableFilter: e.target.checked
                  })
                }}
                isChecked={createData.enableFilter}
              />
            </FormControl>
            <FormControl id="hideMembers" marginTop={'24px'}>
              <FormLabel>Hide all members of this type from Members Directory</FormLabel>
              <Switch
                id="hideMembers"
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    enableRemove: e.target.checked
                  })
                }
                isChecked={createData.enableRemove}
              />
            </FormControl>
            <FormControl id="hideNetwork" marginTop={'24px'}>
              <FormLabel>Hide all members of this type from Network Search results</FormLabel>
              <Switch
                id="hideNetwork"
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    enableSearchRemove: e.target.checked
                  })
                }
                isChecked={createData.enableSearchRemove}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ManageProfileTypes
