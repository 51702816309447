import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Flex,
  Image,
  Input,
  Spinner,
  useToast,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import MultiSelect from 'react-select'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc); 
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import ITUButton from '../components/ITUButton'

function ManagePointsType() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  // Inside the component function
  const [name, setName] = useState('') // State variable for the 'name' input
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState('')

  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0])
  }
  const uploadImage = async () => {
    const formData = new FormData()
    formData.append('file', selectedImage)
    formData.append('resourceType', 'document')

    try {
      const response = await api.uploadFile(formData)
      if (response.status === 200) {
        setImageUrl(response.data.data)
        toast({
          title: 'Success',
          description: 'Image uploaded successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        // Handle further actions based on successful upload (e.g., saving image URL to the database)
      } else {
        throw new Error('Failed to upload image')
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to upload image.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchPointsType({
          current: 1,
          size: 1000
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              setRowData(res.data.data)
            }
          }
        })
    }
  }, [loggedIn])
  const handleCloseModal = () => {
    setName('')
    setIsOpen(false)
    setImageUrl('')
  }
  const handleSave = () => {
    setIsLoading(true)

    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      api
        .updatePointsType({
          id: item.id,
          name: name,
          imageUrl: imageUrl
        })
        .then((response) => {
          // Handle the response
          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'PointsType Updated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          handleCloseModal()
          api
            .fetchPointsType({
              current: 1,
              size: 1000
            })
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                setRowData(res.data.data)
              }
            })
            .catch((error) => {
              // Handle the error
            })
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to Updated PointsType.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
      return
    }
    api
      .createPointsType({
        name: name,
        imageUrl: imageUrl
      })
      .then((response) => {
        // Handle the respons
        setIsLoading(false)
        toast({
          title: 'Success',
          description: 'PointsType saved successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        handleCloseModal()
        api
          .fetchPointsType({
            current: 1,
            size: 1000
          })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              setRowData(res.data.data)
            }
          })
          .catch((error) => {
            // Handle the error
          })
      })
      .catch((error) => {
        // Handle the error

        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Failed to save PointsType.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  const columnDefs = [
    { field: 'name', headerName: 'Name', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'imageUrl',
      headerName: 'Preview',
      flex: '1',
      sortable: false,
      cellRenderer: (params) => {
        return <Image h="40px" src={params.value} />
      }
    },
    {
      field: 'description',
      headerName: 'Date joined',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => {
        return params.value 
          ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z')
          : '';
      },
      flex: '2'
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setName(params.data.name)
                setIsOpen(true)
                setItem(params.data)
                setImageUrl(params.data.imageUrl)
              }}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )
      }
    }
  ]
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Point Types</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setIsOpen(true)
              setItem(null)
              setImageUrl('')
              setName('')
            }}
          >
            Add Points Type
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact
          style={{ width: '100%', height: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
          theme="ag-theme-alpine"
        ></AgGridReact>
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage PointsType</ModalHeader>
          <ModalBody>
            <ModalBody>
              {/* Name Input */}
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
              <FormControl id="imagePreview" mt="4">
                <FormLabel>Image Preview</FormLabel>
                {<Image maxH={'200px'} src={imageUrl} />}
              </FormControl>

              <FormControl mt="6">
                <FormLabel>Upload Image</FormLabel>
                <Input type="file" onChange={handleImageChange} p="20px" h="70px" />
              </FormControl>
              <ITUButton variant="outline" mt="4" size="xs" onClick={uploadImage}>
                Upload Image
              </ITUButton>
            </ModalBody>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deletePointsType({
                      ids: [item.id]
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'PointsType saved successfully.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true
                        })
                      } else {
                        toast({
                          title: 'Error',
                          description: 'Failed to delete PointsType.',
                          status: 'error',
                          duration: 5000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete PointsType.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManagePointsType
