import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { Select, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Heading, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import { api } from '../../services/api'

const timelineOptions = [
  { label: 'All', value: 0 },
  { label: 'yesterday', value: 1 },
  { label: 'A week', value: 2 },
  { label: 'Two weeks', value: 3 },
  { label: 'One month', value: 4 }
]

const categoryOptions = [
  { label: 'Challenge', value: 'CHALLENGE' },
  { label: 'Group', value: 'GROUP' },
  { label: 'Home Page', value: 'MAIN_FEED' }
]

const TrackPage = () => {
  const [formData, setFormData] = useState({
    timeline: 0,
    category: 'CHALLENGE'
  })

  const getData = async () => {
    const res = await api.reportSummary(formData).catch((e) => e)
    if (res?.data?.data) {
      setData(res?.data?.data)
    }
  }

  useEffect(() => {
    getData()
  }, [formData])

  const [data, setData] = useState({
    LIKE: 0,
    POST: 0,
    COMMENT: 0
  })

  return (
    <Layout>
      <Heading marginBottom="2rem">Like Post and Commit</Heading>
      <Flex gap={4} marginBottom={'24px'}>
        <FormControl>
          <FormLabel>Timeline</FormLabel>
          <Select bgColor={'#ffffff'} value={formData.timeline} onChange={(e) => setFormData({ ...formData, timeline: e.target.value })}>
            {timelineOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>category</FormLabel>
          <Select bgColor={'#ffffff'} value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
            {categoryOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 400px)', width: '100%' }}>
        <TableContainer bg={'white'} py={4} rounded={'md'}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Like</Th>
                <Th>Post</Th>
                <Th>Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{data.LIKE}</Td>
                <Td>{data.POST}</Td>
                <Td>{data.COMMENT}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Layout>
  )
}

export default TrackPage
