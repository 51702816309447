import React, { useState, useEffect } from 'react'
import { Button } from '@chakra-ui/react'

const ITUButton = ({ ...props }) => {
  return (
    <Button colorScheme="cyan" onClick={props.onClick} disabled={props.disabled} {...props}>
      {props.children}
    </Button>
  )
}

export default ITUButton
