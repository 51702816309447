import { Select } from '@chakra-ui/react'

const CustomSelect = ({ options, placeholder, value, onChange }) => {
  return (
    <Select
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onChange?.(e.target.value)
      }}
    >
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  )
}

export default CustomSelect
