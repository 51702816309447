import React, { useEffect, useState } from 'react'
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'
import { api } from '../../services/api'
import { AgGridReact } from 'ag-grid-react'
import Layout from '../../components/Layout'
import dayjs from 'dayjs'
import { ColDef } from 'ag-grid-community'

function millisecondsToTime(ms = 0) {
  var seconds = Math.floor(ms / 1000)
  var hours = Math.floor(seconds / 3600)
  seconds %= 3600
  var minutes = Math.floor(seconds / 60)
  seconds %= 60

  var formattedTime = ''
  if (hours > 0) {
    formattedTime += hours + 'h '
  }
  if (minutes > 0) {
    formattedTime += minutes + 'm '
  }
  formattedTime += seconds + 's'

  return formattedTime
}

const ReportVideo = (props: {}) => {
  const [video, setVideo] = useState('')
  const columnDefs: ColDef[] = [
    {
      field: 'videoUrl',
      headerName: 'Video',
      flex: 2,
      cellRenderer: (params: any) => {
        return params.value ? (
          <a target="_blank" href={params.value}>
            {params.value}
          </a>
        ) : (
          '-'
        )
      }
    },
    {
      field: 'playCount',
      headerName: 'Count',
      flex: 1
    },
    {
      field: 'Detail',
      headerName: 'Detail',
      flex: 1,
      cellRenderer: (params: any) => {
        return (
          <>
            <Button size={'sm'} onClick={() => setVideo(params?.data?.videoUrl)}>
              Detail
            </Button>
          </>
        )
      }
    }
  ]

  const [rowData, setRowData] = useState([])
  const [formData, setFormData] = useState({
    startDate: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  })

  const getTableData = async (query = formData) => {
    const res = await api.videoAnalyticsCountTop({
      ...query,
      current: 1,
      size: 100000
    })
    setRowData(res?.data?.data?.records || [])
  }

  useEffect(() => {
    getTableData()
  }, [])

  return (
    <Layout>
      <Heading marginBottom="2rem">Video</Heading>
      <Flex gap={4} marginBottom={'24px'}>
        <FormControl>
          <FormLabel>Timeline</FormLabel>
        </FormControl>
        <FormControl>
          <FormLabel>category</FormLabel>
        </FormControl>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          animateRows
          rowHeight={50}
          groupHeaderHeight={100}
          headerHeight={100}
        ></AgGridReact>
      </div>
      {video && (
        <VideoDetail
          videoUrl={video}
          startDate={formData.startDate}
          endDate={formData.endDate}
          onClose={() => {
            setVideo('')
          }}
        />
      )}
    </Layout>
  )
}

const VideoDetail = ({ onClose, ...props }: { onClose: () => void; startDate: string; endDate: string; videoUrl: string }) => {
  const [viewId, setViewId] = useState('')
  const [rowData, setRowData] = useState([])

  const getTableData = async () => {
    const res = await api.videoAnalyticsVideoPlayHistory({
      current: 1,
      size: 100000,
      ...props
    })
    setRowData(res?.data?.data?.records || [])
  }

  useEffect(() => {
    getTableData()
  }, [])

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'videoType',
      headerName: 'videoType',
      flex: 1
    },
    {
      field: 'duration',
      headerName: 'duration',
      flex: 1,
      cellRenderer: (params: any) => {
        return millisecondsToTime(params.value)
      }
    },
    {
      field: 'playDurationTime',
      headerName: 'playDurationTime',
      flex: 1,
      cellRenderer: (params: any) => {
        return millisecondsToTime(params.value)
      }
    },
    {
      field: 'videoLength',
      headerName: 'videoLength',
      flex: 1,
      cellRenderer: (params: any) => {
        return millisecondsToTime(params.value)
      }
    },
    {
      field: 'userId',
      headerName: 'userId',
      flex: 1
    },
    {
      field: 'Detail',
      headerName: 'Detail',
      flex: 1,
      cellRenderer: (params: any) => {
        return (
          <>
            <Button size={'sm'} onClick={() => setViewId(params?.data?.id)}>
              Detail
            </Button>
          </>
        )
      }
    }
  ])

  return (
    <>
      <Modal size={'full'} isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Video Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                animateRows
                rowHeight={50}
                groupHeaderHeight={100}
                headerHeight={100}
              ></AgGridReact>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            {/* <Button colorScheme="blue" >
            Submit
          </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {viewId && <VideoViewDetail viewId={viewId} onClose={() => setViewId('')} />}
    </>
  )
}

const VideoViewDetail = ({ onClose, viewId }: { onClose: () => void; viewId: string }) => {
  const [rowData, setRowData] = useState([])

  const getTableData = async () => {
    const res = await api.videoAnalyticsViewProgress({ viewId })
    setRowData(res?.data?.data || [])
  }

  useEffect(() => {
    getTableData()
  }, [])

  const [columnDefs] = useState<ColDef<any, any>[]>([
    {
      field: 'id',
      headerName: 'id',
      flex: 1
    },
    {
      field: 'duration',
      headerName: 'duration',
      flex: 1,
      cellRenderer: (params: any) => {
        return millisecondsToTime(params.value)
      }
    },
    {
      field: 'progressTime',
      headerName: 'progressTime',
      flex: 1,
      cellRenderer: (params: any) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  ])

  return (
    <>
      <Modal size={'full'} isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Video Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs as any}
                // pagination
                animateRows
                rowHeight={50}
                groupHeaderHeight={100}
                headerHeight={100}
              ></AgGridReact>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReportVideo
