import React, { useState, useEffect, useMemo } from 'react'
import Layout from '../components/Layout'
import {
  Heading,
  Button,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ButtonGroup,
  ModalBody,
  Text,
  Select,
  FormControl,
  Switch,
  FormLabel,
  Flex,
  Input,
  Spinner,
  useToast,
  Box,
  Image,
  Spacer
} from '@chakra-ui/react'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { api } from '../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../components/ITUButton'
import Pagination from '../components/Pagination'

function ManageVideos() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [tasks, setTasks] = useState([])
  const [challenges, setChallenges] = useState([])
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    current: 1,
    size: 50,
    challengeId: undefined,
    taskId: undefined
  })
  const [pageSize, setPageSize] = useState(50)
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (loggedIn) {
      getTableData(params)
    }
  }, [loggedIn, params.current, params.size])

  const [currentItem, setCurrentItem] = useState({
    id: '',
    pksProductId: '',
    membershipId: '',
    retentionEnabled: false
  })
  const onEdit = async (item) => {
    setMembershipLevel({
      label: item.memberLevelName,
      value: item.membershipId
    })
    setCurrentItem({
      id: item.id,
      retentionEnabled: item?.retentionEnabled,
      pksProductId: item.pksProductId,
      membershipId: item.membershipId
    })
    setIsModalOpen(true)
  }
  const columnDefs = [
    {
      field: 'taskName',
      headerName: 'Task Name',
      flex: '1',
      // sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'challengeName',
      headerName: 'Challenge Name',
      flex: '1',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'userName',
      headerName: 'User Name',
      flex: '1'
    },
    {
      field: 'assignmentName',
      headerName: 'assignmentName',
      flex: '1'
    },
    {
      field: 'assignmentUrl',
      headerName: 'Assignment Url',
      flex: '2'
    }
  ]

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
    getTaskList()
    getChallengeList()
  }, [])
  const getTaskList = () => {
    api
      .getChallengeTaskList({
        current: 1,
        size: 9999
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data.records) {
          setTasks(res.data.data.records)
        }
      })
  }
  const getChallengeList = () => {
    api
      .getChallenges({
        current: 1,
        size: 9999
      })
      .then((res) => {
        if (res.data.code === 200 && res.data.data.records) {
          setChallenges(res.data.data.records)
        }
      })
  }

  const getTableData = (params) => {
    setIsFetching(true)
    api.fetchAssignmentPage(params).then((res) => {
      if (res.data.code === 200 && res.data.data) {
        setIsFetching(false)
        setRowData(res.data.data.records)
        setTotal(res.data.data.total)
        setPages(res.data.data.pages)
      }
    })
  }

  return (
    <Layout>
      <Flex marginBottom="2rem" justifyContent="space-between" direction="column">
        <Heading>Manage Assignment</Heading>
      </Flex>
      <Flex gap={4} marginBottom={'24px'} alignItems="end">
        <FormControl flex="2">
          <FormLabel>Challenge</FormLabel>
          <Select
            bgColor={'#ffffff'}
            onChange={(event) =>
              setParams((_params) => {
                return {
                  ..._params,
                  challengeId: event.target.value === '' ? undefined : event.target.value
                }
              })
            }
            value={params.challengeId}
          >
            <option value="">All</option>
            {challenges &&
              challenges.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl flex="2">
          <FormLabel>Task</FormLabel>
          <Select
            bgColor={'#ffffff'}
            onChange={(event) =>
              setParams((_params) => {
                return {
                  ..._params,
                  taskId: event.target.value === '' ? undefined : event.target.value
                }
              })
            }
            value={params.taskId}
          >
            <option value="">All</option>
            {tasks &&
              tasks.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <ITUButton
          flex="1"
          variant="outline"
          onClick={() => {
            setParams((_params) => {
              return {
                ..._params,
                current: 1,
                size: 50
              }
            })
            getTableData({
              ...params,
              current: 1,
              size: 50
            })
          }}
        >
          Search
        </ITUButton>
      </Flex>
      {/* <Flex gap={1}>
        <Spacer flex={1}>
          <Input
            placeholder="Type at least two characters to search"
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            bg="white"
            mb="15px"
          />
        </Spacer>
        <ITUButton
          variant="outline"
          onClick={() => {
            getTableData(
              {
                current: 1,
                size: 20
              },
              keyword
            )
          }}
        >
          Search
        </ITUButton>
      </Flex> */}
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        {isFetching ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
            <Spinner size="xl" marginBottom="1rem" />
            <Text>Loading data...</Text>
          </Flex>
        ) : (
          <>
            <AgGridReact
              style={{ width: '100%', height: '100%' }}
              rowData={rowData}
              columnDefs={columnDefs}
              // onSortChanged={(event) => console.log(event)}
              multiSortKey={'ctrl'}
              // pagination={true}
              // paginationPageSize={'50'}
            ></AgGridReact>
            <Pagination
              loading={isFetching}
              current={params.current}
              size={pageSize}
              total={total}
              onChange={(current, size) => {
                setPageSize(size)
                setParams((_params) => {
                  return {
                    ..._params,
                    size: size,
                    current: current
                  }
                })
              }}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default ManageVideos
