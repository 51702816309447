import React, { useState, useEffect, useMemo, useRef } from 'react'
import Layout from '../../components/Layout'
import {
  Heading,
  Button,
  Modal,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Switch,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  IconButton,
  ModalFooter,
  Textarea,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Box,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  useToast
} from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'
import { userHasAccess } from '@impact-theory/app-auth'
import { AgGridReact } from 'ag-grid-react'
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MultiSelect from 'react-select'
import { api } from '../../services/api'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc); 
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import ITUButton from '../../components/ITUButton'

function ManageSeries() {
  const [users, setUsers] = useState([])
  const [events, setEvents] = useState([])
  const [selectEvents, setSelectEvents] = useState([])
  const [editEvents, setEditEvents] = useState([])
  const [loggedIn, setLoggedIn] = useState(false)
  const [rowData, setRowData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [pages, setPages] = useState(0)
  const [flag, setFlag] = useState(false)
  const [item, setItem] = useState(null)
  const inputRef = useRef()
  const [params, setParams] = useState({
    current: 1,
    size: 9999
  })
  const toast = useToast()

  useEffect(() => {
    async function run() {
      const result = await userHasAccess(['itu-admin'])
      setLoggedIn(result)
    }
    run()
  }, [])
  useEffect(() => {
    if (loggedIn) {
      api
        .fetchEventSeries({
          current: 1,
          size: 9999
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setEvents(res.data.data.records)
          }
        })
      api
        .fetchSeries({
          current: params.current,
          size: params.size
        })
        .then((res) => {
          if (res.data.code === 200 && res.data.data) {
            setRowData(res.data.data.records)
            setPages(1)
          }
        })
    }
  }, [loggedIn, params.size, params.current, flag])
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    if (name === '' || !name) {
      toast({
        title: 'Error',
        description: 'Please enter name',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
      return
    }
    setIsLoading(true)
    // Perform your API call using the input values (title, phaseId, content, videoPreviewUrl)
    if (item) {
      const addEventIds = selectEvents.filter((el) => editEvents.every((eel) => eel.value !== el.value))
      const removeEventIds = editEvents.filter((el) => selectEvents.every((eel) => eel.value !== el.value))
      api
        .updateSeries({
          id: item.id,
          description,
          name,
          addEventIds: addEventIds.map((el) => el.value),
          removeEventIds: removeEventIds.map((el) => el.value),
          selectEvents: selectEvents.map((el) => el.value)
        })
        .then((response) => {
          // Handle the response

          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Series saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          setFlag((_flag) => !_flag)
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to save Series.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    } else {
      api
        .createSeries({
          description: description,
          name,
          eventIds: selectEvents.map((el) => el.value)
        })
        .then((response) => {
          // Handle the response

          setIsLoading(false)
          toast({
            title: 'Success',
            description: 'Series saved successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true
          })
          setFlag((_flag) => !_flag)
          handleCloseModal()
        })
        .catch((error) => {
          // Handle the error

          setIsLoading(false)
          toast({
            title: 'Error',
            description: 'Failed to save Series.',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
    }
  }
  const gotoPage = (page) => {
    setParams((_params) => {
      return { ..._params, current: page }
    })
  }
  const previousPage = () => {
    setParams((_params) => {
      return { ..._params, current: _params.current - 1 }
    })
  }
  const canPreviousPage = useMemo(() => {
    return params.current > 1
  }, [params, pages])

  const canNextPage = useMemo(() => {
    return params.current < pages
  }, [params, pages])

  const nextPage = () => {
    setParams((_params) => {
      return { ..._params, current: _params.current + 1 }
    })
  }
  const [columnDefs] = useState([
    { field: 'name', headerName: 'Title', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      field: 'createdAt',
      headerName: 'Start Date',
      valueFormatter: (params) => {
        return params.value 
          ? dayjs(params.value)?.tz('America/Los_Angeles').format('MMM DD YYYY h:mm a. z')
          : '';
      },
      flex: '2',
      sortable: true,
      headerTooltip: 'Search',
      filter: 'agTextColumnFilter'
    },
    { field: 'count', headerName: 'Series', flex: '1', sortable: true, headerTooltip: 'Search', filter: 'agTextColumnFilter' },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        return (
          <Flex gap={4}>
            <IconButton
              aria-label="edit"
              onClick={async () => {
                const res = await api.fetchSeriesEvent({
                  current: 1,
                  seriesId: params.data.id,
                  size: 9999
                })
                if (res.data.code === 200) {
                  setEditEvents(
                    res.data.data.records.map((el) => {
                      return {
                        value: el.id,
                        label: el.title
                      }
                    })
                  )
                  setSelectEvents(
                    res.data.data.records.map((el) => {
                      return {
                        value: el.id,
                        label: el.title
                      }
                    })
                  )
                }
                setDescription(params.data.description)
                setName(params.data.name)
                setItem(params.data)
                setIsOpen(true)
              }}
              icon={<EditIcon />}
            />
            <Button
              size={'sm'}
              onClick={() => {
                setItem(params.data)
                setDeleteOpen(true)
              }}
            >
              <FiTrash2 />
            </Button>
          </Flex>
        )
      }
    }
  ])
  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    )

  return (
    <Layout>
      <Flex justifyContent="space-between" direction="column">
        <Heading marginBottom="2rem">Manage Series</Heading>
        <Flex justifyContent="flex-end">
          <ITUButton
            marginBottom="1rem"
            onClick={() => {
              setItem(null)
              setDescription('')
              setName('')
              setIsOpen(true)
            }}
          >
            Add Series
          </ITUButton>
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
        <AgGridReact style={{ height: 'calc(100vh - 350px)', width: '100%' }} rowData={rowData} columnDefs={columnDefs}></AgGridReact>
      </div>
      <Flex justifyContent="space-between" paddingBottom={'48px'} paddingTop={'12px'} m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton onClick={() => gotoPage(1)} isDisabled={!canPreviousPage} icon={<ArrowLeftIcon h={3} w={3} />} mr={4} />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton onClick={previousPage} isDisabled={!canPreviousPage} icon={<ChevronLeftIcon h={6} w={6} />} />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{' '}
            <Text fontWeight="bold" as="span">
              {params.current}
            </Text>{' '}
            of{' '}
            <Text fontWeight="bold" as="span">
              {pages}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{' '}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pages}
            onChange={(value) => {
              const page = value ? value - 1 : 0
              gotoPage(page)
            }}
            defaultValue={params.current + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={params.size}
            onChange={(e) => {
              setParams((_params) => {
                return { ..._params, size: Number(e.target.value) }
              })
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton onClick={nextPage} isDisabled={!canNextPage} icon={<ChevronRightIcon h={6} w={6} />} />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton onClick={() => gotoPage(pages)} isDisabled={!canNextPage} icon={<ArrowRightIcon h={3} w={3} />} ml={4} />
          </Tooltip>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{item ? 'Create' : 'Edit'} Series</ModalHeader>
          <ModalBody>
            <FormControl id="Title">
              <FormLabel>Name</FormLabel>
              <Input placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl id="description" mt="4">
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </FormControl>
            <FormControl id="event" marginY={'24px'}>
              <FormLabel>Event</FormLabel>
              <MultiSelect
                defaultValue={selectEvents}
                isMulti
                name="membership"
                onChange={(event) => {
                  setSelectEvents(event)
                }}
                options={
                  events
                    ? events.map((el) => {
                        return {
                          value: el.id,
                          label: el.title
                        }
                      })
                    : []
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ITUButton variant="outline" mr={3} onClick={handleCloseModal}>
              Cancel
            </ITUButton>
            <ITUButton onClick={handleSave} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Save'}
            </ITUButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={deleteOpen} onClose={() => setDeleteOpen(deleteOpen)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setDeleteOpen(false)
                  api
                    .deleteSeries({
                      seriesId: item.id
                    })
                    .then((response) => {
                      if (response.status === 200 && response.data.code === 200) {
                        toast({
                          title: 'Success',
                          description: 'Series delete successfully.',
                          status: 'success',
                          duration: 3000,
                          isClosable: true
                        })
                        setItem(null)
                        setFlag((_flag) => !_flag)
                      } else {
                        setItem(null)
                        toast({
                          title: 'Error',
                          description: 'Failed to delete Series.',
                          status: 'error',
                          duration: 3000,
                          isClosable: true
                        })
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: 'Error',
                        description: 'Failed to delete Series.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true
                      })
                    })
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

export default ManageSeries
